<template>
  <v-layout ref="app">
    <v-app-bar density="compact" class="px-2">
      <v-row>
        <v-col cols="auto">
          <BreadcrumbsComponent :items="breadcrumbs"/>
        </v-col>
        <v-col style="z-index: 2;">
          <v-select
              class="small float-end mx-1"
              :items="[{id: '0', title: 'Pionowo'},{id: '1', title: 'Poziomo'}]"
              item-text="title"
              item-value="id"
              style="height:30px;"
              v-model="treeOrientation"
              variant="outlined"
              density="compact"
              hide-details
          >
          </v-select>
        </v-col>

      </v-row>
    </v-app-bar>
    <v-main class="page-wrapper2">
      <v-container style="z-index:0;">
        <div id="scene">
          <div class="">
            <div class="text-center tree" ref="headRef" style="position:relative;">
              <blocks-tree :data="productsTree.valueOf()"
                           :horizontal="treeOrientation==='1'"
                           class=" scrolled"
                           :collapsable="collapsable">
                <template #node="{data}">
                  <v-card color="blue">
                    <BranchView :label="data.label" @update-label="data.label = $event"></BranchView>
                    {{ data.idTree }}
                    <v-btn
                        color="green"
                        style="margin-top:6px;"
                        class="float-start ma-3"
                        size="x-small"
                        prepend-icon="mdi-plus-box"
                        @click="addBranch(data.id,productsTree,data.color,data.colorText)">Dodaj
                    </v-btn>
                    <v-btn
                        color="red"
                        style="margin-top:6px;"
                        class="float-end ma-3"
                        size="x-small"
                        prepend-icon="mdi-plus-box"
                        @click="deleteNode(data,productsTree)">Usuń
                    </v-btn>

                  </v-card>
                </template>
              </blocks-tree>
            </div>
          </div>
        </div>
        <v-bottom-navigation :elevation="0" color="teal"
                             grow style="position:absolute; left:50%;
                             margin-left: -250px; bottom: 10px; width:500px;">

          <v-btn class="float-start mx-1"
                 size="small"
                 icon="mdi-plus-box"
                 color="grey"
                 @click="zoomIn"></v-btn>
          <v-btn class="float-start mx-1"
                 size="small"
                 icon="mdi-minus-box"
                 color="grey"
                 @click="zoomOut"></v-btn>
          <v-btn class="float-start mx-1"
                 size="small"
                 icon="mdi-overscan"
                 color="grey"
                 @click="zoomReset"></v-btn>
        </v-bottom-navigation>
      </v-container>

    </v-main>


  </v-layout>

  Data: {{ itemsInWarehouses.itemsInWarehouses }}
</template>
<script>

import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';
import axios from "axios";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import panzoom from "panzoom";
import BranchView from "@/views/warehouses/component/BranchView.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "ProductTree2",
  components: {
    BranchView,
    BreadcrumbsComponent,
    'blocks-tree': VueBlocksTree,
  },

  data() {
    return {
      warehouseItems: null,
      breadcrumbs: [
        {
          title: 'Projekt',
          disabled: true,
        },
        {
          title: this.title,
          disabled: true,
        },
      ],
      zoom: null,
      highestID: 0,
      productsTree: {
        label: 'Magazyn główny',
        expand: true,
        some_id: 1,
        /* children: [
           {
             label: 'Magazyn główny',
             expand: true,
             some_id: 1,
           },
           {
             label: 'Magazyn główny',
             expand: true,
             some_id: 1,
           },
           {
             label: 'Magazyn główny',
             expand: true,
             some_id: 1,
           }
         ]*/
      },

    }
  },

  mounted() {
    {
      var pz = document.querySelector('#scene')
      this.zoom = panzoom(pz, {
        filterKey: function ( /*e, dx, dy, dz*/) {
          return true;
        },
        setTransform: (_, {scale, x, y}) => {
          panzoom.setStyle('transform', `rotate(${x / 20}deg) scale(${scale}) translate(${x}px, ${y}px)`)
        }
      });
      this.zoom.zoomAbs(20, 20, 0.2);
    }
  },
  created() {
    this.getListTree();
    //console.log(this.warehouses.warehouses)

    /*  this.$store.dispatch('addWarehouse', {
        "name": "Magazyn Główny TT",
        "idBranch": 1,
        "tree": "[]",
        "parent": 0,
        "address": 0,
        "company": 1,
        "user": 1,
      });*/
  },
  computed: {
    ...mapGetters(['getItemsInWarehouses', 'getToken']),
    ...mapState(["warehouses", "itemsInWarehouses"]),
  },

  methods: {

    transformData(data) {
      //funkcja zamienia dane pobrane z bazy na tablicę posortowaną po rodzicach
      var result = [];

      function transformItem(item) {
        var transformedItem = {
          label: item.item.name + " - " + item.mark,
          expand: true,
          some_id: item.id
        };

        if (item.parent) {
          transformedItem.parent_id = item.parent.id;
        }

        var children = data.filter(child => child.parent && child.parent.id === item.id);
        if (children.length > 0) {
          transformedItem.children = children.map(transformItem);
        }

        return transformedItem;
      }

      result = data.filter(item => !item.parent).map(transformItem);

      return result;
    },
    async getListTree() {

      await axios.get('https://api.labmetry.com/api/items_in_warehouses_by_warehouse/'+this.$route.params.id, {
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      }).then(response => {
        //this.warehouseItems = response.data
        //console.log("this.response",response.data);
        this.warehouseItems = response.data;
        this.productsTree.children = this.transformData(this.warehouseItems);
      }).catch(error => {
                 this.warehouseItems = error;
      });

      //this.warehouseItems = this.getItemsInWarehouses;
//console.log("this.warehouseItems",this.warehouseItems);


    },
    zoomIn() {
      this.zoom.smoothZoom(20, 20, 1.25);
    },
    zoomOut() {
      this.zoom.smoothZoom(20, 20, 0.8);
    },
    zoomReset() {
      this.zoom.zoomAbs(20, 20, 0.2);
    },
    recursiveSearch(element) {
      if (element.id > this.highestID) {
        this.highestID = element.id;
      }
      if (Array.isArray(element.children)) {
        element.children.forEach((child) => {
          this.recursiveSearch(child);
        });
      }
    },
    findHighestID() {
      this.recursiveSearch(this.productsTree);
    },
    async addBranch(parentId, tree) {
      let isParent = tree.id === parentId;
      if (isParent) {
        this.findHighestID();
        let some_id = this.highestID + 1;
        let leaf = {
          label: ``,
          id: some_id,
        }
        if (!tree.children) {
          tree.expand = true;
          tree.children = [];
        }
        tree.children.push(leaf);
        if (tree.label === "") {
          tree.label = 'Magazyn';
        }

        /*  await this.$store.dispatch('addWarehouse', {
            name: tree.label,
            parent: parentId,
            company:1,
            user:1
          });*/

      } else if (tree.children) {
        tree.children.forEach(ch => this.addBranch(parentId, ch));
      }
    },
    deleteNode(node, tree) {
      let parent = tree.children ? tree.children.find(p => p.id === node.id) : null;
      if (parent) {
        tree.children.splice(tree.children.indexOf(node), 1)
      } else if (tree.children) {
        tree.children.forEach(ch => this.deleteNode(node, ch))
      }
    },

  }
}


</script>
