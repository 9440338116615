<template>
  <div>
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-item>DispositionView</v-card-item>
        </v-card>
      </v-main>
    </v-layout>

  </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";

export default {
  name: "DispositionView",
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'DispositionView',
          disabled: true,
        },
      ],
    }

  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>

<style scoped>

</style>
