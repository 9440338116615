<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PROJECTS'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-btn
                v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_PROJECTS_SAVE'])"
                color="green"
                style="margin-top:6px;"
                class="float-end"
                size="small"
                prepend-icon="mdi-plus"
                variant="elevated"
            >
              Nowy
              <v-dialog
                  v-model="dialog"
                  activator="parent"
                  width="auto"
              >
                <v-card>
                  <v-card-text>
                    <v-text-field
                        label="Nazwa"
                        variant="outlined"
                        v-model="titleProject">
                    </v-text-field>
                    <v-select :items="['Gałąź','Projekt']"
                              v-model="typeProject"
                    ></v-select>
                    <v-text-field
                        v-model="selectedDate"
                        label="Planowana data zakończenia projektu"
                        @click="showDatePicker = !showDatePicker"
                        readonly
                    ></v-text-field>
                    <v-date-picker
                        v-model="selectedDateV"
                        v-if="showDatePicker"
                        locale="pl"
                        @input="showDatePicker = false"
                    ></v-date-picker>
                    <v-select
                        label="Domyślny magazyn"
                        :items="getWarehouses"
                        v-model="warehouse"
                        item-value="id"
                        item-title="name"
                    ></v-select>
                    <v-select
                        label="Osoba odpowiedzialna"
                        :items="employees.employees"
                        v-model="employee"
                        item-value="id"
                        item-title="firstName"
                    ></v-select>
                    <v-row>
                      <v-col cols="8">
                        <v-autocomplete
                            label="Klient"
                            :items="customers.customers"
                            item-value="id"
                            item-title="name"
                            v-model="customer"
                            variant="outlined"

                        >

                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <ItemCustomer :check-roles="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CUSTOMERS_SAVE'])"
                                      :item-id="null" name="Nowy" size="x-large" @get:id="customer = $event"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="justify-space-between">
                    <AnnulledButton @action="dialog=false"></AnnulledButton>
                    <SaveButton @action="saveProject('close')"
                                :loading="loadingClose" label="Zapisz i zamknij"></SaveButton>
                    <SaveButton @action="saveProject('redirect')"
                                :loading="loadingClose" label="Zapisz i przejdź"></SaveButton>
                                     </v-card-actions>
                </v-card>
              </v-dialog>
            </v-btn>
            <v-btn
                color="blue"
                style="margin-top:6px;"
                class="float-end mx-2"
                size="small"
                prepend-icon="mdi-file-tree-outline"
                to="/galezie"
                variant="elevated"
                v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER'])"
            >
              Gałęzie
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-if="projects"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="projects.projects"
              :search="search"
              class="elevation-0"
              item-value="id"
              locale="pl"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.title }}</td>
                <td>{{ formatDate(item.createdAt) }}</td>
                <td>{{ formatDate(item.modifiedAt) }}</td>
                <td>
                  <v-chip
                      :color="status(item.status).color"
                  >
                    {{ status(item.status).label }}
                  </v-chip>
                </td>
                <td align="end">

                  <v-btn class="mx-2" append-icon="mdi-family-tree" size="small" fab dark color="blue"
                         :to="'/projekt/' +  item.id ">
                    Drzewo
                  </v-btn>
                  <RemoveButton @confirm="removeItem(item.id)" :loadingSave="loadingRemoveItem"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">

                  </RemoveButton>
                  <ProjectDetail :id-project="item.id"></ProjectDetail>

                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>


  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import ProjectDetail from "@/components/ProjectDetail.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import ItemCustomer from "@/views/customers/ItemCustomer.vue";
import AnnulledButton from "@/components/navigation/element/AnnulledButton.vue";
import SaveButton from "@/components/navigation/element/SaveButton.vue";

export default {
  name: 'ProjectsView',
  components: {SaveButton, AnnulledButton, ItemCustomer, RemoveButton, ProjectDetail, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Projekty',
          disabled: true,
        },
      ],

      //Loadery
      loadingRemoveItem: false,

      //loadery
      loadingClose: false,
      loadingRedirect: false,

      selectedDate: null,
      selectedDateV: null,
      selectedDateToSend: null,
      showDatePicker: false,
      warehouse: null,

      // Project Save
      dialog: false,
      titleProject: null,
      typeProject: 'Projekt',

      customer: null,
      employee: null,

      detailModal: [],
      progres: 45,
      search: '',
      itemsPerPage: 50,
      headers: [
        /*        {
                  title: 'ID',
                  align: 'start',
                  key: 'id',
                },*/
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Data utworzenia', key: 'createAt'},
        {title: 'Data modyfikacji', key: 'modifyAt'},
        {title: 'Status', key: 'status'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      totalItems: 10,
      itemId: null,

      products: [{
        branchName: 'Element1',
        productName: 'Produkt 1'
      }]

    };
  },
  computed: {
    ...mapState(["projects",'customers','employees','warehouses']),
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles",'getWarehouses']),
  },
  watch:{
    selectedDateV(value){
      this.selectedDateToSend = value;
      this.selectedDate = this.formatDateDP(value);
    }
  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    ...mapActions(['loadProjects', 'removeProject']),
    async fetchProjects() {
      await this.loadProjects();
    },
    async removeItem(itemId) {
      this.loadingRemoveItem = true;
      await this.removeProject(itemId);
      this.loadingRemoveItem = false;
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    async saveProject(what) {
      if (what === "redirect") {
        this.loadingRedirect = true;
      }
      if (what === "close") {
        this.loadingClose = true;
      }

      let deadline =  new Date(this.selectedDateToSend);

      const dataToUpdate = {
        title: this.titleProject,
        type: this.typeProject,
        deadline: deadline.toISOString(),
        warehouse: this.warehouse,
        responsibility: this.employee,
        customer: this.customer
      }
      await this.$store.dispatch('addProject', dataToUpdate);

      if(this.$store.state.users.responseStatus !== 'error'){
        if (what === 'redirect') {
          this.$router.push('/projekt/' + this.$store.state.projects.currentIdProject);
        } else {
          this.dialog = false;
        }
        if (what === "redirect") {
          this.loadingRedirect = false;
        }
        if (what === "close") {
          this.loadingClose = false;
        }
      }
      console.log( this.$store.state.users.responseStatus,  this.$store.state.users.responseMessage);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    formatDateDP(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
    status(status) {
      console.log(status)
      let color;
      let label;

      switch (status) {
        case "draft":
          color = 'grey'
          label = 'Szkic'
          break;
        case "progress":
          color = 'blue'
          label = 'W toku'
          break;
        case "closed":
          color = 'green'
          label = 'Zamknięty'
          break;
        case "annulled":
          color = 'grey'
          label = 'Anulowany'
          break;
        default:
          color = 'green'
          label = 'BRAK'
      }

      return {
        color: color,
        label: label
      }
    }
  },
  created() {
    this.fetchProjects();
  }
}
</script>
