import {createApp} from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Vue3Storage, {StorageType} from "vue3-storage";
import VueAxios from 'vue-axios'
import XGantt from "@xpyjs/gantt";
import "@xpyjs/gantt/dist/index.css";

// Vuetify
import 'vuetify/styles'
import {createVuetify,} from 'vuetify'
import * as comp from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labs from 'vuetify/labs/components'
// Translations provided by Vuetify
import { createI18n } from 'vue-i18n'


//import Moveable from '@gabojkz/vue_moveable_v3';

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import DatetimePicker from 'vuetify-datetime-picker'
import Vue3Material from 'vue3-material';
import ganttastic from '@infectoone/vue-ganttastic'

import Notifications from '@kyvg/vue3-notification'


import {loadFonts} from './plugins/webfontloader'
import store from './store'

loadFonts();

const i18n = createI18n({
    locale: 'pl',
    messages: {
        pl: {
            search: 'Szukaj',
            myAccount: 'Moje konta',
            logout: 'Wyloguj',
            home: 'Strona główna',
            projects: 'Projekty',
            gantt: 'Wykres ganta',
            products: 'Produkty',
            clients: 'Klienci',
            orders: 'Zamówienia',
            warehouses: 'Magazyny',
            filesLibrary: 'Biblioteka',
            settings: 'Ustawienia',
            automation: 'Automatyzacja',
            producers: 'Producenci',
            statuses: 'Statusy',
            categories: 'Kategorie',
            features: 'Cechy',
            units: 'Jednostki',
            warehousePositions: 'Pozycje magazynowe',
            users: 'Użytkownicy',
            employees: 'Pracownicy',
            industries: 'Branże',
            currencies: 'Waluty',
            activities: 'Czynności',
            collaspeMenu: 'Zwiń menu',
            warehouseAccept: 'Magazyn - akceptacje',
            warehouseDisposition: 'Magazyn - roporządzenie',
            warehousesList: 'Lista magazynów'
        },
        en: {
            search: 'Search',
            myAccount: 'My account',
            logout: 'Logout',
            home: 'Home',
            projects: 'Projects',
            gantt: 'Gantt',
            products: 'Products',
            clients: 'Clients',
            orders: 'Orders',
            warehouses: 'Warehouses',
            filesLibrary: 'Library',
            settings: 'Settings',
            automation: 'Automation',
            producers: 'Products',
            statuses: 'Statuses',
            categories: 'Categories',
            features: 'Features',
            units: 'Units',
            warehousePositions: 'Warehouse positions',
            users: 'Users',
            employees: 'Employees',
            industries: 'Industries',
            currencies: 'Currencies',
            activities: 'Activities',
            collaspeMenu: 'Collaspe Menu',
            warehouseAccept: 'Wahrehouse - accept',
            warehouseDisposition: 'Warehouse - disposition',
            warehousesList: 'Warehouses list'
        }
    }
})

const vuetify = createVuetify({
    directives,
    components: {
        ...labs,
        ...comp,
    },

    defaults: {
        global: {
            rounded: 'lg',
            //elevation: 0,
        },
        VTab: {
            size: 'x-small',
            elevation: 0,
        },
        VCard: {
            VBtn: {
                elevation: 2
            }
        },
        VTextField: {
            variant: 'outlined',
            rounded: 80,
            height: 28,
            style: 'border-radius: 100px;'
        },
        VSelect: {
            variant: 'outlined',
            height: 48,
        },
        VList: {
            destiny: "compact"
        },
        VListItem: {
            destiny: "compact"
        },
        VMenu: {
            VCard: {
                elevation: 10
            }
        },
        VAppBar: {
            elevation: 0
        }
    },

    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                primary: "#1e88e5",
                secondary: "#034c5d",
                success: "#00c853",
                error: "#f44336",
                warning: "#ffc107",

                accent: "#03a9f4",
                info: "#009688",
                secondaryDarken: '#e5e5e5',
                bgLight: '#EEF2F6FF'
            },
            dark: {
                primary: "#3f51b5",
                secondary: "#2196f3",
                accent: "#03a9f4",
                error: "#f44336",
                warning: "#ffc107",
                info: "#009688",
                success: "#4caf50",
                secondaryDarken: '#525252',
            },
        }
    }
})

createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(Vue3Storage, {namespace: "pro_", storage: StorageType.Local})
    .use(ganttastic)
    .use(Vue3Material)
    .use(Notifications)
    .use(i18n)
    .use(XGantt)
    .use(VueSweetalert2)
    .use(DatetimePicker)
    .use(VueAxios, axios)
    .mount('#app');
