import axios from "axios";
export const units = {
    state: {
        units: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getUnits: state => {
            return state.units;
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setUnits(state, items) {
            state.units = items;
        },
        addUnit(state, items) {
            state.units.push(items);
        },
        updateUnit(state, data) {
            const index = state.units.findIndex(item => item.idUnit === data.idUnit);
            state.units.splice(index, 1, {id: data.idUnit , title: data.item.title});
        },
        removeUnit(state, itemId) {
            state.units = state.units.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadUnits({commit, rootState }) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/units',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setUnits', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addUnit({commit, rootState }, data) {
            try {
                await axios.post('https://api.labmetry.com/api/units',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addUnit', items);
                        commit('setResponseMessage', 'Jednostka została dodana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateUnit({commit, rootState }, data) {
           //console.log('updateUnit', data)
            try {
                await axios.put('https://api.labmetry.com/api/units/' + data.idUnit,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('updateUnit', data);
                        commit('setResponseMessage', 'Jednostka została zaktualizowana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeUnit({commit, rootState }, itemId) {
            try {
                await axios.delete('https://api.labmetry.com/api/units/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeUnit', itemId);
                        commit('setResponseMessage', 'Jednostka została usunięta');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
