<template>

  <v-btn
      color="green"
      class="float-end"
      size="small"
      prepend-icon="mdi-plus"
      variant="elevated"
      @click="openDialog"
  >
    {{ $props.name ?? 'Dodaj' }}

    <v-dialog
        v-model="dialog"
        activator="parent"
        width="800"
        height="600"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('firstName')"
                  :rules="[rules.required]"
                  v-model="firstName"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('lastName')"
                  :rules="[rules.required]"
                  v-model="lastName"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('phone')"
                  :rules="[rules.required]"
                  v-model="phone"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('email')"
                  :rules="[rules.required]"
                  v-model="email"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('street')"
                  v-model="street"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('homeNumber')"
                  v-model="homeNumber"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('apartmentNumber')"
                  v-model="apartmentNumber"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('zipCode')"
                  v-model="zipCode"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  density="compact"
                  :label="$t('city')"
                  v-model="city"
                  hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <ActionsButton @annulled="dialog=false" @save="fetchAdd($props.itemId)"
                       :loading="loadingSaveItem"></ActionsButton>
      </v-card>
    </v-dialog>
  </v-btn>
</template>
<script>
import ActionsButton from "@/components/navigation/ActionsButton.vue"
import {mapGetters} from "vuex";

export default {
  name: 'ItemCustomer',
  components: {ActionsButton},
  data: () => {
    return {
      loadingSaveItem: false,
      dialog: false,

      // Form Data
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      street: null,
      homeNumber: null,
      apartmentNumber: null,
      zipCode: null,
      city: null,

      // Validation
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  props: {
    checkRoles: {},
    itemId: {},
    name: {}
  },
  computed: {
    ...mapGetters(["getCustomerById"])
  },
  methods: {
    openDialog() {
      console.log('otwieramy', this.$props.itemId)
      if (this.$props.itemId) {
        const currentItem = this.getCustomerById(this.$props.itemId);
        console.log(currentItem);
        this.firstName = currentItem.name;
        this.lastName = currentItem.lastName;
        this.phone = currentItem.phone;
        this.email = currentItem.email;

        this.street = currentItem.street;
        this.homeNumber = currentItem.homeNumber;
        this.apartmentNumber = currentItem.apartmentNumber;
        this.zipCode = currentItem.zipCode;
        this.city = currentItem.city;

      }
    },
    async fetchAdd(id) {
      this.loadingSaveItem = true;
      let dataToUpdate = {
        'name': this.firstName,
        'lastName': this.lastName,
        'phone': this.phone,
        'email': this.email,
        'street': this.street,
        'homeNumber': this.homeNumber,
        'apartmentNumber': this.apartmentNumber,
        'zipCode': this.zipCode,
        'city': this.city,
      }
      if (id) {
        await this.$store.dispatch('updateCustomer', {item: dataToUpdate, id: id});

      } else {
        await this.$store.dispatch('addCustomer', dataToUpdate);
        console.log(this.$store.state.customers.id);
        this.$emit('get:id', this.$store.state.customers.id);
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
      }
      this.loadingSaveItem = false;
    },
  }
}
</script>
