<template>
  <v-container fluid class="background-first">
    <div class="titlePage">
      <h1>Rejestracja</h1>
    </div>
    <v-container>
      <v-row>
        <v-col cols="6" class="offset-1 ">
          <v-img
              :width="400"
              aspect-ratio="16/9"
              class="mt-3 me-auto"
              cover
              src="logo.svg"
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="5" class="offset-3 ">

          <template v-if="registerSuccess === false">
            <h1>Zarejestruj się</h1>
          <v-card class="my-3">
            <v-form @submit.prevent="register" ref="form">
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="firstName"
                    label="Imię"
                    type="text"
                    v-model="firstName"
                    variant="plain"
                    :rules="firstNameRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="firstName"
                    label="Nazwisko"
                    type="text"
                    v-model="lastName"
                    variant="plain"
                    :rules="lastNameRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="phone"
                    label="Telefon"
                    type="text"
                    v-model="phone"
                    variant="plain"
                    :rules="phoneRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="companyName"
                    label="Firma"
                    type="text"
                    v-model="companyName"
                    variant="plain"
                    :rules="companyNameRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="companyTaxId"
                    label="NIP"
                    type="text"
                    v-model="companyTax"
                    variant="plain"
                    :rules="companyTaxRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    prepend-icon="mdi-email"
                    name="email"
                    label="Email"
                    type="text"
                    v-model="email"
                    variant="plain"
                    :rules="emailRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Hasło"
                    type="password"
                    variant="plain"
                    v-model="password"
                    :rules="passwordRules"
                ></v-text-field>
              </v-card-item>
              <v-divider></v-divider>
              <v-card-item>
                <v-text-field
                    id="repeatPassword"
                    prepend-icon="mdi-lock"
                    name="repeatPassword"
                    label="Powtórz hasło"
                    type="password"
                    variant="plain"
                    :rules="passwordRules"
                    v-model="passwordRepeat"
                ></v-text-field>
              </v-card-item>

            <v-divider></v-divider>
            <v-card-text v-if="errorForm !== ''">
              <v-alert :text="errorForm" type="error"></v-alert>
            </v-card-text>
            <v-card-item>
              <v-checkbox label="Akceptuję Regulamin i politykę prywatności" hide-details
                          variant="plain" v-model="agreement" :rules="requiredRules"
              ></v-checkbox>
            </v-card-item>
            <v-card-text class="text-end">
              <v-btn color="success"
                     type="submit"
                     prepend-icon="mdi-send"
                     variant="elevated" size="small"
                     :loading="loading"
              >Zarejestruj się
              </v-btn>
            </v-card-text>
            </v-form>
          </v-card>
          <v-btn variant="text" class="text-none" to="/">Zaloguj się!</v-btn>
          </template>
          <template v-if="registerSuccess === true">
            <h1>Rejestracja przebiegła pomyślnie!</h1>
            <v-card class="my-3">
              <v-card-item>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <v-btn variant="plain" class="text-none" to="/">Zaloguj się!</v-btn>
              </v-card-item>
            </v-card>
          </template>
        </v-col>
      </v-row>

    </v-container>
  </v-container>


</template>
<script>
import {mapMutations} from "vuex";
import axios from "axios";
export default {
  name: 'RegisterView',
  data: () => {
    return {

      options: {
        isLoggingIn: true,
        shouldStayLoggedIn: true,
      },

      loading:false,

      registerSuccess: false,

      // Data to send
      firstName: null,
      lastName: null,
      phone: null,
      companyName: null,
      companyTax: null,
      email: null,
      password: null,
      passwordRepeat: null,
      agreement: null,


      lostPassword: false,
      errorForm: "",

      requiredRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
      ],

      firstNameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length >= 3) return true
          return 'Minimalna liczba znaków to 2.'
        },
        value => {
          if (value.length < 30) return true
          return 'Maksymalna liczba znaków to 30.'
        },
        value => {
          if (!/\d/.test(value)) return true
          return 'Imię nie może zawierać liczb.'
        },
      ],

      companyNameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length >= 3) return true
          return 'Minimalna liczba znaków to 2.'
        },
        value => {
          if (value.length < 30) return true
          return 'Maksymalna liczba znaków to 30.'
        },
      ],
      companyTaxRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (/^\d{3}(-)?\d{3}(-)?\d{2}(-)?\d{2}$/.test(value)) return true
          return 'Nieprawidłowy format NIP'
        },
      ],
      lastNameRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (value.length >= 3) return true
          return 'Minimalna liczba znaków to 2.'
        },
        value => {
          if (value.length < 30) return true
          return 'Maksymalna liczba znaków to 30.'
        },
        value => {
          if (!/\d/.test(value)) return true
          return 'Imię nie może zawierać liczb.'
        },
      ],

      phoneRules: [
        value => {
          if (value) return true
          return 'Wymagane.'
        },
        value => {
          if (!/^\+?\d{0,3}(\d{3}-\d{3}-\d{4}|\d{10})$/.test(value)) return true
          return 'To nie jest poprawny numer telefonu.'
        },
      ],

      emailRules: [
        value => {
          if (value) return true

          return 'E-mail jest wymagany.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail musi być prawidłowy'
        },
      ],
      passwordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
        value => {
          if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).*$/.test(value)) return true

          return 'Your password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
        },
      ],

    };
  },
  methods: {
    ...mapMutations(["setToken", "setUser", "setRoles", "logout"]),
    async login(e) {
      e.preventDefault();
      const response = await fetch("https://api.labmetry.com/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      });

      if (response.status === 401) {
        this.errorForm = "Nieprawidłowy adres email lub hasło";
      } else {
        const {token} = await response.json();
        this.setToken(token);
        this.setUser(this.email);

        await axios.get("https://api.labmetry.com/api/current-user", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
            .then(response => {
              this.setRoles(response.data.roles);
            }).catch(() => {
              this.logout;
            });


      }
    },

    async register() {

      this.loading = true;
      const { valid } = await this.$refs.form.validate()

      if(valid !== false){
        this.loading = true;
        await axios.post('https://api.labmetry.com/api/register', {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          companyName: this.companyName,
          companyTax: this.companyTax,
          email: this.email,
          password: this.password,
          agreement: this.agreement
        },)
            .then(response => {
              if(response.data.error) {
                this.errorForm = response.data.error;
              }else{
                this.registerSuccess = true;
              }
            })
            .catch(error => {

              console.log(error);

              this.$notify({
                title: error.response.data.detail,
                type: 'error',
              });
            });

        this.loading = false
      }
      this.loading = false




    },

    remindPassword() {

      axios.post('https://api.labmetry.com/reset-password', {
        email: this.email
      },)
          .then(response => {
            this.$notify({
              title: response.data,
              type: 'success',
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify({
              title: error['hydra:description'],
              type: 'error',
            });
          });
    },
  },
};
</script>
<style>
.login-form {
  max-width: 500px;
}

</style>
