<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_TICKETS_SAVE'])">
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>

      <v-btn
          color="green"
          style="margin-top:6px; "
          class="float-start mx-1"
          size="x-small"
          prepend-icon="mdi-content-save"
      >
        Zapisz

        <v-dialog
            v-model="dialog"
            activator="parent"
            width="auto"
        >
          <v-card>
            <v-card-text>
              <v-text-field style="width: 550px;" label="Nazwa" variant="outlined"
                            v-model="title"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="fetchAddTicket(itemId)">Zapisz</v-btn>
              <v-btn color="primary" @click="dialog = false">Zamknij</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>

    </v-col>
  </v-row>

  <template v-if="projects.projects">
    <v-card
        color="#1F7087"
        theme="dark"
        style="width: fit-content !important;"
        v-for="(project, index) in projects.projects" :key="index"
        class="float-left me-2"
        @click="selectProject(project)"
    >
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title class="text-h6">
            {{ project.title }}
          </v-card-title>
          <v-card-actions>
            <label> <input type="radio" name="selectedProject" :value="project.id" v-model="selectedProject"> Wybierz
            </label>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </template>

  <br>
  <div style="clear:both"></div>
  <br>
  <DynamicForm :tree="tree" @selected="handleSelected" @updateMyProp="updateMyProp"/>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>

<script>

import DynamicForm from "@/views/tickets/DynamicForm.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {rozdzielDzialanie} from "@/views/tree/js/main";

export default {
  components: {
    BreadcrumbsComponent,
    DynamicForm
  },
  data() {
    return {

      breadcrumbs: [
        {
          title: 'Zlecenie',
          disabled: true,
        },
      ],
      dialog: false,
      title: '',
      itemId: null,

      tree: [],
      selectedProducts: [],
      math: [],
      selectedProject: null,
    };
  },
  created() {
    this.fetchTickets();
    this.fetchProjects();
    if (this.$route.params.id) {
      let data = this.getTicketById(this.$route.params.id);

      this.itemId = data.id;
      this.title = data.title;
      this.math = JSON.parse(data.math);
      this.selectedProducts = JSON.parse(data.selectedProducts);
      this.selectedProject = JSON.parse(data.selectedProject);
      this.tree = JSON.parse(data.tree)

    }
  },
  computed: {
    ...mapState(["projects"]),
    ...mapGetters(["isLoggedIn", 'getToken', "getUser", "getRoles", 'getTicketById'])
  },
  methods: {
    ...mapActions(['loadProjects', 'loadTickets', 'addTicket']),
    async fetchTickets() {
      await this.loadTickets();
    },
    async fetchProjects() {
      await this.loadProjects();
    },

    async fetchAddTicket(idTicket) {
      //console.log('fetchAddUnit',idUnit);
      if (idTicket) {
        await this.$store.dispatch('updateTicket', {
          item: {
            title: this.title,
            math: JSON.stringify(this.math),
            selectedProducts: JSON.stringify(this.selectedProducts),
            selectedProject: JSON.stringify(this.selectedProject),
            tree: JSON.stringify(this.tree)
          }, idTicket: idTicket
        });
      } else {
        await this.$store.dispatch('addTicket', {
          title: this.title,
          math: JSON.stringify(this.math),
          selectedProducts: JSON.stringify(this.selectedProducts),
          selectedProject: JSON.stringify(this.selectedProject),
          tree: JSON.stringify(this.tree)
        });
        this.itemId = this.$store.state.tickets.currentId;
        console.log(this.itemId);
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
      }
    },

    handleSelected(selectedStep) {
      if (selectedStep.checked === true) {
        this.selectedProducts.push(selectedStep);
        let children = [];
        if (selectedStep.children && selectedStep.children.length > 0) {
          selectedStep.children.filter((c) => {
            children.push(c.id);
          })
        }

        //Dodajemy do Math całe produkty
        let math = {
          idTree: Number(selectedStep.id),
          feature: '??',
          children: children,
          product: selectedStep.id,
          price: Number(selectedStep.price),
          priceUnit: Number(selectedStep.priceUnit),
          type: selectedStep.type
        };

        const row = this.math.findIndex((el) => el.idTree === Number(selectedStep.id) && el.feature === '??');
        if (row !== -1) {
          this.math[row] = math
        } else {
          this.math.push(math);
        }

        //Dodajemy do Math cechy produktów
        if (selectedStep.features) {
          selectedStep.features.filter(e => {
            let math2 = {
              idTree: Number(selectedStep.id),
              feature: e.id,
              math: e.math,
              children: children,
              type: selectedStep.type,
              value: Number(e.value)
            };
            const row2 = this.math.findIndex((el) => el.idTree === Number(selectedStep.id) && el.feature === e.id);
            if (row2 !== -1) {
              this.math[row2] = math2
            } else {
              this.math.push(math2);
            }
          })
        }

        this.getCalculations();
        this.getCalculations();
        this.getCalculations();

      } else {
        const index = this.selectedProducts.findIndex((product) => product.id === selectedStep.id);
        if (index !== -1) {
          this.selectedProducts.splice(index, 1);
        }

        this.math.filter((el) => {
          if (Number(el.idTree) === Number(selectedStep.id)) {
            //console.log('Usuwam',this.math[index])
            this.math.splice(this.math.indexOf(el), 1);
          }

        });

        this.getCalculations();
        this.getCalculations();
        this.getCalculations();
      }
    },
    selectProject(selectedProject) {

      let tree = [JSON.parse(selectedProject.tree)];
      this.tree = tree[0].children

      this.selectedProject = selectedProject.id;
      this.selectedProducts = [];
    },
    getCalculations() {
      this.math.filter((item, index) => {
            if (item.type === 'build' && item.math !== undefined) {

              let mathElements;
              /*              const mathElements_ = item.math.split(' ');
                            if (typeof mathElements_ === 'string') {
                              mathElements = mathElements_.trim();
                            } else {
                              mathElements = mathElements_;
                            }*/

              //console.log('mathElements Before', item.math);

              mathElements = rozdzielDzialanie(item.math);
              let _math = '';

              //console.log('mathElements', mathElements);

              mathElements.filter((mathElement) => {

                    //console.log('mathElement', mathElement);

                    if (mathElement) {


                      let pattern = /^[0-9]+([.,][0-9]+)?$/;
                      if (mathElement === "*" || mathElement === "/" || mathElement === "+" || mathElement === "-" || mathElement === "(" || mathElement === ")"
                          || pattern.test(mathElement)) {
                        _math += mathElement;
                      } else {

                        // Rozbijamy działanie matematyczne na części
                        // Wyciągamy identyfikatory

                        //console.log('mathElement split', mathElement);

                        const mathId = mathElement.split('_');

                        let mathProduct = mathId[0];
                        let mathFeature = mathId[1];
                        let mathContent = mathId[2];

                        if(mathProduct && mathFeature && !mathContent){
                          let variables = this.getVariableById(mathFeature);
                          _math += String(variables.value.replace(',','.'));
                        }

                        if (mathProduct && mathFeature && mathContent) {
                          let _selectChildren = null;

                          if (item.children !== undefined) {
                            item.children.filter((childrenProduct) => {
                              // Zaznaczony produkt RADIO
                              if (this.selected) {
                                let selectChildren = this.selected.filter(element => Number(element) === Number(childrenProduct));
                                if (selectChildren.length > 0) {
                                  _selectChildren = selectChildren[0];
                                }
                              }
                            })
                          }

                          if (mathProduct === "??" && mathFeature === "??") {
                            const row = this.math.find((el) => String(el.idTree) === String(_selectChildren));
                            if (row !== undefined) mathProduct = row;
                            if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
                          } else if (mathProduct === "??" && mathFeature !== "??") {

                            const row = this.math.find((el) => String(el.idTree) === String(_selectChildren)
                                && String(el.feature) === String(mathFeature));
                            if (row !== undefined) mathProduct = row;

                            if (row !== undefined && mathContent === "C" && mathProduct !== '??') {
                              _math += String(row.price);
                            }
                            if (row !== undefined && mathContent === "W" && mathProduct !== '??') {
                              _math += String(row.value);
                            }

                          } else if (mathProduct !== "??" && mathProduct !== "00" && mathFeature !== "??" && mathFeature !== "00") {

                            const row = this.math.find((el2) => {

                              if (String(el2.idTree) === String(mathProduct) &&
                                  String(el2.feature) === String(mathFeature)
                              ) {

                                if (item.children !== undefined) {
                                  if (item.children.find((el3) => el3 === el2.idTree)) {
                                    return el2
                                  }
                                }

                                if (String(el2.idTree) === String(item.idTree)) {
                                  return el2
                                }
                              }
                            });

                            if (row !== undefined && mathContent === "W" && mathProduct !== '??') {
                              if (row.value !== null) {
                                let price = row.value;
                                if (price) {
                                  _math += parseFloat(price.toString().replace(',', '.'));
                                }
                              }
                            }

                          } else if (mathProduct !== "??" && mathFeature === "??") {

                            let row = this.math.find((el) => String(el.idTree) === String(mathProduct));

                            if (row.type !== undefined && row.type === 'ready' && !row.priceReady) {

                              let index = this.math.findIndex(obj => obj.idTree === row.idTree);

                              if (index !== -1) {
                                this.math.splice(index, 1);
                              }

                              row = this.math.find((el) => String(el.idTree) === String(mathProduct));
                            }

                            if (row !== undefined) mathProduct = row;

                            if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
                            if (mathContent === "J" && mathProduct !== '??') _math += String(mathProduct.priceUnit);

                          } else if (mathProduct === "00" && mathFeature === "00") {

                            if (item.children !== undefined) {
                              item.children.filter((childrenProduct) => {
                                const row = this.math.find((el) => String(el.idTree) === String(childrenProduct));
                                if (row && row.priceReady !== undefined) {
                                  if (mathContent === "C") _math += String(row.priceReady);
                                  if (mathContent === "J") _math += String(row.priceUnit);
                                  _math += '+';
                                }
                              });

                              var lastChar = _math.charAt(_math.length - 1);
                              if (lastChar === "+") {
                                _math = _math.slice(0, -1);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
              )


              if (_math === "") {
                _math = '---';
              }


              //console.log('_math', _math);

              //this.changeValue(item.idTree, '', item.feature)

              try {
                let resultMath = eval(_math);
                resultMath = parseFloat(resultMath.toFixed(2));
                this.math[index].value = resultMath;

                this.changeValue(item.idTree, resultMath, item.feature)


              } catch (error) {
                ////console.log(error);
                //this.error[item.idTree] = [];
                //this.error[item.idTree][item.feature] = true;
                //this.changeValue(item.idTree, null, item.feature)
              }

            }

          }
      )
      ;
    },
    // Aktualizacja tablicy matematycznej o wpisywany wartości przez sprzedawcę
    updateMyProp(event) {

      console.log(event);


      const row = this.math.findIndex((el) => el.idTree === Number(event[1][0]) && el.feature === Number(event[1][1]));
      if (row !== -1) {
        this.math[row] = {
          idTree: Number(event[1][0]),
          feature: Number(event[1][1]),
          value: Number(event[0])
        }
      } else {
        this.math.push(
            {
              idTree: Number(event[1][0]),
              feature: Number(event[1][1]),
              value: Number(event[0])
            }
        );
      }
      this.getCalculations()
    },
    changeValue(nodeId, newValue, feature) {

      //console.log(nodeId, feature, newValue);

      let _product = this.selectedProducts.find((p) => p.id === nodeId);
      let _feature = _product.features.find((f) => f.id === feature);
      _feature.value = newValue;


    },
    ...mapMutations(["checkRole"]),
    checkRoles(roles) {
      const userRoles = this.getRoles || [];
      return roles.some(role => userRoles.includes(role));
    },
  },

};
</script>
