import axios from "axios";

const success_addProducer = "Producenta dodano poprawnie";
const success_updateProducer = "Producenta zaktualizowano poprawnie";
const success_removeProducer = "Producenta usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const producers = {
    state: {
        producers: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getProducers: state => {
            return state.producers;
        },
        getProducerById: (state) => (id) => {
            return state.producers.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setProducers(state, items) {
            state.producers = items;
        },
        addProducer(state, items) {
            state.producers.push(items);
        },
        updateProducer(state, data) {
            const index = state.producers.findIndex(item => Number(item.id) === Number(data.id));
            state.producers.splice(index, 1, data.item);
        },
        removeProducer(state, itemId) {
            state.producers = state.producers.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadProducers({commit, rootState}) {
            try {
                const response = await axios.get(host + '/api/producers',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setProducers', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addProducer({commit, rootState}, data) {

            try {
                await axios.post(host + '/api/producers',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addProducer', items);
                        commit('setResponseMessage', success_addProducer);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateProducer({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/producers/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateProducer', items);
                        commit('setResponseMessage', success_updateProducer);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeProducer({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/producers/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeProducer', id);
                        commit('setResponseMessage', success_removeProducer);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
