import axios from "axios";

const success_addIndustries = "Przykład dodano poprawnie";
const success_updateIndustries = "Przykład zaktualizowano poprawnie";
const success_removeIndustries = "Przykład usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const industries = {
    state: {
        industries: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getIndustries: state => {
            return state.industries;
        },
        getIndustriesById: (state) => (id) => {
            return state.industries.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setIndustries(state, items) {
            state.industries = items;
        },
        addIndustries(state, items) {
            state.industries.push(items);
        },
        updateIndustries(state, data) {
            const index = state.industries.findIndex(item => Number(item.id) === Number(data.id));
            state.industries.splice(index, 1, data);
        },
        removeIndustries(state, itemId) {
            state.industries = state.industries.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadIndustries({commit, rootState}) {
            try {
                const response = await axios.get(host + '/api/industries',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setIndustries', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addIndustries({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/industries',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addIndustries', items);
                        commit('setResponseMessage', success_addIndustries);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateIndustries({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/industries/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateIndustries', items);
                        commit('setResponseMessage', success_updateIndustries);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeIndustries({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/industries/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeIndustries', id);
                        commit('setResponseMessage', success_removeIndustries);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}

// W store/index.js dodać nazwę store na koniec
// Dodać ładowanie danych w src/App.vue
