// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/right.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.login-form {\r\n  max-width: 500px;\n}\n.background-first {\r\n  background-color: #f2f2f3;\n}\n.background-first:before {\r\n  content: '';\r\n  display: block;\r\n  position: fixed;\r\n  right: 0;\r\n  top: 0;\r\n  height: 100vh;\r\n  width: 415px;\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\r\n  background-position: right bottom;\n}\n.titlePage{\r\n  position:fixed;\r\n  right: 180px;\r\n  bottom: 283px;\r\n  color:#fff;\r\n  text-transform:uppercase;\n}\r\n\r\n\r\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
