import axios from "axios";

const success_addLocationItems = "Pozycję dodano poprawnie";
const success_updateLocationItems = "Pozycję zaktualizowano poprawnie";
const success_removeLocationItems = "Pozycję usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const locationItems = {
    state: {
        locationItems: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getLocationItems: state => {
            return state.locationItems;
        },
        getLocationItemsById: (state) => (id) => {
            return state.locationItems.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setLocationItems(state, items) {
            state.locationItems = items;
        },
        addLocationItems(state, items) {
            state.locationItems.push(items);
        },
        updateLocationItems(state, data) {
            const index = state.locationItems.findIndex(item => Number(item.id) === Number(data.id));
            state.locationItems.splice(index, 1, data);
        },
        removeLocationItems(state, itemId) {
            state.locationItems = state.locationItems.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadLocationItems({commit, rootState}) {
            try {
                const response = await axios.get(host + '/api/location_items',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                console.log("go");
                commit('setLocationItems', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addLocationItems({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/location_items',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addLocationItems', items);
                        commit('setResponseMessage', success_addLocationItems);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateLocationItems({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/location_items/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateLocationItems', items);
                        commit('setResponseMessage', success_updateLocationItems);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeLocationItems({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/location_items/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeLocationItems', id);
                        commit('setResponseMessage', success_removeLocationItems);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
