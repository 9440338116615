<template>
  <v-card>
    <v-tabs
        v-model="tab"
        destiny="compact"
        bg-color="dark"
        size="x-small"
    >
      <v-tab value="detail" size="x-small">Szczegóły</v-tab>
      <v-tab value="product" size="x-small" v-if="branch.product">Produkt</v-tab>
      <v-tab value="historia" size="x-small">Historia</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item value="detail">
        <v-row>
          <v-col>
            <v-card-item>
              <v-row>
                <v-col>
                  <v-col>


                    <v-btn
                        color="primary"
                        variant="outlined"
                        prepend-icon="mdi-upload"
                        style="margin-top:9px;"
                    >
                      Dodaj załącznik
                      <v-dialog
                          v-model="dialogUploadFile"
                          activator="parent"
                          width="auto"
                      >
                        <v-card>
                          <form @submit="uploadFile">
                            <v-card-text>
                              <v-text-field
                                  ref="title"
                                  name="title"
                                  density="compact"
                                  placeholder="Nazwa własna"
                                  hide-details
                              ></v-text-field>
                              <v-file-input
                                  label="Plik"
                                  ref="fileInput"
                                  variant="outlined"
                                  density="compact"
                                  hide-details
                                  class="mt-3">
                              ></v-file-input>
                              <v-select
                                  class="mt-3"
                                  density="compact"
                                  :items="statuses.statuses"
                                  item-title="name"
                                  item-value="id"
                                  hide-details
                                  variant="outlined"
                                  label="Wybierz status"
                                  v-model="statusId"
                                  ref="statusId"
                              >
                                {{branchId}}
                              </v-select>
                              <input type="hidden" ref="branchId" :value="branchId"/>
                              <input type="hidden" ref="statusId" :value="statusId"/>
                            </v-card-text>

                            <v-card-actions>
                              <v-row align="center" justify="space-between">
                                <v-col cols="auto">
                                  <v-btn color="grey-darken-1"
                                         variant="flat"
                                         size="small"
                                         prepend-icon="mdi-close"
                                         @click="dialogUploadFile = false">Zamknij
                                  </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                  <v-btn color="green"
                                         variant="flat"
                                         size="small"
                                         prepend-icon="mdi-content-save"
                                         @click="uploadFile"
                                         >Zapisz i zamknij
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-actions>
                          </form>
                        </v-card>
                      </v-dialog>
                    </v-btn>


<!--
                    <v-dialog
                        v-model="dialogUploadFile"
                        width="auto"
                    >

                      <template v-slot:activator="{ props }">
                        <v-btn
                            color="primary"
                            v-bind="props"
                            variant="outlined"
                            prepend-icon="mdi-upload"
                            style="margin-top:9px;"
                        >
                          Dodaj załącznik
                        </v-btn>
                      </template>

                      <template v-slot:default="{ isActive }">
                      <v-card>
                        <form @submit="uploadFile">
                          <v-card-text>
                            <v-text-field ref="title" name="title" density="compact" placeholder="Nazwa własna"
                                          hide-details></v-text-field>
                            <input type="file" ref="fileInput"/>
                            <input type="hidden" ref="productId" :value="branchId"/>
                            <input type="hidden" ref="statusId" :value="statusId"/>
                            <v-select
                                class="mt-3"
                                density="compact"
                                :items="statuses.statuses"
                                item-title="name"
                                item-value="id"
                                variant="outlined"
                                v-model="statusId"
                            >
                            </v-select>
                          </v-card-text>

                          <v-card-actions>
                            <v-row align="center" justify="space-between">
                              <v-col cols="auto">
                                <v-btn color="grey-darken-1"
                                       variant="flat"
                                       size="small"
                                       prepend-icon="mdi-close"
                                       @click="dialogUploadFile = false">Zamknij
                                </v-btn>
                              </v-col>
                              <v-col cols="auto">
                                <v-btn color="green"
                                       variant="flat"
                                       size="small"
                                       prepend-icon="mdi-content-save"
                                       @click="uploadFile"
                                       :loading="saveAndClose">Zapisz i zamknij
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </form>
                      </v-card>
                      </template>
                    </v-dialog>-->
                  </v-col>
                </v-col>
              </v-row>
              <v-text-field
                  density="compact"
                  label="Nazwa"
                  v-model="label"
                  variant="outlined"
                  class="mt-2"
                  @change="updateLabel"
                  hide-details
              />
            </v-card-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <StatusesComponent
                :idBranch="branchId"
                :idProject="idProject"
                :isTitle="true"
                @update-history="updateHistory"
            ></StatusesComponent>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item value="product" v-if="branch.product">
        <v-card-item>

          {{ getReadyProductById(branch.product) }}

        </v-card-item>
      </v-window-item>
      <v-window-item value="historia">
        <v-card-item>
          <v-data-table
              :headers="headers"
              :items="history"
              :items-per-page="10"
              hide-default-footer
              class="elevation-1"
          >
          </v-data-table>
        </v-card-item>
      </v-window-item>
    </v-window>


  </v-card>
</template>
<script>
import StatusesComponent from "@/components/StatusesComponent.vue";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "BranchView",
  components: {StatusesComponent},
  props: ['branch','idProject'],
  data() {
    return {
      tab: null,

      data: null,
      label: null,
      statusId: null,
      branchId: null,
      dialog:false,
      dialogUploadFile: false,
      headers: [
        {
          title: 'Kto',
          align: 'start',
          key: 'user',
        },
        {title: 'Co', key: 'type', align: 'start'},
        {title: 'Kiedy', key: 'date'},
        {title: 'Zmiany', key: 'changes'},
      ],

    }
  },
  async created() {
    console.log(this.$props.branch);
    this.label = this.$props.branch.label;
    this.branchId = this.$props.branch.element;
    this.statusId = this.$props.branch.status;
    await this.getHistory({idBranch: this.$props.branch.element, endpoint: 'App/Entity/Branch/StatusesBranch'})
    this.history = this.getHistoryByIdBranch(this.$props.branch.element);
  },
  computed: {
    ...mapGetters(['getHistoryByIdBranch','getReadyProductById']),
        ...mapState(['statuses'])
  },
  methods: {
    updateHistory(){
      this.getHistory({idBranch: this.$props.branch.element, endpoint: 'App/Entity/Branch/StatusesBranch'})
      console.log('History',this.history );

    },
    ...mapActions([
      'getHistory',
    ]),
    updateLabel(event){
      console.log(event)
     this.data = this.$props.branch;
     this.data.label  = this.label;
      this.$emit('update', this.data)
    },

    // Wgrywanie plików
    async uploadFile(event) {
      this.saveAndClose = true;
      event.preventDefault();

      const file = this.$refs.fileInput.files[0];
      const branchId = this.$refs.branchId.value;
      const statusId = this.$refs.statusId.value;
      const title = this.$refs.title.value;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('branchId', branchId);
      formData.append('statusId', statusId);
      formData.append('title', title);


      console.log(formData);

      this.dialogUploadFile = false;

      await this.$store.dispatch('attachFileBranchStatus', formData);

      this.$notify({
        title: "Plik został przesłany. Trwa aktualizacja",
        type: 'success',
      });

      /*
      // Wykonaj żądanie API przy użyciu preferowanej metody (np. Axios)
      await axios.post('https://api.labmetry.com/api/c/productsStatus/importFile/', formData)
          .then(async () => {
            this.$notify({
              title: "Aktualizacja przebiegła pomyślnie",
              type: 'success',
            });
            await this.fetchSingleItem(this.product.id);

            this.getById(this.product.id);
          })
          .catch(() => {
            this.$notify({
              title: "Wystąpił problem podczas aktualizacji produktów",
              type: 'error',
            });
          });
      this.saveAndClose = false;
    },*/
    }
  }
}
</script>

<style>
</style>
