import axios from "axios";
export const readyProducts = {
    state: {
        readyProducts: [],
        currentProduct: null,
    },
    getters: {
        getReadyProducts: state => {
            return state.readyProducts;
        },
        totalProducts: state => {
            return state.readyProducts.length;
        },
        getReadyProductById: (state) => (id) => {
            return state.readyProducts.find(item => item.id === id);
        },

    },
    mutations: {

        setCurrentProduct(state, id) {
            state.currentProduct = id;
        },
        setReadyProducts(state, items) {
            state.readyProducts = items;
        },
        removeReadyProduct(state, itemId) {
            state.readyProducts = state.readyProducts.filter(item => item.id !== itemId);
        },
        addProduct(state, items) {
           //console.log('addProduct',items);
            state.readyProducts.push(items);
        },
        updateProduct(state, updatedProduct) {

            console.log(updatedProduct);

            const index = state.readyProducts.findIndex(item => item.id === updatedProduct.id);
            if (index !== -1) {
                // If the product with the updated ID exists, update it in the readyProducts array
                state.readyProducts.splice(index, 1, updatedProduct);
            } else {
                // If the product doesn't exist, you can choose to handle it as per your application's requirement.
                console.error(`Product with ID ${updatedProduct.id} not found.`);
            }
        },
    },
    actions: {
        async fetchSingleItem({ commit , rootState}, itemId) {
            console.log('fetchSingleItem',itemId);
            try {
                const response = await axios.get(`https://api.labmetry.com/api/products/${itemId}`, {
                headers: { Authorization: `Bearer ${rootState.token}`}
            });
                const updatedItem = response.data;

                console.log('fetchSingleItem',updatedItem);

                commit('updateProduct', updatedItem); // Wywołanie mutacji, aby zaktualizować stan
            } catch (error) {
                console.error('Błąd podczas pobierania pozycji:', error);
            }
        },
        async loadReadyProducts({ commit ,rootState}) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/products', {
                    headers: { Authorization: `Bearer ${rootState.token}`}
                });
                const items = response.data;
                commit('setReadyProducts', items);
            } catch (error) {
                console.error('Error loading items:', error);
            }
        },
        async removeReadyProduct({commit, rootState }, itemId) {
            this.loadingRemoveItem = true;
            try {
                await axios.delete(`https://api.labmetry.com/api/products/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                commit('removeReadyProduct', itemId);
            } catch (error) {
                console.error('Error removing item:', error);
            }
            this.loadingRemoveItem = false;
        },
        async addProduct({commit, rootState}, data) {
            try {
                await axios.post('https://api.labmetry.com/api/products',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;

                        console.log(items);

                        commit('addProduct', items);
                        commit('setResponseMessage', 'Produkt został dodany');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentProduct', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async copyProduct({commit}, data) {
            commit('addProduct', data);
            commit('setResponseMessage', 'Produkt został skopiowany');
            commit('setResponseStatus', 'success');
            commit('setCurrentProduct', data.id);
        },
        async updateProduct({commit, rootState}, item) {

            let fetchData = item[0];
            let productId = item[1];

            try {
                await axios.put('https://api.labmetry.com/api/products/' + productId,
                    fetchData,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateProduct', items);
                        commit('setResponseMessage', 'Produkt został zaktualizowany');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentProduct', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
