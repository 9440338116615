<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CATEGORIES'])">

    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CATEGORIES_SAVE'])"
                v-model="dialog"
                width="1000"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="green"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="small"
                    prepend-icon="mdi-plus"
                    variant="elevated"
                    @click="addItem()"
                >
                  Dodaj
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Kategoria"
                          :rules="[rules.required, rules.counter]"
                          v-model="title"
                          hide-details="auto"
                      ></v-text-field>

                      <v-select
                          label="Kategoria nadrzędna"
                          v-model="parentCategory"
                          density="compact"
                          :items="categories.categories"
                          item-value="id"
                          item-title="title"
                          class="mt-2"
                          placeholder="Kategoria nadrzędna"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-checkbox
                          density="compact"
                          :label="feature.title"
                          v-for="feature in features.features"
                          :key="feature.id"
                          v-model="selectedFeatures"
                          :value="feature.id"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                  <v-btn
                      color="gray"
                      size="small"
                      variant="tonal"
                      @click="dialog = false"
                  >
                    Anuluj
                  </v-btn>
                  <v-btn
                      color="green"
                      size="small"
                      variant="tonal"
                      prepend-icon="mdi-content-save"
                      @click="fetchAddCategories(itemId)"
                      :loading="loadingSaveItem"
                  >
                    Zapisz
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="categories.categories"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td v-if="item.parent">{{ item.parent.title }}</td>
                <td v-else>Brak</td>
                <td>
                  <span v-for="features in item.features" :key="features.id"> [ {{
                      features.title
                    }} ] </span>
                </td>
                <td align="end">
                  <v-btn
                      v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CATEGORIES_SAVE'])"
                      class="mx-2" append-icon="mdi-pencil-box" size="small" fab dark color="blue"
                      @click="editItem(item)">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteCategory(item.id)"
                                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CATEGORIES_REMOVE'])"
                  >
                  </RemoveButton>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import {useStorage} from "vue3-storage";

export default {
  name: 'CategoriesView',
  components: {RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      title: '',
      breadcrumbs: [
        {
          title: 'Ustawienia',
          disabled: true,
        },
        {
          title: 'Kategorie',
          disabled: true,
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Rodzic', key: 'parent', align: 'start'},
        {title: 'Cechy', key: 'features', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      //loaders
      loadingSaveItem: false,
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
      selectedFeatures: [],
      parentCategory: null,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["categories", "features"])
  },
  methods: {
    ...mapActions(['addCategory']),
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    async fetchAddCategories(idCategory) {
      this.loadingSaveItem = true;
      if (idCategory) {
        if (idCategory == this.parentCategory) {
          this.$notify({
            type: this.$store.state.users.responseStatus,
            title: "Kategoria nie może być sama dla siebie rodzicem"
          });
          this.loadingSaveItem = false;
          return false;
        } else {
          await this.$store.dispatch('updateCategory', {
            item: {
              title: this.title,
              features: this.selectedFeatures,
              parentCategory: this.parentCategory,
            }, idCategory: idCategory
          });
        }
      } else {
        await this.$store.dispatch('addCategory', {
          title: this.title,
          features: this.selectedFeatures,
          parentCategory: this.parentCategory,
        });
      }
      this.parentCategory = null;
      this.selectedFeatures = [];
      this.title = null;

      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});

      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
      this.loadingSaveItem = false;
    },
    async fetchDeleteCategory(idCategory) {
      await this.$store.dispatch('removeCategory', idCategory);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(category) {
      this.dialog = true;
      this.title = category.title;
      this.itemId = category.id;
      if (category.features) {
        this.selectedFeatures = category.features.map(item => item.id);
      }
      if(category.parent) {
        this.parentCategory = category.parent.id;
      }else{
        this.parentCategory = null;
      }

    },
    addItem() {
      this.title = null;
      this.itemId = null;
      this.selectedFeatures = [];
      this.parentCategory = null;
    }
  }
}
</script>
