<template>
  <v-card
      class="mx-auto"
      max-width="368"
      elevation="0"
  >


    <v-card-item :title="$props.title">
      <template v-slot:subtitle>
        <template v-if="$props.alert">
          <v-icon
              icon="mdi-alert"
              size="18"
              color="error"
              class="me-1 pb-1"
          ></v-icon>

          {{ $props.alert }}
        </template>
      </template>
    </v-card-item>

    <v-card-text class="py-0">
      <v-row align="center" no-gutters>
        <v-col
            class="text-h2"
            cols="6"
        >
          {{ $props.count }}
        </v-col>

        <v-col cols="6" class="text-right">
          <v-icon
              color="error"
              :icon="$props.icon"
              size="88"
          ></v-icon>
        </v-col>
      </v-row>
    </v-card-text>

    <div class="d-flex py-3 justify-space-between">
      <v-list-item
          density="compact"
          prepend-icon="mdi-calendar-month"
      >
        <v-list-item-subtitle>20/10/2023</v-list-item-subtitle>
      </v-list-item>

      <v-list-item
          density="compact"
          prepend-icon="mdi-cart"
      >
        <v-list-item-subtitle>4</v-list-item-subtitle>
      </v-list-item>
    </div>

    <v-expand-transition>
      <div v-if="expand">
        <div class="py-2">
          <v-slider
              v-model="$props.time"
              :max="6"
              :step="1"
              :ticks="labels"
              class="mx-4"
              color="primary"
              density="compact"
              hide-details
              show-ticks="always"
              thumb-size="10"
          ></v-slider>
        </div>

        <v-list class="bg-transparent">
          <v-list-item
              v-for="item in forecast"
              :key="item.day"
              :title="item.day"
              :append-icon="item.icon"
              :subtitle="item.temp"
          >
          </v-list-item>
        </v-list>
      </div>
    </v-expand-transition>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn @click="expand = !expand">
        {{ !expand ? 'Ostatni projekt' : 'Ukryj' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'BoxComponent',
  props: {
    title: null,
    alert: {},
    forecast: {},
    labels: {},
    time: {},
    count: {},
    icon:{}
  },
  data: () => {
    return {
      expand: false,
    }
  }
}
</script>
