import axios from "axios";

const success_addItemsInWarehouses = "Magazyn dodano poprawnie";
const success_updateItemsInWarehouses = "Magazyn zaktualizowano poprawnie";
const success_removeItemsInWarehouses = "Magazyn usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const itemsInWarehouses = {
    state: {
        itemsInWarehouses: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getItemsInWarehouses: state => {
            return state.itemsInWarehouses;
        },
        getItemsInWarehousesById: (state) => (id) => {
            return state.itemsInWarehouses.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setItemsInWarehouses(state, items) {
            state.itemsInWarehouses = items;
        },
        addItemsInWarehouses(state, items) {
            state.itemsInWarehouses.push(items);
        },
        /* updateWarehouse(state, data) {
             const index = state.warehouses.findIndex(item => Number(item.id) === Number(data.id));
             state.warehouses.splice(index, 1, data.item);
         },*/

        updateItemsInWarehouses(state, data) {
            //console.log('updateCategory',data, state.categories);
            const index = state.itemsInWarehouses.findIndex(item => item.id === data.id);
            state.itemsInWarehouses.splice(index, 1, {
                id: data.id,
            });
        },

        removeItemsInWarehouses(state, itemId) {
            state.itemsInWarehouses = state.itemsInWarehouses.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadItemsInWarehouses({commit, rootState}) {

            try {
                const response = await axios.get(host + '/api/items_in_warehouses/',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setItemsInWarehouses', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addItemsInWarehouses({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/items_in_warehouses',
                    {
                        id: data.id,
                    },
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addItemsInWarehouses', items);
                        commit('setResponseMessage', success_addItemsInWarehouses);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateItemsInWarehouses({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/items_in_warehouses/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateItemsInWarehouses', items);
                        commit('setResponseMessage', success_updateItemsInWarehouses);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeItemsInWarehouses({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/items_in_warehouses/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeItemsInWarehouses', id);
                        commit('setResponseMessage', success_removeItemsInWarehouses);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
