<template>
  <v-btn color="success"
         prepend-icon="mdi-content-save-check"
         variant="elevated" size="small" @click="$emit('action')"
         class="mb-2 ms-1"
          :disabled="disabledSave" :loading="loadingSave"
  >{{ $props.label ?? 'Zapisz' }}
  </v-btn>
</template>
<script>
export default {
  name: "SaveButton",
  props: ['label','disabledSave','loadingSave'],
}
</script>
