<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCERS'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCERS_SAVE'])"
                v-model="dialog"
                width="600"
                height="500"
            >
              <template v-slot:activator="{ props }">
                <AddButton v-bind="props" class="float-end" style="margin-top:6px"></AddButton>
              </template>
              <v-card>
                <v-card-text>
                  <v-form>
                    <v-text-field
                        label="Producent"
                        density="compact"
                        v-model="name"
                        hide-details="auto"
                        class="mb-5"
                        counter
                        maxlength="20"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <ActionsButton @annulled="dialog = false" @save="fetchAdd(itemId)"></ActionsButton>
              </v-card>
            </v-dialog>

          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="producers.producers"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td align="end">
                  <v-btn
                      v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCERS_SAVE'])"
                      class="mx-2" append-icon="mdi-pencil-box" size="small" fab dark color="blue"
                         @click="editItem(item)">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDelete(item.id)"
                                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCERS_REMOVE'])">

                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>



  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>


<script>
import {mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import ActionsButton from "@/components/navigation/ActionsButton.vue";
import AddButton from "@/components/navigation/element/AddButton.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: 'ProducersView',
  components: {RemoveButton, AddButton, ActionsButton, BreadcrumbsComponent},
  data: () => {
    return {
      name: '',
      breadcrumbs: [
        {
          title: 'Ustawienia',
          disabled: true,
        },
        {
          title: 'Producenci',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'name', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      //loaders
      loadingSaveItem: false,
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["producers"])
  },

  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    async fetchAdd(idItem) {
      this.loadingSaveItem=true;
      if(idItem){
        await this.$store.dispatch('updateProducer', {item: {name: this.name}, id: idItem});
      }else{
        await this.$store.dispatch('addProducer', {name: this.name});
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if(this.$store.state.users.responseStatus === 'success'){
        this.dialog = false;
        this.itemId = null;
      }
      this.loadingSaveItem=false;
    },
    async fetchDelete(idUnit) {
      await this.$store.dispatch('removeProducer', idUnit);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(unit) {
      this.dialog = true
      this.title = unit.title
      this.itemId = unit.id
    },
  }
}
</script>
