<template>
  <v-card-item v-if="node.isPublic === true">
    <label v-if="oneChildren !== true">
      <input type="checkbox" :value="node.id" :disabled="checkSelected(parent) === undefined"
             @click="handleCheckboxClick"
      > {{ node.label }}
    </label>

    <label v-if="oneChildren === true">
      <input type="radio" :name="'radio_' + parent" :value="node.id" :disabled="checkSelected(parent) === undefined"
             @click="handleCheckboxClick" :required="node.isRequired"
      > {{ node.label }}
    </label>

    <v-card-subtitle v-if="node.type === 'ready'">
    Cena całkowita: {{node.price }}<br>
    Cena jednostki: {{node.priceUnit }}
    </v-card-subtitle>


    <v-card-item v-for="feature in node.features" :key="feature.id">
      <v-card-subtitle>{{ feature.label ?? feature.title }}
        <strong v-if="feature.value !== undefined">{{ feature.value }}</strong>


      </v-card-subtitle>
      <v-text-field :disabled="checkSelected(node.id, feature.id) === undefined"
                    variant="outlined" density="compact"
                    :value="checkSelected(node.id, feature.id) ? checkSelected(node.id, feature.id).value : ''"
                    v-if="feature.input === true"
                    @input="updateInput(node.id, feature.id, $event.target.value)"
      ></v-text-field>
    </v-card-item>
    <v-card-item v-if="node.children && node.children.length > 0">
      <BlockTree :disabled="true"

                 v-for="child in node.children" :key="child.id" :node="child"
                 :selectedProducts="selectedProducts"
                 :oneChildren="node.oneChildren"
                 :parent="node.id"
                 @handleSelected="handleSelected"
                 @checkSelected="checkSelected"
                 @updateInput="updateInput"

      />
    </v-card-item>
  </v-card-item>
</template>

<script>
export default {
  name: "BlockTree",
  props: {
    node: {
      type: Object,
      required: true,
    },
    selectedProducts: {
      type: Array,
      required: true,
    },
    oneChildren: {
      type: Boolean,
      required: false,
    },
    parent: {
      type: Number,
      required: false,
    },
  },
  methods: {
    handleCheckboxClick() {
      this.$emit('handleSelected', this.node.id);
    },

    checkSelected(idTree, idFeature = null) {
      if (idFeature !== null) {
        const row = this.selectedProducts.find((el) => el.idTree === Number(idTree) && el.idFeature === Number(idFeature));
        return (row !== -1) ? row : false;
      } else {
        const row = this.selectedProducts.find((el) => el.idTree === Number(idTree));
        return (row !== -1) ? row : false;
      }
    },

    handleSelected(idTree, idFeature = null, value = null, parent = null) {
      // Funkcja do przekazania zaznaczonego identyfikatora do komponentu rodzica.
      this.$emit('handleSelected', idTree, idFeature, value, parent);
    },
    updateInput(idTree, idFeature = null, value = null) {
      this.$emit('updateInput', idTree, idFeature, value);
    }

  },
}
</script>

<style scoped>

</style>
