<template>
  <v-btn color="green"
         prepend-icon="mdi-plus"
         variant="elevated" size="small" @click="$emit('action')"
  >{{ $props.label ?? 'Dodaj' }}
  </v-btn>
</template>
<script>
export default {
  name: "AddButton",
  props: ['label'],
}
</script>
