import axios from "axios";

const success_addCurrencies = "Przykład dodano poprawnie";
const success_updateCurrencies = "Przykład zaktualizowano poprawnie";
const success_removeCurrencies = "Przykład usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const currencies = {
    state: {
        currencies: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getCurrencies: state => {
            return state.currencies;
        },
        getCurrenciesById: (state) => (id) => {
            return state.currencies.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setCurrencies(state, items) {
            state.currencies = items;
        },
        addCurrencies(state, items) {
            state.currencies.push(items);
        },
        updateCurrencies(state, data) {
            const index = state.currencies.findIndex(item => Number(item.id) === Number(data.id));
            state.currencies.splice(index, 1, data);
        },
        removeCurrencies(state, itemId) {
            state.currencies = state.currencies.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadCurrencies({commit, rootState}) {
            try {
                const response = await axios.get(host + '/api/currencies',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setCurrencies', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addCurrencies({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/currencies',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addCurrencies', items);
                        commit('setResponseMessage', success_addCurrencies);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateCurrencies({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/currencies/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateCurrencies', items);
                        commit('setResponseMessage', success_updateCurrencies);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeCurrencies({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/currencies/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeCurrencies', id);
                        commit('setResponseMessage', success_removeCurrencies);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
