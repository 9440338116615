<template>
  <v-card-actions class="justify-space-between">
    <AnnulledButton label="Anuluj" @action="$emit('annulled')"></AnnulledButton>
    <SaveButton :label="labelSave ?? 'Zapisz'" @action="$emit('save')" :disabledSave="disabledSave" :loadingSave="loadingSave"></SaveButton>
  </v-card-actions>
</template>
<script>
import AnnulledButton from "@/components/navigation/element/AnnulledButton.vue";
import SaveButton from "@/components/navigation/element/SaveButton.vue";

export default {
  name: "ActionsButton",
  props: ['disabledSave','loadingSave', 'labelSave'],
  components: {SaveButton, AnnulledButton}
}
</script>
