<template>
  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="small" fab dark color="success"
         @click="dialog = true"
  >
    Szczegóły
  </v-btn>
  <v-dialog
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar>
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Szczegóły projektu</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!--                          <v-btn
                                        variant="text"
                                        @click="detailModal[item.id] = false"
                                    >
                                      Zapisz
                                    </v-btn>-->
        </v-toolbar-items>
      </v-toolbar>

      <v-card-item>
        <v-row>
          <v-col>
            <strong>Nazwa projektu:</strong> {{ project.title }}<br/>
            <strong>Data stworzenia:</strong> {{ formatDate(project.createdAt) }}<br/>
            <strong>Data ostatniej aktualizacji:</strong> {{ formatDate(project.modifiedAt) }}<br/>
            <strong>Planowana data zakończenia projektu:</strong> {{ formatDate(project.deadline) }}<br/>
            <strong>Magazyn domyślny:</strong> {{ project.warehouse ?? 'brak' }} <br/>
            <strong>Osoba odpowiedzialna:</strong> {{ project.responsibility }} <br/>
            <strong>Klient:</strong> {{ project.customer }} <br/>
          </v-col>
          <v-col>

            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="products"
                item-value="value"
                return-object
                show-select
            >
            </v-data-table>

            <v-btn color="green"
                   variant="flat"
                   size="small"
                   prepend-icon="mdi-content-save"
                   @click="newOrder()"
                   :loading="loadingOrder"
                   :disabled="buttonOrder"
            >
              Zgłoś zapotrzebowanie
            </v-btn>

          </v-col>
        </v-row>
      </v-card-item>


    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProjectDetail",
  props: ['idProject'],
  data: () => {
    return {
      dialog:false,
      project: null,
      products: [],
      //loader
      loadingOrder:false,
      selected: [],
      headers: [
        { title: 'Gałąź', key: 'branchTitle' },
        { title: 'Produkt', key: 'productTitle' },
        { title: 'Ilość (??)', key: 'quantity' }
      ],
      buttonOrder: true,
    }
  },
  computed:{
    ...mapGetters(['getProjectById'])
  },
  mounted(){
    this.project = this.getProjectById(this.$props.idProject);
    this.getProductsFromProject(this.$props.idProject);
  },
  methods:{
    ...mapActions(['addOrder']),
    getProductsFromProject(idProject) {
      let project = this.getProjectById(idProject);
      if (project.tree !== undefined) {
        let tree = JSON.parse(project.tree);
        this.products = this.extractProducts(tree);
        if(this.products.length > 0){
          this.buttonOrder = false;
        }
      }
    },
    extractProducts(data) {
      const products = [];

      function recursiveExtract(node) {
        if (node && node.product) {

          console.log(node);

          products.push({
            id: node.product,
            productTitle: node.title,
            idBranch: node.element,
            branchTitle: node.label,
            quantity: node.quantity,
            price: node.price,
            category: node.category ? node.category.title : '-',
            currency: node.currency,
            ean: node.ean,
            unit: node.unit ? node.unit.title : '-'
          });
        }

        if (node && node.children && node.children.length > 0) {
          node.children.forEach(childNode => {
            recursiveExtract(childNode);
          });
        }
      }

      recursiveExtract(data);

      return products;
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    async newOrder(){
      this.loadingOrder = true;
      console.log('Zamawiamy', this.selected)

      let orderDetails = [];
      let product = {};

      this.selected.forEach(item => {
        product = {
          product: item.id,
          warehouse: 1,
          project: this.$props.idProject,
          quantity: item.quantity,
          additionalIdentifier: item.idBranch
        }
        console.log(item)
        orderDetails.push(product)
      })

      await this.$store.dispatch('addOrder', {item: {orderDetails:orderDetails}});
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      console.log('orderDetails', orderDetails)

      this.loadingOrder = false;
    }
  },
}
</script>

<style scoped>

</style>
