<template>
  <div>
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj zamówienia"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-card-item>
            <v-data-table
                v-model:items-per-page="itemsPerPage"
                :headers="headers"
                :items-length="totalItems"
                :items="items"
                :loading="loading"
                :search="search"
                class="elevation-0"
                item-value="id"
            >

              <template v-slot:item="{ item }">
                <tr>
                  <td>Jeżeli status jest: </td>
                  <td>{{ item.status }}</td>
                  <td>to:</td>
                  <td>{{ item.task }}</td>
                  <td align="end">
                    <RemoveButton
                        v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">
                    </RemoveButton>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-item>
          ReceiveTheGoods
          <ul>
            <li>Możliwosć zmiany statusu / przyjęchało / bez oznaczenia miejsca na magazynie</li>
            <li>Przycisk, z możliwością przydzielenia na magazyn / Lista elementów na magazynie / Drzewko</li>
            <li>Pole ilość</li>
          </ul>
        </v-card>
      </v-main>
    </v-layout>

  </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: "ReceiveTheGoodsView",
  components: {RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Magazyn',
          disabled: true,
        },
        {
          title: 'Przyjęcie',
          disabled: true,
        },
      ],

      //Table
      itemsPerPage: 50,
      loading: false,
      search: '',
      headers: [
        {title: 'Nr zamówienia', key: 'idOrder', align: 'start'},
        {title: 'Produkt', key: 'product', align: 'start'},
        {title: 'Status', key: 'status', align: 'start', colspan:2},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      items: [
        { idOrder: 'LB/01012013', product: 'Produkt nr 1', status: 'Oczekujący'},
      ],
      totalItems: 7

    }

  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>

<style scoped>

</style>
