<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_AUTOMATION'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-item>
            <v-row align="center"
                   justify="center">
              <v-col cols="auto">Wybierz</v-col>
              <v-col cols="auto">
                <v-select
                    density="compact"
                    style="width:200px;"
                    size="x-small"
                    hide-details
                    placeholder="status"
                    :items="['nowy', 'kolorowy', 'wyśniowy', 'Georgia', 'Texas', 'Wyoming']"></v-select>
              </v-col>
              <v-col cols="auto">a następnie</v-col>
              <v-col cols="auto">
                <v-select
                    size="x-small"
                    hide-details
                    style="width:200px;"
                    placeholder="wybierz działanie"
                    density="compact" :items="['Wyślij email', 'Dodaj zamówienie']"></v-select>
              </v-col>
              <v-col>
                <SaveButton label="Dodaj"></SaveButton>
              </v-col>
            </v-row>
          </v-card-item>
          <v-card-item>
            <v-data-table
                v-model:items-per-page="itemsPerPage"
                :headers="headers"
                :items-length="totalItems"
                :items="items"
                :loading="loading"
                :search="search"
                class="elevation-0"
                item-value="id"
            >

              <template v-slot:item="{ item }">
                <tr>
                  <td>Jeżeli status jest: </td>
                  <td>{{ item.status }}</td>
                  <td>to:</td>
                  <td>{{ item.task }}</td>
                  <td align="end">
                    <RemoveButton
                                  v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">
                    </RemoveButton>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-item>
        </v-card>
      </v-main>
    </v-layout>

  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import SaveButton from "@/components/navigation/element/SaveButton.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: "AutomationView",
  components: {RemoveButton, SaveButton, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Automatyzacja',
          disabled: true,
        },
        {
          title: 'Statusy',
          disabled: true,
        },
      ],


      //Table
      itemsPerPage: 50,
      loading: false,
      search: '',
      headers: [
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Status', key: 'status', align: 'start'},
        {title: 'Zadanie', key: 'task', align: 'start', colspan:2},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      items: [
        { status: 'Oczekujący', task: 'Wyślij email'},
        { status: 'Oczekujący', task: 'Wyślij email'},
        { status: 'Oczekujący', task: 'Wyślij email'},
        { status: 'Oczekujący', task: 'Wyślij email'},
        { status: 'Oczekujący', task: 'Wyślij email'},
        { status: 'Oczekujący', task: 'Wyślij email'},
        { status: 'Oczekujący', task: 'Wyślij email'},
      ],
      totalItems: 7
    }

  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>

<style scoped>

</style>
