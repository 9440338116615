import axios from "axios";
export const users = {
    state: {
        users: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getUsers: state => {
            return state.users;
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setUsers(state, items) {
            state.users = items;
        },
        addUser(state, items) {
            state.users.push(items);
        },
        removeUser(state, itemId) {
            state.users = state.users.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadUsers({commit, rootState }) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/users',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setUsers', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addUser({commit, rootState }, data) {
            try {
                await axios.post('https://api.labmetry.com/api/users',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addUser', items);
                        commit('setResponseMessage', 'Użytkownik został dodany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeUser({commit, rootState }, itemId) {
            try {
                await axios.delete('https://api.labmetry.com/api/users/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeUser', itemId);
                        commit('setResponseMessage', 'Użytkownik został usunięty');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async newPassword({commit}, data) {
            try {
                await axios.post('https://api.labmetry.com/api/reset-password',
                    data)
                    .then(response => {
                        const items = response.data;
                        commit('setResponseMessage', items);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async remindPassword({commit}, data) {
            try {
                await axios.post('https://api.labmetry.com/api/remind-password',
                    data)
                    .then(response => {
                        const items = response.data;
                        commit('setResponseMessage', items);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
