<template>
  <v-table density="compact">
    <thead>
    <tr>
      <th colspan="5">Główne uprawnienia
        <!--                          <p>Aktualna rola użytkownika: {{ roles }}</p>-->

        <!-- <div>

    <button @click="logUserRole">Wypisz rolę użytkownika w konsoli</button>
  </div>-->
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="checkRoles(['ROLE_ADMIN'])">
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="role_super_admin"
                 :checked="roles.indexOf('ROLE_SUPER_ADMIN')> -1"
                 @change="updateRole('ROLE_SUPER_ADMIN')">
          <label class="form-check-label" for="role_super_admin">
            Super Administrator
          </label>
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="role_admin"
                 :checked="roles.indexOf('ROLE_ADMIN')> -1"
                 @change="updateRole('ROLE_ADMIN')">
          <label class="form-check-label" for="role_admin">
            Administrator
          </label>
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="role_owner"
                 :checked="roles.indexOf('ROLE_OWNER')> -1"
                 @change="updateRole('ROLE_OWNER')">
          <label class="form-check-label" for="role_owner">
            Właściciel
          </label>
        </div>
      </td>
    </tr>

    <tr>
      <th class="text-left">
        Sekcja
      </th>
      <th class="text-left">
        Odczyt
      </th>
      <th class="text-left">
        Zapis
      </th>
      <th class="text-left">
        Usuwanie
      </th>
      <th class="text-left">
        Wszystkie
      </th>
    </tr>

    <tr>
      <td>Projekty</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_PROJECTS')> -1"
                 @change="updateRole('ROLE_PROJECTS')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_PROJECTS_SAVE')> -1"
                 @change="updateRole('ROLE_PROJECTS_SAVE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_PROJECTS_REMOVE')> -1"
                 @change="updateRole('ROLE_PROJECTS_REMOVE')">
        </div>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>Gantt</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_GANTT')> -1"
                 @change="updateRole('ROLE_GANTT')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_GANTT_SAVE')> -1"
                 @change="updateRole('ROLE_GANTT_SAVE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_GANTT_REMOVE')> -1"
                 @change="updateRole('ROLE_GANTT_REMOVE')">
        </div>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>Produkty</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_PRODUCTS')> -1"
                 @change="updateRole('ROLE_PRODUCTS')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_PRODUCTS_SAVE')> -1"
                 @change="updateRole('ROLE_PRODUCTS_SAVE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_PRODUCTS_REMOVE')> -1"
                 @change="updateRole('ROLE_PRODUCTS_REMOVE')">
        </div>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>Zapotrzebowanie</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_DEMAND')> -1"
                 @change="updateRole('ROLE_DEMAND')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_DEMAND_SAVE')> -1"
                 @change="updateRole('ROLE_DEMAND_SAVE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_DEMAND_REMOVE')> -1"
                 @change="updateRole('ROLE_DEMAND_REMOVE')">
        </div>
      </td>
      <td></td>
    </tr>

    <tr>
      <td>Zamówienia</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_ORDER')> -1"
                 @change="updateRole('ROLE_ORDER')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_ORDER_SAVE')> -1"
                 @change="updateRole('ROLE_ORDER_SAVE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_ORDER_REMOVE')> -1"
                 @change="updateRole('ROLE_ORDER_REMOVE')">
        </div>
      </td>
      <td></td>
    </tr>

    <tr>
      <td>Magazyn</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_WAREHOUSE')> -1"
                 @change="updateRole('ROLE_WAREHOUSE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_WAREHOUSE_SAVE')> -1"
                 @change="updateRole('ROLE_WAREHOUSE_SAVE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_WAREHOUSE_REMOVE')> -1"
                 @change="updateRole('ROLE_WAREHOUSE_REMOVE')">
        </div>
      </td>
      <td></td>
    </tr>

    <tr>
      <td>Biblioteka plików</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_LIBRARY')> -1"
                 @change="updateRole('ROLE_LIBRARY')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_LIBRARY_SAVE')> -1"
                 @change="updateRole('ROLE_LIBRARY_SAVE')">
        </div>
      </td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_LIBRARY_REMOVE')> -1"
                 @change="updateRole('ROLE_LIBRARY_REMOVE')">
        </div>
      </td>
      <td></td>
    </tr>

    <tr>
      <td>Ustawienia</td>
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 :checked="roles.indexOf('ROLE_SETTING')> -1"
                 @change="updateRole('ROLE_SETTING')">
        </div>
      </td>
      <td>
      </td>
      <td>

      </td>
      <td></td>
    </tr>
    </tbody>
  </v-table>
</template>

<script>
import {useStorage} from "vue3-storage";

export default {
  name: "PermissionsView",
  props:['roles'],
  data: () => {
    return {

    }
  },
  methods:{
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    updateRole (controller) {
      let find = this.$props.roles.find((el) => el === controller)
      if(find){
        let index = this.$props.roles.indexOf(find);
        if (index > -1) {
          this.$props.roles.splice(index, 1);
        }
      }else{
        this.$props.roles.push(controller)
      }
    },
  }
}
</script>

<style scoped>

</style>
