import axios from "axios";
export const branchStatuses = {
    state: {
        branchStatuses: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getBranchStatuses: state => {
            return state.branchStatuses;
        },
        getBranchStatusesByProjectId: (state) => (idBranch) => {
            return state.branchStatuses.find(item =>
                Number(item.idBranch) === Number(idBranch)
            );
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setBranchStatuses(state, items) {
            state.branchStatuses = items;
        },
        addBranchStatus(state, items) {
            state.branchStatuses.push(items);
        },
        updateBranchStatus(state, data) {

            console.log(state.branchStatuses);

            const index = state.branchStatuses.findIndex(item => item.idBranch === data.idBranch);
            console.log(data);
            state.branchStatuses.splice(index, 1, data);
        },
        removeBranchStatus(state, itemId) {
            state.branchStatuses = state.branchStatuses.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadBranchStatuses({commit, rootState }) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/branches',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setBranchStatuses', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async putBranchStatus({commit, rootState }, data) {
            try {
                const response = await axios.put('https://api.labmetry.com/api/branches/' + data.id + '/status',
                    data.item ,{headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('updateBranchStatus', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async attachFileBranchStatus({commit, rootState }, data) {
            try {
                const response = await axios.post('https://api.labmetry.com/api/branches/import',
                    data ,{headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('updateBranchStatus', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
