<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3" style="width:800px; height: 100%;">

        <div style="width:100%; height: 100%;">

          <GanttElastic
              v-if="loadedGantt"
              :options="options" :tasks="tasks"
              @chart-task-click="handleTaskClick"
          >
            <template v-slot:header>
              <GanttElasticHeader2></GanttElasticHeader2>
            </template>

          </GanttElastic>
        </div>
      </v-main>
    </v-layout>

    <GanttDialog :dialog="dialog" @dialog="dialog = false" :currentTask="currentTask"/>

  </div>
</template>
<script>
import dayjs from "dayjs";
import {GanttElastic, GanttElasticHeader2} from "gantt-elastic-wrapvue3"
import "gantt-elastic-wrapvue3/dist/style.css"
import {useStorage} from "vue3-storage";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {mapGetters} from "vuex";
import GanttDialog from "@/views/gantt/GanttDialog.vue";

function getDate(hours) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const timeStamp = new Date(
      currentYear,
      currentMonth,
      currentDay,
      0,
      0,
      0
  ).getTime();
  return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
}

let tasks = [
  {
    id: 1,
    label: "Make some noise",
    user:
        '<a href="https://www.google.com/search?q=John+Doe" target="_blank" style="color:#0077c0;">John Doe</a>',
    start: getDate(-24 * 5),
    duration: 15 * 24 * 60 * 60 * 1000,
    percent: 85,
    type: "project"
    //collapsed: true,
  },
  {
    id: 2,
    label: "With great power comes great responsibility",
    user:
        '<a href="https://www.google.com/search?q=Peter+Parker" target="_blank" style="color:#0077c0;">Peter Parker</a>',
    parentId: 1,
    start: getDate(-24 * 4),
    duration: 4 * 24 * 60 * 60 * 1000,
    percent: 50,
    type: "milestone",
    collapsed: true,
    style: {
      base: {
        fill: "#1EBC61",
        stroke: "#0EAC51"
      }
    }
  },
  {
    id: 3,
    label: "Courage is being scared to death, but saddling up anyway.",
    user:
        '<a href="https://www.google.com/search?q=John+Wayne" target="_blank" style="color:#0077c0;">John Wayne</a>',
    parentId: 2,
    start: getDate(-24 * 3),
    duration: 2 * 24 * 60 * 60 * 1000,
    percent: 100,
    type: "task"
  },
  {
    id: 4,
    label: "Put that toy AWAY!",
    user:
        '<a href="https://www.google.com/search?q=Clark+Kent" target="_blank" style="color:#0077c0;">Clark Kent</a>',
    start: getDate(-24 * 2),
    duration: 2 * 24 * 60 * 60 * 1000,
    percent: 50,
    type: "task",
    dependentOn: [3]
  },
  {
    id: 5,
    label:
        "One billion, gajillion, fafillion... shabadylu...mil...shabady......uh, Yen.",
    user:
        '<a href="https://www.google.com/search?q=Austin+Powers" target="_blank" style="color:#0077c0;">Austin Powers</a>',
    parentId: 4,
    start: getDate(0),
    duration: 2 * 24 * 60 * 60 * 1000,
    percent: 10,
    type: "milestone",
    style: {
      base: {
        fill: "#0287D0",
        stroke: "#0077C0"
      }
    }
  },
  {
    id: 6,
    label: "Butch Mario and the Luigi Kid",
    user:
        '<a href="https://www.google.com/search?q=Mario+Bros" target="_blank" style="color:#0077c0;">Mario Bros</a>',
    parentId: 5,
    start: getDate(24),
    duration: 1 * 24 * 60 * 60 * 1000,
    percent: 50,
    type: "task",
    collapsed: true,
    style: {
      base: {
        fill: "#8E44AD",
        stroke: "#7E349D"
      }
    }
  },
  {
    id: 7,
    label: "Devon, the old man wanted me, it was his dying request",
    user:
        '<a href="https://www.google.com/search?q=Knight+Rider" target="_blank" style="color:#0077c0;">Knight Rider</a>',
    parentId: 2,
    dependentOn: [6],
    start: getDate(24 * 2),
    duration: 4 * 60 * 60 * 1000,
    percent: 20,
    type: "task",
    collapsed: true
  },
  {
    id: 8,
    label: "Hey, Baby! Anybody ever tell you I have beautiful eyes?",
    user:
        '<a href="https://www.google.com/search?q=Johhny+Bravo" target="_blank" style="color:#0077c0;">Johhny Bravo</a>',
    parentId: 7,
    dependentOn: [7],
    start: getDate(24 * 3),
    duration: 1 * 24 * 60 * 60 * 1000,
    percent: 0,
    type: "task"
  },
  {
    id: 9,
    label:
        "This better be important, woman. You are interrupting my very delicate calculations.",
    user:
        '<a href="https://www.google.com/search?q=Dexter\'s+Laboratory" target="_blank" style="color:#0077c0;">Dexter\'s Laboratory</a>',
    parentId: 8,
    dependentOn: [8, 7],
    start: getDate(24 * 4),
    duration: 4 * 60 * 60 * 1000,
    percent: 20,
    type: "task",
    style: {
      base: {
        fill: "#8E44AD",
        stroke: "#7E349D"
      }
    }
  },
  {
    id: 10,
    label: "current task",
    user:
        '<a href="https://www.google.com/search?q=Johnattan+Owens" target="_blank" style="color:#0077c0;">Johnattan Owens</a>',
    start: getDate(24 * 5),
    duration: 24 * 60 * 60 * 1000,
    percent: 0,
    type: "task"
  },
  {
    id: 11,
    label: "test task",
    user:
        '<a href="https://www.google.com/search?q=Johnattan+Owens" target="_blank" style="color:#0077c0;">Johnattan Owens</a>',
    start: getDate(24 * 6),
    duration: 24 * 60 * 60 * 1000,
    percent: 0,
    type: "task"
  },
  {
    id: 12,
    label: "test task",
    user:
        '<a href="https://www.google.com/search?q=Johnattan+Owens" target="_blank" style="color:#0077c0;">Johnattan Owens</a>',
    start: getDate(24 * 7),
    duration: 24 * 60 * 60 * 1000,
    percent: 0,
    type: "task",
    parentId: 11
  },
  {
    id: 13,
    label: "test task",
    user:
        '<a href="https://www.google.com/search?q=Johnattan+Owens" target="_blank" style="color:#0077c0;">Johnattan Owens</a>',
    start: getDate(24 * 8),
    duration: 24 * 60 * 60 * 1000,
    percent: 0,
    type: "task"
  },
  {
    id: 14,
    label: "test task",
    user:
        '<a href="https://www.google.com/search?q=Johnattan+Owens" target="_blank" style="color:#0077c0;">Johnattan Owens</a>',
    start: getDate(24 * 9),
    duration: 24 * 60 * 60 * 1000,
    percent: 0,
    type: "task"
  },
  {
    id: 15,
    label: "test task",
    user:
        '<a href="https://www.google.com/search?q=Johnattan+Owens" target="_blank" style="color:#0077c0;">Johnattan Owens</a>',
    start: getDate(24 * 16),
    duration: 24 * 60 * 60 * 1000,
    percent: 0,
    type: "task"
  }
];
let options = {
  taskMapping: {
    progress: "percent"
  },
  maxRows: 100,
  maxHeight: 500,
  title: {
    label: "Your project title as html (link or whatever...)",
    html: false
  },
  row: {
    height: 24
  },
  calendar: {
    hour: {
      display: true
    }
  },
  chart: {
    progress: {
      bar: false
    },
    expander: {
      display: true
    }
  },
  taskList: {
    expander: {
      straight: false
    },
    columns: [
      {
        id: 1,
        label: "ID",
        value: "id",
        width: 40
      },
      {
        id: 2,
        label: "Description",
        value: "label",
        width: 200,
        expander: true,
        html: true,
        events: {
          click({data, column}) {
            alert("description clicked!\n" + data.label + column);
          }
        }
      },
     /* {
        id: 3,
        label: "Assigned to",
        value: "user",
        width: 130,
        html: true
      },*/
      {
        id: 3,
        label: "Start",
        value: task => dayjs(task.start).format("YYYY-MM-DD"),
        width: 78
      },
     /* {
        id: 4,
        label: "Type",
        value: "type",
        width: 68
      },
      {
        id: 5,
        label: "%",
        value: "progress",
        width: 35,
        style: {
          "task-list-header-label": {
            "text-align": "center",
            width: "100%"
          },
          "task-list-item-value-container": {
            "text-align": "center",
            width: "100%"
          }
        }
      }*/
    ]
  },
  locale:{
    code:'pl',
    name: 'pl', // name String
    weekdays: 'Poniedziałek_Wtorek_Środa_Czwartek_Piątek_Sobota_Niedziela'.split('_'), // weekdays Array
    weekdaysShort: 'Pon_Wto_Śro_Czw_Pią_Sob_Nie'.split('_'), // OPTIONAL, short weekdays Array, use first three letters if not provided
    weekdaysMin: 'Pn_Wt_Śr_Cz_Pt_So_Ni'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
    months: 'Styczeń_Luty_Marzec_Kwiecień_Maj_Czerwiec_Lipiec_Sierpień_Wrzesień_Październik_Listopad_Grudzień'.split('_'), // months Array
    monthsShort: 'Sty_Lut_Mar_Kwi_Maj_Cze_Lip_Sie_Wrz_Paź_Lis_Gru'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
    ordinal: n => `${n}`, // ordinal Function (number) => return number + output
    relativeTime: { // relative time format strings, keep %s %d as the same
      future: 'za %s', // e.g. in 2 hours, %s been replaced with 2hours
      past: '%s temu',
      s: 'kilka sekund',
      m: 'minutę',
      mm: '%d minut',
      h: 'godzinę',
      hh: '%d godzin', // e.g. 2 hours, %d been replaced with 2
      d: 'dzień',
      dd: '%d dni',
      M: 'miesiąc',
      MM: '%d miesięcy',
      y: 'rok',
      yy: '%d lat'
    }
  }
};

export default {
  components: {
    GanttDialog,
    BreadcrumbsComponent,
    GanttElastic,
    GanttElasticHeader2
  },
  computed: {
    ...mapGetters(['getProjects','getStatusesById', 'getActivitiesById']),
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: 'Gantt',
          disabled: true,
        },
      ],

      lastId: 3,
      dynamicStyle: {},
      tasks,
      options,
      loadedGantt: false,
      dialog: false,
      currentTask: null,
    }
  },
  async mounted() {
    await this.readProjects()
    this.loadedGantt = true;
  },
  methods: {

    flatTree(nodes, arr, parentId = null) {
      if (!nodes) {
        return [];
      }
      if (!arr) {
        arr = [];
      }
      for (var i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        node.parentId = parentId; // Dodanie właściwości parentId

        arr.push(node);
        if (node !== null) {
          this.flatTree(node.children, arr, node.id); // Przekazanie node.id jako parentId
        }
      }
      return arr;
    },

    async readProjects() {
      this.tasks = [];
      this.getProjects.filter((project) => {

        const oneDay = 24 * 60 * 60 * 1000;
        let object = {
          id: project.id,
          label: project.title,
          user: project.customer,
          start: getDate(0),
          duration: 15 * oneDay,
          percent: 85,
          type: "project",
          //collapsed: true,
        };
        this.tasks.push(object);
        let time = 0;

        if(project.tree !==  undefined){
          let items = this.flatTree([JSON.parse(project.tree)], []);
          console.log(items);
          if (items !== null) {
            items.filter((value, index) => {
              console.log(value, value.statuses ? value.statuses.length : 1);
              let time2 = value.statuses ? value.statuses.length : 1;
              if (index !== 0) {
                let object = {
                  id: project.id + '_' + value.id,
                  label: value.label,
                  branch: value.element,
                  start: getDate(time),
                  duration: oneDay * time2,
                  percent: 85,
                  statuses: value.statuses,
                  type: "task",
                  //collapsed: true,
                };
                object.parentId = project.id;
                console.log(object);
                this.tasks.push(object);
                time = time + 24;
                let oldIndex = null;
                if(value.statuses){
                  value.statuses.filter((bool, index) => {
                    if(bool === true){
                      const currentStatus = this.getStatusesById(index);
                      console.log(index,bool, currentStatus);
                      let object = {
                        id: project.id + '_' + value.id + '_' + index,
                        label: currentStatus.name,
                        user: '',
                        branch: project.branch,
                        start: getDate(time),
                        duration: oneDay,
                        percent: 85,
                        type: "task",
                        parentId: project.id + '_' + value.id,
                        //collapsed: true,
                        style: {
                          base: {
                            fill: currentStatus.color,
                            stroke: currentStatus.color,
                          }
                        },
                        dependentOn: [project.id + '_' + value.id + '_' + oldIndex]
                      };
                      oldIndex = index;
                      this.tasks.push(object);
                      time = time + 24;
                    }
                  })
                }

                time = 0;

                if(value.activities){
                  value.activities.filter((bool, index) => {

                    console.log(bool, index);

                    if(bool === true){
                      const currentActivities = this.getActivitiesById(index);
                      console.log(index,bool, currentActivities);
                      let object = {
                        id: project.id + '_' + value.id + '__' + index,
                        label: currentActivities.name,
                        user: '',
                        branch: project.element,
                        start: getDate(time),
                        duration: oneDay,
                        percent: 85,
                        type: "task",
                        parentId: project.id + '_' + value.id,
                      //collapsed: true,
                      /*style: {
                      base: {
                        fill: currentActivities.color,
                            stroke: currentActivities.color,
                      }
                    },*/
                      dependentOn: [project.id + '_' + value.id + '__' + oldIndex]
                    };
                    oldIndex = index;
                    this.tasks.push(object);
                    time = time + 24;
                  }
                })
              }

            }

          });

        }

        console.log(this.tasks);
        }


      });



    },
    handleTaskClick(task) {
      // Obsługa kliknięcia na zadanie
      console.log('Kliknięto zadanie:', task);
      this.currentTask = task.data;
      this.dialog = true;
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    getDate(hours) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      const timeStamp = new Date(`${currentYear}-${currentMonth}-${currentDay} 00:00:00`).getTime();
      return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
    },
    addTask() {
      this.tasks.push({
        id: this.lastId++,
        label: 'Make some noise',
        user: 'Adam Klimas',
        start: this.getDate(-24 * 5),
        duration: 5 * 24 * 60 * 60 * 1000,
        progress: 85,
        type: 'project',//'task', //'project'
        collapsed: false,
        html: "fsdfdsfsd"
      });
    },
    tasksUpdate(tasks) {
      this.tasks = tasks;
    },
    optionsUpdate(options) {
      this.options = options;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    }
  }
}

</script>
