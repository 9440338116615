<template>
  <v-btn color="grey"
         prepend-icon="mdi-close"
         variant="elevated" size="small" @click="$emit('action')">{{ $props.label ?? 'Zamknij' }}
  </v-btn>
</template>
<script>
export default {
  name: "AnnulledButton",
  props: ['label'],
}
</script>
