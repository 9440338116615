import { createRouter, createWebHashHistory } from 'vue-router'

import UnitsView from "@/views/dictionary/units/UnitsView.vue";

// ######################

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import UsersView from "@/views/users/UsersView.vue";
import UserProfileView from "@/views/users/UserProfileView.vue";

import ProductsView from "@/views/products/ProductsView.vue";
import TicketsSingleView from "@/views/tickets/TicketsSingleView.vue";
import TicketsView from "@/views/tickets/TicketsView.vue";
import ProductsTreeView2 from "@/views/tree/ProductsTreeView2.vue";
import ProjectsView from "@/views/projects/ProjectsView.vue";
import LibraryView from "@/views/library/LibraryView.vue";
import ProjectsElementsView from "@/views/projects/ProjectsElementsView.vue";

import TicketsSingle2View from "@/views/tickets/TicketsSingle2View.vue";

import GanttView from "@/views/gantt/GanttView.vue";
import RegisterView from "@/views/RegisterView.vue";
import EmployeesView from "@/views/emplyees/EmployeesView.vue";
import LayoutView from "@/views/warehouses/LayoutView.vue";
import DispositionView from "@/views/warehouses/DispositionView.vue";
import ReceiveTheGoodsView from "@/views/warehouses/ReceiveTheGoodsView.vue";
import AutomationView from "@/views/settings/AutomationView.vue";

import EmployeeProfileView from "@/views/emplyees/EmployeeProfileView.vue";
import OrdersView from "@/orders/OrdersView.vue";
import NewPasswordView from "@/views/NewPasswordView.vue";
import CustomersView from "@/views/customers/CustomersView.vue";

import CategoriesView from "@/views/dictionary/categories/CategoriesView.vue";
import StatusView from "@/views/dictionary/status/StatusView.vue";
import ProducersView from "@/views/dictionary/producers/ProducersView.vue";
import FeaturesView from "@/views/dictionary/features/FeaturesView.vue";
import IndustriesTableView from "@/views/dictionary/industries/IndustriesTableView.vue";
import CurrenciesTableView from "@/views/dictionary/currencies/CurrenciesTableView.vue";
import ActivitiesTableView from "@/views/dictionary/activities/ActivitiesTableView.vue";

import WarehousesListView from "@/views/warehouses/WarehousesListView.vue";
import WarehousesPositionsView from "@/views/warehouses/WarehousesPositionsView.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/haslo',
    name: 'haslo',
    component: NewPasswordView
  },
  {
    path: '/uzytkownicy',
    name: 'users',
    component: UsersView
  },
  {
    path: '/pracownicy',
    name: 'pracownicy',
    component: EmployeesView
  },
  {
    path: '/biblioteka',
    name: 'biblioteka',
    component: LibraryView
  },

  {
    path: '/rejestracja',
    name: 'rejestracja',
    component: RegisterView
  },

  {
    path: '/user/profile/:id*',
    name: 'userProfile',
    component: UserProfileView
  },
  {
    path: '/pracownik/:id*',
    name: 'employeeProfile',
    component: EmployeeProfileView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  /* */

  {
    path: '/produkty',
    name: 'products',
    component: ProductsView
  },

  {
    path: '/projekt/:id*',
    name: 'productsTree2',
    component: ProductsTreeView2
  },
  {
    path: '/projekty',
    name: 'projects',
    component: ProjectsView
  },

  {
    path: '/galezie',
    name: 'elements',
    component: ProjectsElementsView
  },

  {
    path: '/ticket/:id*',
    name: 'ticket',
    component: TicketsSingleView
  },
  {
    path: '/ticket2/:id*',
    name: 'ticket2',
    component: TicketsSingle2View
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TicketsView
  },
  {
    path: '/kategorie',
    name: 'categories',
    component: CategoriesView
  },
  {
    path: '/zapotrzebowanie',
    name: 'zapotrzebowanie',
    component: OrdersView
  },
  {
    path: '/zamowienia',
    name: 'zamowienia',
    component: OrdersView
  },
  {
    path: '/cechy-produktow',
    name: 'features',
    component: FeaturesView
  },
  {
    path: '/jednostki',
    name: 'units',
    component: UnitsView
  },
  {
    path: '/statusy',
    name: 'statuses',
    component: StatusView
  },
  {
    path: '/gantt',
    name: 'gantt',
    component: GanttView
  },
    //Magazyn
  {
    path: '/magazyny/:id*',
    name: 'magazyny',
    component: LayoutView
  },
  {
    path: '/rozporzadzanie',
    name: 'rozporzadzanie',
    component: DispositionView
  },
  {
    path: '/przyjmij-na-magazyn',
    name: 'przyjmij-na-magazyn',
    component: ReceiveTheGoodsView
  },
  {
    path: '/lista-magazynow',
    name: 'lista-magazynow',
    component: WarehousesListView
  },

  {
    path: '/pozycje-magazynowe',
    name: 'pozycje-magazynowe',
    component: WarehousesPositionsView
  },

    //Ustawienia
  {
    path: '/producenci',
    name: 'producenci',
    component: ProducersView
  },
  {
    path: '/automatyzacja',
    name: 'automatyzacja',
    component: AutomationView
  },
  {
    path: '/klienci',
    name: 'klienci',
    component: CustomersView
  },
  {
    path: '/branze',
    name: 'branze',
    component: IndustriesTableView
  },
  {
    path: '/waluty',
    name: 'waluty',
    component: CurrenciesTableView
  },
  {
    path: '/czynnosci',
    name: 'czynnosci',
    component: ActivitiesTableView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
