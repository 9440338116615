import axios from "axios";
export const projectsElements = {
    state: {
        projectsElements: []
    },
    getters: {
        getProjectsElements: state => {
            return state.projectsElements;
        },
        getProjectElementsById: (state) => (id) => {
            return state.projectsElements.find(item => item.id === Number(id));
        }
    },
    mutations: {
        setProjectsElements(state, items) {
            state.projectsElements = items;
        },
        removeProjectElements(state, itemId) {
            state.projectsElements = state.projectsElements.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadProjectsElements({commit, rootState }) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/projects/elements',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setProjectsElements', items);
            } catch (error) {
                console.error('Error loading items:', error);
            }
        },
        async removeProjectElements({commit, rootState }, itemId) {
            try {
                // Perform the asynchronous task to remove an item
                await axios.delete(`https://api.labmetry.com/api/projects/${itemId}`,
                    {headers: {Authorization: `Bearer ${rootState .token}`}});

                // Commit the mutation to update the state
                commit('removeProjectElements', itemId);
            } catch (error) {
                console.error('Error removing item:', error);
            }
        },
        async addProjectElement({commit, rootState }, data) {

            try {
                await axios.post('https://api.labmetry.com/api/projects',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addProjectElement', items);
                        commit('setResponseMessage', 'Element został dodany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateProjectElement({commit, rootState }, data) {
            try {
                await axios.put('https://api.labmetry.com/api/projects/' + data.idProject,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('updateProjectElement', data);
                        commit('setResponseMessage', 'Element został zaktualizowany');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        console.log(error);
                        commit('setResponseMessage', '0:' + error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', '1:' + error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
