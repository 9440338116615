import axios from "axios";
export const features = {
    state: {
        features: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getFeatures: state => {
            return state.features;
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setFeatures(state, items) {
            state.features = items;
        },
        addFeature(state, items) {
           //console.log('addFeature',items);
            state.features.push(items);
        },
        updateFeature(state, data) {
           //console.log('updateFeature',data, state.features);
            const index = state.features.findIndex(item => item.id === data.id);
            state.features.splice(index, 1, {
                id: data.id ,
                title: data.title,
                units: data.units
            });
        },
        removeFeature(state, itemId) {
            state.features = state.features.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadFeatures({commit, rootState }) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/features',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setFeatures', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addFeature({commit, rootState }, data) {

            console.log('addFeature', data, data.units)
            try {
                await axios.post('https://api.labmetry.com/api/features',
                    {
                        title: data.title,
                        units: data.units
                    },
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addFeature', items);
                        commit('setResponseMessage', 'Cecha została dodana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateFeature({commit, rootState }, data) {
           console.log('updateFeature', data, data.item.units)
            try {

                await axios.put('https://api.labmetry.com/api/features/' + data.idFeature,
                    {
                        title: data.item.title,
                        units: data.item.units
                    },
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {

                        console.log(response);

                        const items = response.data;
                        commit('updateFeature', items);
                        commit('setResponseMessage', 'Cecha została zaktualizowana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeFeature({commit, rootState }, itemId) {
            try {
                await axios.delete('https://api.labmetry.com/api/features/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeFeature', itemId);
                        commit('setResponseMessage', 'Cecha została usunięta');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
