import axios from "axios";

export const histories = {
    state: {
        histories: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getHistories: state => {
            return state.histories;
        },
        getHistoryByIdBranch: (state) => (id) => {

            if (Array.isArray(state.histories)) {
                return state.histories.filter(item => item.idBranch === Number(id));
            }

        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        }
        ,
        setResponseStatus(state, status) {
            state.responseStatus = status;
        }
        ,
        setHistories(state, items) {
            state.histories = items;
            console.log(state.histories);

        }
        ,
        addHistory(state, items) {
            state.histories.push(items);
        }
        ,

    },
    actions: {

        async getHistory({commit, rootState}, data) {

            console.log('getHistory', data);

            try {
                await axios.post('https://api.labmetry.com/api/gethistories/' + data.idBranch,
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('setHistories', items);
                    })
                    .catch(error => {

                        console.log(error);

                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
        ,

    }
}
