<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_WAREHOUSE'])">
  <div>
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_WAREHOUSE_SAVE'])"
                v-model="dialog"
                width="1000"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="green"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="small"
                    prepend-icon="mdi-plus"
                    variant="elevated"
                    @click="addItem()"
                >
                  Dodaj
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Nazwa magazynu"
                          :rules="[rules.required, rules.counter]"
                          v-model="name"
                          hide-details="auto"
                      ></v-text-field>

                      <v-select
                          label="Magazyn nadrzędny"
                          v-model="parentWarehouse"
                          density="compact"
                          :items="warehouses.warehouses"
                          item-value="id"
                          item-title="name"
                          class="mt-2"
                          placeholder="Magazyn nadrzędny"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                  <v-btn
                      color="gray"
                      size="small"
                      variant="tonal"
                      @click="dialog = false"
                  >
                    Anuluj
                  </v-btn>
                  <v-btn
                      color="green"
                      size="small"
                      variant="tonal"
                      prepend-icon="mdi-content-save"
                      @click="fetchAddWarehouse(itemId)"
                      :loading="loadingSaveItem"
                  >
                    Zapisz
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="warehouses.warehouses"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td v-if="item.parent">{{ item.parent.name }}</td>
                <td v-else>Brak</td>

                <td align="end">
                  <v-btn
                      v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_WAREHOUSE_SAVE'])"
                      class="mx-2" append-icon="mdi-pencil-box" size="small" fab dark color="blue"
                      @click="editItem(item)">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteCategory(item.id)"
                                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_WAREHOUSE_REMOVE'])"
                  >
                  </RemoveButton>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>

  </div>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import {mapGetters, mapState} from "vuex";
import RemoveButton from "@/components/navigation/element/RemoveButton";

export default {
  name: "WarehousesList",
  components: {RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
      },
      name: '',
      breadcrumbs: [
        {
          title: 'Lista magazynów',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Rodzic', key: 'parent', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loadingSaveItem: false,
      loading: false,
      totalItems: 10,
      itemId: null,
      parentWarehouse: null,
    }

  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["warehouses"])
  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    async fetchAddWarehouse(idWarehouse) {
      this.loadingSaveItem = true;
      if (idWarehouse) {
        if (idWarehouse == this.parentWarehouse) {
          this.$notify({
            type: this.$store.state.users.responseStatus,
            title: "Magazyn nie może być sam dla siebie rodzicem"
          });
          this.loadingSaveItem = false;
          return false;
        } else {
         await this.$store.dispatch('updateWarehouse', {
            item: {
              name: this.name,
              parent: this.parentWarehouse,
            }, id: idWarehouse
          });
        }
      } else {
        await this.$store.dispatch('addWarehouse', {
          name: this.name,
          parentWarehouse: this.parentWarehouse,
        });
      }
      this.parentWarehouse = null;
      this.name = null;

      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});

      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
      this.loadingSaveItem = false;
    },
    async fetchDeleteCategory(idWarehouse) {
      await this.$store.dispatch('removeWarehouse', idWarehouse);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(warehouse) {
      this.dialog = true;
      this.name = warehouse.name;
      this.itemId = warehouse.id;
      if(warehouse.parent) {
        this.parentWarehouse = warehouse.parent.id;
      }else{
        this.parentWarehouse = null;
      }

    },
    addItem() {
      this.name = null;
      this.itemId = null;
      this.parentWarehouse = null;
    }
  }
}
</script>

<style scoped>

</style>
