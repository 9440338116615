<template>
  <v-card v-if="loading === false" height="100%">
    <v-card-item>
      <v-form ref="form">
        <v-text-field
            name="title"
            label="Nazwa produktu"
            density="compact"
            type="text"
            v-model="title"
        ></v-text-field>
        <v-row>
          <v-col>
            <v-textarea
                name="description"
                label="Opis produktu"
                density="compact"
                v-model="description"
            ></v-textarea>
          </v-col>
          <v-col>
            <v-text-field
                name="tags"
                label="Tagi"
                density="compact"
                type="text"
                v-model="tags"
            ></v-text-field>
            <v-text-field
                name="ean"
                label="EAN"
                density="compact"
                type="text"
                v-model="ean"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
                label="Waluta"
                v-model="currency"
                density="compact"
                :items="currencies.currencies"
                item-value="id"
                item-title="name"
            >
            </v-select>
<!--            <v-text-field
                name="currency"
                label="Waluta"
                density="compact"
                type="text"
                v-model="currency"
            ></v-text-field>-->
          </v-col>
          <v-col>
            <v-text-field
                name="price"
                label="Cena"
                density="compact"
                type="text"
                v-model="price"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
                label="Jednostka"
                v-model="unit"
                density="compact"
                :items="units.units"
                item-value="id"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
                label="Producent"
                v-model="producer"
                density="compact"
                :items="producers.producers"
                item-value="id"
                item-title="name"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
                label="Kategoria"
                v-model="category"
                density="compact"
                :items="categories.categories"
                item-value="id"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-item>
    <v-card-actions>
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <v-btn color="grey-darken-1"
                 variant="flat"
                 size="small"
                 prepend-icon="mdi-close"
                 @click="$emit('closeDialog')"
          >Zamknij
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="green"
                 variant="flat"
                 size="small"
                 prepend-icon="mdi-content-save"
                 @click="save"
                 :loading="saveAndClose"
          >
            Zapisz i zamknij
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-skeleton-loader v-else-if="loading === true"></v-skeleton-loader>
</template>
<script>
import {mapGetters, mapState,} from "vuex";

export default {
  name: "CreateProductView",
  props: ['productId'],
  data() {
    return {
      loading: false,

      // product
      title: "",
      description: "",
      tags: "",
      ean: "",
      currency: null,
      price: "",
      unit: null,
      category: null,
      producer: null,

      //loaders
      saveAndClose: false,
    }
  },
  computed: {
    ...mapState(['categories', 'units', 'producers', 'currencies']),
    ...mapGetters(['getToken', 'getRoles', 'getReadyProductById']),
  },
  created() {
    if (this.$props.productId !== null) {
      let product = this.getReadyProductById(this.$props.productId);

      this.title = product.title;
      this.description = product.description;
      this.tags = product.tags;
      this.ean = product.ean;
      this.currency = product.currency ? product.currency.id : null;

      this.price = product.price;
      this.unit = product.unit ? product.unit.id : null;
      this.category = product.category ? product.category.id : null;
      this.producer = product.producer ? product.producer.id : null;
    }
  },
  methods: {
    async save(event) {
      this.saveAndClose = true;
      event.preventDefault();
      const data = {
        title: this.title,
        description: this.description,
        tags: this.tags,
        ean: this.ean,
        currency: this.currency,
        price: this.price ? parseFloat(this.price.toString().replace(',', '.')) : 0,
        unit: this.unit,
        category: this.category,
        producer: this.producer,
      }

      if (this.$props.productId === null) {
        await this.$store.dispatch('addProduct', data);
      } else {
        await this.$store.dispatch('updateProduct', [data, this.$props.productId]);
      }

      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      this.$emit('closeDialog');
      this.saveAndClose = false;
    }
  }

}
</script>

<style>
</style>
