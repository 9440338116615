<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap" rel="stylesheet">


  <notifications position="bottom left"/>
  <LoginView v-if="$route.name !== 'rejestracja' && $route.name !== 'haslo' && !isLoggedIn && countData === 0 "/>
  <NewPasswordView v-if="$route.name === 'haslo' && !isLoggedIn && countData === 0 "/>
  <RegisterView v-if="$route.name === 'rejestracja' && !isLoggedIn && countData === 0"></RegisterView>


  <v-container text-center v-if="isLoggedIn && countData !== 100">
    <v-row justify="center" align="center" class="h-100">
      <v-col cols="4" sm="12" lg="4" align-self="center">
        <v-card variant="flat">
          <v-card-title class="text-center">
            <v-img
                aspect-ratio="16/9"
                class="mt-3 mx-auto"
                cover
                src="logo.svg"
            ></v-img>
          </v-card-title>
          <v-progress-linear
              class="my-5 "
              v-model="countData"
              height="50"
              bg-color="pprogress-linear"
              color="progress-linear"
          >
            <strong>{{ Math.ceil(countData) }}%</strong>
          </v-progress-linear>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-app id="inspire" v-if="isLoggedIn && countData === 100">
    <v-app-bar class="elevation-0">
      <v-img
          height="30"
          class="me-auto logo-poziom ms-3"
          src="logo-small.svg"
      ></v-img>
      <v-text-field
          density="compact"
          variant="outlined"
          :label="$t('search')"
          append-inner-icon="mdi-magnify"
          single-line
          hide-details
      ></v-text-field>
      <LocaleSwitcher />
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ props: menu }">
          <v-tooltip location="top">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn icon="mdi-account" v-bind="mergeProps(menu, tooltip)"></v-btn>
            </template>
            <span>{{ $t('myAccount') }}</span>
          </v-tooltip>
        </template>
        <v-list class="elevation-6">
          <v-list-item>
            {{ getUser }}
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @lick="logout" variant="plain">
            {{ $t('logout') }}
          </v-list-item>
          <!--          <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>-->
        </v-list>
      </v-menu>
      <v-btn icon="mdi-power" @click="logout"></v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" class="border-0">
      <v-list density="compact" nav v-model:opened="open">
        <v-list-item prepend-icon="mdi-home" :title="$t('home')" value="home" to="/"></v-list-item>
        <v-divider class="mb-1"></v-divider>
        <v-list-item prepend-icon="mdi-briefcase-variant" :title="$t('projects')" value="projects" to="/projekty"
                     v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_PROJECTS'])"></v-list-item>
        <v-list-item prepend-icon="mdi-chart-gantt" :title="$t('gantt')" value="gantt" to="/gantt"
                     v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_GANTT'])"
        ></v-list-item>
        <v-list-item prepend-icon="mdi-alpha-p-box" :title="$t('products')" value="products" to="/produkty"
                     v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_PRODUCTS',])"></v-list-item>
        <v-list-item prepend-icon="mdi-order-alphabetical-ascending" title="Zapotrzebowanie" value="zapotrzebowanie"
                     to="/zapotrzebowanie"
                     v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_DEMAND'])"></v-list-item>
        <v-list-item prepend-icon="mdi-account-check" :title="$t('clients')" value="klienci"
                     to="/klienci"
                     v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_CUSTOMER'])"></v-list-item>

        <v-list-item prepend-icon="mdi-order-alphabetical-ascending" :title="$t('orders')" value="zamowienia"
                     to="/zamowienia"
                     v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_ORDER'])"></v-list-item>


        <v-list-group value="Magazyn"

        >
          <template v-slot:activator="{ props }">
            <v-list-item
                prepend-icon="mdi-warehouse"
                v-bind="props"
                :title="$t('warehouses')" value="magazyn" to="/magazyny"
                v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_WAREHOUSE'])">
            </v-list-item>
          </template>
          <v-list-item :title="$t('warehousesList')" value="lista-magazynow" to="/lista-magazynow"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_WAREHOUSE_ACCEPT'])"></v-list-item>
          <v-list-item :title="$t('warehouseAccept')" value="przyjmij-na-magazyn" to="/przyjmij-na-magazyn"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_WAREHOUSE_ACCEPT'])"></v-list-item>
          <v-list-item :title="$t('warehouseDisposition')" value="rozporzadzanie" to="/rozporzadzanie"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_WAREHOUSE_DISPOSITION'])"></v-list-item>


        </v-list-group>


        <v-list-item prepend-icon="mdi-file-check-outline" :title="$t('filesLibrary')" value="biblioteka" to="/biblioteka"
                     v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_LIBRARY'])"></v-list-item>
        <v-list-group value="Ustawienia"
                      v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING'])">
          <template v-slot:activator="{ props }">
            <v-list-item
                v-bind="props"
                prepend-icon="mdi-cog"
                :title="$t('settings')"
            ></v-list-item>
          </template>
          <v-list-item :title="$t('automation')" value="automatyzacja" to="/automatyzacja"
                       v-if="checkRoles(['ROLE_OWNER','ROLE_ADMIN','ROLE_SETTING','ROLE_AUTOMATION'])"></v-list-item>
          <v-list-item :title="$t('producers')" value="producers" to="/producenci"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_PRODUCERS'])"></v-list-item>
          <v-list-item :title="$t('statuses')" value="statuses" to="/statusy"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_STATUSES'])"></v-list-item>
          <v-list-item :title="$t('categories')" value="categories" to="/kategorie"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_CATEGORIES'])"></v-list-item>
          <v-list-item :title="$t('features')" value="features" to="/cechy-produktow"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_FEATURES'])"></v-list-item>
          <v-list-item :title="$t('units')" value="units" to="/jednostki"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_UNITS'])"></v-list-item>
          <v-list-item :title="$t('warehousePositions')" value="pozycje-magazynowe" to="/pozycje-magazynowe"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_WAREHOUSE_DICTIONARY'])"></v-list-item>
          <v-list-item :title="$t('users')" value="users" to="/uzytkownicy"
                       v-if="checkRoles(['ROLE_ADMIN'])"></v-list-item>
          <v-list-item :title="$t('employees')" value="customers" to="/pracownicy"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_EMPLOYEES'])"></v-list-item>
          <v-list-item :title="$t('industries')" value="branze" to="/branze"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_INDUSTRIES'])"></v-list-item>
          <v-list-item :title="$t('currencies')" value="waluty" to="/waluty"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_CURRENCIES'])"></v-list-item>
          <v-list-item :title="$t('activities')" value="czynnosci" to="/czynnosci"
                       v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_ACTIVITIES'])"></v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>

      <v-btn v-if="drawer === true" @click="drawer = !drawer" variant="flat" prepend-icon="mdi-arrow-collapse-left"
             class="mx-auto">{{$t('collaspeMenu')}}
      </v-btn>

    </v-navigation-drawer>
    <div v-if="drawer === false"
         @click="drawer = !drawer" id="drawer" class="drawer rounded-e-xl pa-5 elevation-4"
         style="z-index:9999;">
      <v-icon>mdi mdi-arrow-collapse-right</v-icon>
    </div>
    <v-main fluid>
      <v-container fluid fill-height class="page-wrapper">
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {useTheme} from 'vuetify'
import {mapActions, mapGetters, mapMutations} from "vuex";

import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";


import {mergeProps} from 'vue'
import {useStorage} from "vue3-storage";
import NewPasswordView from "@/views/NewPasswordView.vue";

import { useLocale } from 'vuetify'
import LocaleSwitcher from "@/LocaleSwitcher.vue";


export default {
  name: "App",
  components: {LocaleSwitcher, NewPasswordView, RegisterView, LoginView},
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getUser", "getRoles"])
  },
  mounted() {
    if (this.isLoggedIn && this.countData !== 100) {
      this.loadData()
    }


    const { t } = useLocale();
    this.text = t('$vuetify.my-component.text')

  },
  watch: {
    isLoggedIn(newValue, oldValue) {
      console.log(`isLogin changed from ${oldValue} to ${newValue}`);
      if (newValue === true) {
        this.loadingData === true;
        this.loadData();
        this.loadingData === false;
      }
      if (oldValue === true && newValue === false) {
        this.countData = 0;
      }
    },
  },

  data: () => ({
    loadingData: false,
    drawer: null,
    open: ['Users'],

    text: '',

    admins: [
      ['Management', 'mdi-account-multiple-outline'],
      ['Settings', 'mdi-cog-outline'],
    ],
    cruds: [
      ['Create', 'mdi-plus-outline'],
      ['Read', 'mdi-file-outline'],
      ['Update', 'mdi-update'],
      ['Delete', 'mdi-delete'],
    ],
    items: [
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me 2'},
    ],
    countData: 0
  }),
  setup() {
    const theme = useTheme()
    return {
      theme,
      toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }
  },

  methods: {

    mergeProps,
    ...mapMutations(['logout']),
    ...mapActions([
      'loadReadyProducts',
      'loadCategories',
      'loadProjects',
      'loadProjectsElements',
      'loadLibrary',
      'loadStatuses',
      'loadBranchStatuses',
      'loadUsers',
      'loadUnits',
      'loadOrders',
      'loadEmployees',
      'loadFeatures',
      'loadCustomers',
      'loadProducers',
      'loadWarehouses',
      'loadLocationItems',
      'loadIndustries',
      'loadCurrencies',
      'loadActivities',
      'loadItemsInWarehouses',
    ]),
    async loadData() {
      if (this.isLoggedIn) {
        await this.loadStatuses();
        await this.loadActivities();
        await this.loadProjects();
        await this.loadWarehouses();
        this.countData = 5;

        this.countData = 25;
        await this.loadProjectsElements();
        this.countData = 50;
        await this.loadBranchStatuses();
        await this.loadItemsInWarehouses();
        this.countData = 100;


        await this.loadUsers();
        await this.loadEmployees();
        await this.loadUnits();
        await this.loadLibrary();
        await this.loadFeatures();
        await this.loadOrders();
        await this.loadProducers();
        await this.loadCustomers();
        await this.loadReadyProducts();
        await this.loadCategories();
        await this.loadWarehouses();
        await this.loadLocationItems();
        await this.loadIndustries();
        await this.loadCurrencies();
        console.log('Koniec ładowania');
      }
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    changeLocale (locale) {
      this.$vuetify.locale.current = locale
    },

  }
}
</script>
<style lang="scss">

#drawer {
  position: absolute;
  left: 0px;
  top: 50%;
  background: #fff;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
}

$bgColor: rgba(238, 242, 246);
$bgColor2: rgb(225, 226, 227);
.page-wrapper2 {
  height: calc(100vh - 100px);
  padding: 15px;
  border-radius: 12px;
}

.page-wrapper {
  background: $bgColor;
  height: calc(100vh - 64px);
}


.page-head {
  background: $bgColor2;
}


.default-color {
  color: #034c5d !important;
}

.v-list-item:hover > .v-list-item__overlay {
  opacity: calc(var(--v-hover-opacity) * var(--v-theme-overlay-multiplier));
}

.logo-poziom img {
  width: auto !important;
}

.vue-notification {
  font-size: 15px !important;
  border-radius: 30px;
}

.primary {
  color: rgba(235, 0, 140, 1);
}

.primary-gradient {
  background: -webkit-linear-gradient(0deg, rgba(235, 0, 140, 1) 0%, rgba(9, 9, 121, 1) 84%, rgba(43, 82, 149, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-progress-linear {
  background: rgb(235, 0, 140);
  background: -moz-linear-gradient(90deg, rgba(235, 0, 140, 1) 0%, rgba(9, 9, 121, 1) 84%, rgba(43, 82, 149, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(235, 0, 140, 1) 0%, rgba(9, 9, 121, 1) 84%, rgba(43, 82, 149, 1) 100%);
  background: linear-gradient(90deg, rgba(235, 0, 140, 1) 0%, rgba(9, 9, 121, 1) 84%, rgba(43, 82, 149, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eb008c", endColorstr="#2b5295", GradientType=1);
}

.v-list-item--active .v-list-item__content {
  color: rgba(235, 0, 140, 1);
}

.v-list-item--active .v-list-item__prepend .v-icon {
  background: rgb(235, 0, 140);
  background: -webkit-linear-gradient(0deg, rgba(235, 0, 140, 1) 0%, rgba(9, 9, 121, 1) 84%, rgba(43, 82, 149, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.round.v-input .v-input__slot {
  border-radius: 100px !important;
}

.v-field__outline {
  border-radius: 8px !important;
  color: #605d5d;
}

.v-field--variant-outlined .v-field__outline__start.v-locale--is-ltr, .v-locale--is-ltr .v-field--variant-outlined .v-field__outline__start {
  border-radius: 8px 0 0 8px;
}

.v-field--variant-outlined .v-field__outline__end.v-locale--is-ltr, .v-locale--is-ltr .v-field--variant-outlined .v-field__outline__end {
  border-radius: 0 8px 8px 0;
}

</style>
