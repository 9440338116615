<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_CUSTOMERS'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <ItemCustomer :check-roles="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CUSTOMERS_SAVE'])"
                           :item-id="null"
            />
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="customers.customers"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.lastName }}</td>
                <td align="end">
                  <ItemCustomer :dialog="dialog[item.id]"
                                :check-roles="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CUSTOMERS_SAVE'])"
                                :item-id="item.id"
                                @update:dialog="dialog[item.id] = false"
                                :name="'Edytuj'"
                  />

                  <RemoveButton @confirm="fetchDelete(item.id)"
                                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_CUSTOMERS_REMOVE'])"
                  >
                  </RemoveButton>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import ItemCustomer from "@/views/customers/ItemCustomer.vue";

export default {
  name: 'CustomersView',
  components: {ItemCustomer, RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Klienci',
          disabled: true,
        },
      ],
      dialog: [],
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Imię', key: 'name', align: 'start'},
        {title: 'Nazwisko', key: 'lastName', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      //loaders
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
      selectedFeatures: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["customers"])
  },
  methods: {
    async fetchDelete(id) {
      await this.$store.dispatch('removeCustomer', id);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(item) {
      this.itemId = item
      this.dialog = true
    },
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>
