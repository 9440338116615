<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_TICKETS_READ'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-if="projectsElements"
              density="compact"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="projectsElements.projectsElements"
              :search="search"
              class="elevation-0"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td align="end">
                  <v-btn class="mx-2" append-icon="mdi-pencil-box" size="x-small" fab dark color="blue"
                         :to="'/projekt/' +  item.id ">
                    Drzewo
                  </v-btn>
                  <RemoveButton @confirm="removeItem(item.id)"
                                v-if="checkRoles(['ROLE_SUPER_ADMIN','ROLE_ADMIN'])">
                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>


  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: 'ProjectsView',
  components: {RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Elementy drzewa',
          disabled: true,
        },
      ],
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      totalItems: 10,
      itemId: null
    };
  },
  computed: {
    ...mapState(["projectsElements"]),
    ...mapGetters(["isLoggedIn", 'getToken', 'getRoles()', "getRoles"])
  },
  methods: {
    ...mapActions(['removeProjectElements','checkRoles']),
    async removeItem(itemId) {
      await this.removeProjectElements(itemId);
    },
  },
}
</script>
