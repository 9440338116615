<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_TICKETS_SAVE'])">
  <v-row>
    <v-col>
      <BreadcrumbsComponent :items="breadcrumbs"/>
    </v-col>
    <v-col>
      <v-btn
          color="green"
          style="margin-top:6px; "
          class="float-start mx-1"
          size="x-small"
          prepend-icon="mdi-content-save"
      >
        Zapisz

        <v-dialog
            v-model="dialog"
            activator="parent"
            width="auto"
        >
          <v-card>
            <v-card-text>
              <v-text-field style="width: 550px;" label="Nazwa" variant="outlined"
                            v-model="title"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="fetchAddTicket(itemId)">Zapisz</v-btn>
              <v-btn color="primary" @click="dialog = false">Zamknij</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>
    </v-col>
  </v-row>

  <v-card>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Podsumowanie</v-card-title>
          <v-btn @click="getCalculations">Przelicz</v-btn>
          <v-card-subtitle>Projekt</v-card-subtitle>
          <v-card-item>{{ selectedProject }}</v-card-item>

          <v-card-subtitle>Wybory</v-card-subtitle>
          <v-card-item v-for="m in selectedProducts" :key="m">{{ m }}</v-card-item>

          <v-card-subtitle>Wartości</v-card-subtitle>
          <v-card-item v-for="m in math" :key="m">{{ m }}</v-card-item>

          <template v-if="itemsWithIsScreenTrue">
            <v-card :title="screen.label" variant="outlined" v-for="screen in itemsWithIsScreenTrue" :key="screen.id">
              <v-table density="compact">
                <thead>
                <tr>
                  <th class="text-left" v-for="item in tableHeaders"
                      :key="item.name">
                    {{ item.title }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in getItemFromScreen(screen.id)"
                    :key="item.idTree"
                >
                  <td>{{ item.idTree }} {{ item.idFeature ?? '' }}</td>
                  <td>{{ item.parent }} | {{ item.name }}</td>
                  <td>{{ item.value }}</td>
                  <td>{{ item.math }}</td>
                </tr>

                <template v-if="screen.children">
                  <template v-for="screenCh in screen.children" :key="screenCh">
                    <tr
                        v-for="item in getItemFromScreen(screenCh.id)"
                        :key="item.idTree"
                    >
                      <td>{{ item.idTree }}</td>
                      <td>{{ item.parent }} | {{ item.name }}</td>
                      <td>{{ item.value }}</td>
                      <td>{{ item.math }}</td>
                    </tr>
                  </template>

                </template>


                </tbody>
              </v-table>
            </v-card>
          </template>


        </v-card>
      </v-col>
      <v-col>


        <v-tabs
            v-model="tab"
            bg-color="primary"
        >
          <v-tab value="project">Projekt</v-tab>
          <v-tab value="result" @click="getCalculations">Podsumowanie</v-tab>
          <v-tab value="calculate">Dane</v-tab>
          <template v-if="itemsWithIsScreenTrue">
            <v-tab :value="screen.id" v-for="screen in itemsWithIsScreenTrue" :key="screen.id">{{ screen.id }}</v-tab>
          </template>
        </v-tabs>
        <v-card-text>
          <v-window v-model="tab" transition="fade-transition">
            <v-window-item transition="fade-transition" value="project">
              <template v-if="projects.projects">
                <v-card
                    color="#1F7087"
                    theme="dark"
                    style="width: fit-content !important;"
                    v-for="(project, index) in projects.projects" :key="index"
                    class="float-left me-2"
                    @click="selectProject(project)"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div>
                      <v-card-title class="text-h6">
                        {{ project.title }}
                      </v-card-title>
                      <v-card-actions>
                        <label> <input type="radio" name="selectedProject" :value="project.id"> Wybierz
                        </label>
                      </v-card-actions>
                    </div>
                  </div>
                </v-card>
              </template>
            </v-window-item>
            <v-window-item transition="fade-transition" value="result">
              <v-card>
                <v-card-title>Podsumowanie</v-card-title>
                <v-card-subtitle>Projekt</v-card-subtitle>
                <v-card-item>{{ selectedProject }}</v-card-item>

                <v-card-subtitle>Wybory</v-card-subtitle>
                <v-card-item v-for="m in selectedProducts" :key="m">{{ m }}</v-card-item>

                <v-card-subtitle>Wartości</v-card-subtitle>
                <v-card-item v-for="m in math" :key="m">{{ m }}</v-card-item>

                <template v-if="itemsWithIsScreenTrue">
                  <v-card :title="screen.label" variant="outlined" v-for="screen in itemsWithIsScreenTrue"
                          :key="screen.id">
                    <v-table density="compact">
                      <thead>
                      <tr>
                        <th class="text-left" v-for="item in tableHeaders"
                            :key="item.name">
                          {{ item.title }}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in getItemFromScreen(screen.id)"
                          :key="item.idTree"
                      >
                        <td>{{ item.idTree }}</td>
                        <td>{{ item.parent }} | {{ item.name }}</td>
                        <td>{{ item.value }}</td>
                        <td>{{ item.math }}</td>
                      </tr>

                      <template v-if="screen.children">
                        <template v-for="screenCh in screen.children" :key="screenCh">
                          <tr
                              v-for="item in getItemFromScreen(screenCh.id)"
                              :key="item.idTree"
                          >
                            <td>{{ item.idTree }}</td>
                            <td>{{ item.parent }} | {{ item.name }}</td>
                            <td>{{ item.value }}</td>
                            <td>{{ item.math }}</td>
                          </tr>
                        </template>

                      </template>


                      </tbody>
                    </v-table>
                  </v-card>
                </template>


              </v-card>
            </v-window-item>
            <v-window-item transition="fade-transition" value="calculate">
              <v-card>
                <v-card-item>
                  <v-row>
                    <v-col v-for="item in tree" :key="item.id">
                      <v-card variant="outlined">
                        <v-card-title>{{ item.label }}</v-card-title>
                        <v-card-actions>
                          <label>
                            <input type="radio" :name="'selected_0'" :value="item.id"
                                   @click="handleSelected($event.target.value)"
                            > Wybierz
                          </label>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-item>
              </v-card>
            </v-window-item>
            <v-window-item transition="fade-transition" :value="screen.id" v-for="screen in itemsWithIsScreenTrue"
                           :key="screen.id" @load="handleSelected(screen.id)">

              <v-card v-if="screen.isPublic === true">
                <v-card-title>{{ screen.label }}</v-card-title>

                <template v-if="screen.features">
                  <v-card-item v-for="feature in screen.features" :key="feature.id" @load="handleSelected(screen.id)">
                    <v-card-subtitle>[Feature: {{ feature.id }}] {{ feature.label ?? feature.title }}
                      <strong v-if="feature.value !== undefined" @load="handleSelected(screen.id)">{{
                          feature.value
                        }}</strong>
                    </v-card-subtitle>
                    <v-text-field
                        @load="handleSelected(screen.id)"
                        variant="outlined" density="compact"
                        :value="checkSelected(screen.id, feature.id) ? checkSelected(screen.id, feature.id).value : ''"
                        v-if="feature.input === true"
                        @input="updateInput(screen.id, feature.id, $event.target.value)"
                        @change="getCalculations"
                    ></v-text-field>
                  </v-card-item>
                </template>

                <v-row>
                  <v-col v-for="child in screen.children" :key="child.id">

                    <v-card variant="outlined" v-if="child.isPublic === true">
                      <v-card-item v-if="child.isScreen === false || !child.isScreen">
                        <label v-if="child.oneChildren !== true">
                          <input type="checkbox" :value="child.id"
                                 @click="handleSelected($event.target.value)"
                          > {{ child.label }}
                        </label>
                        <template v-if="child.features">
                          <v-card-item v-for="feature in child.features" :key="feature.id">
                            <v-card-subtitle>[Feature: {{ feature.id }}] {{ feature.label ?? feature.title }}
                              <strong v-if="feature.value !== undefined">{{ feature.value }}</strong>
                            </v-card-subtitle>


                            <v-text-field :disabled="checkSelected(child.id, feature.id) === undefined"
                                          variant="outlined" density="compact"
                                          :value="checkSelected(child.id, feature.id) ? checkSelected(child.id, feature.id).value : ''"
                                          v-if="feature.input === true"
                                          @input="updateInput(child.id, feature.id, $event.target.value)"
                            ></v-text-field>
                          </v-card-item>
                        </template>


                        <BlockTree v-for="child2 in child.children" :key="child2.id" :node="child2"
                                   :selectedProducts="selectedProducts"
                                   :parent="child.id"
                                   @handleSelected="handleSelected"
                                   @checkSelected="checkSelected"
                                   @updateInput="updateInput"
                        />

                      </v-card-item>

                      <v-btn v-if="child.isScreen === true" @click="tab = child.id">Wybierz {{ child.label }}</v-btn>
                    </v-card>

                  </v-col>
                </v-row>


              </v-card>

            </v-window-item>
          </v-window>

        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import BlockTree from "@/views/tickets/BlockTree.vue";
import {rozdzielDzialanie} from "@/views/tree/js/main";

export default {
  components: {
    BlockTree,
    BreadcrumbsComponent,
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: 'Zlecenie',
          disabled: true,
        },
      ],
      itemsWithIsScreenTrue: [],
      tab: null,
      tab2: [],
      tree: [],


      dialog: false,
      title: '',
      itemId: null,


      selectedProducts: [],
      selectedProductsTemp: [],
      math: [],
      selectedProject: null,

      itemsPerPage: 20,
      tableHeaders: [
        {
          title: 'ID',
          align: 'start',
          sortable: false,
          key: 'id',
        },
        {title: 'Nazwa', align: 'start', key: 'name'},
        {title: 'Wartość', align: 'start', key: 'value'},
        {title: 'Wzór', align: 'start', key: 'math'},

      ],
      tableData: [],
    };
  },
  created() {
    this.fetchTickets();
    this.fetchProjects();
    if (this.$route.params.id) {
      let data = this.getTicketById(this.$route.params.id);
      this.itemId = data.id;
      this.title = data.title;
      this.math = JSON.parse(data.math);
      this.selectedProducts = JSON.parse(data.selectedProducts);
      this.selectedProject = JSON.parse(data.selectedProject);
      this.tree = JSON.parse(data.tree)
    }
  },
  computed: {
    ...mapState(["projects"]),
    ...mapGetters(["isLoggedIn", 'getToken', "getUser", "getRoles", 'getTicketById', 'getProjectById'])
  },

  methods: {
    ...mapActions(['loadProjects', 'loadTickets', 'addTicket']),
    async fetchTickets() {
      await this.loadTickets();
    },
    async fetchProjects() {
      await this.loadProjects();
    },
    async selectProject(projectData) {
      this.selectedProject = projectData.id;
      const project = await this.getProjectById(this.selectedProject);
      if (project) {
        const tree = JSON.parse(project.tree);
        this.chipsMath = JSON.parse(project.chipsMath);
        this.tree = tree.children;
        this.itemsWithIsScreenTrue = this.extractItemsWithIsScreenTrue(this.tree);
        this.itemsWithIsRequiredTrue = this.extractItemsWithIsRequiredTrue(this.tree);

        console.log(this.chipsMath);

        this.tab = "calculate";
      }
    },

    getItemFromScreen(idScreen) {
      return this.selectedProducts.filter((s) => s.idTree === idScreen);
    },

    async fetchAddTicket(idTicket) {
      //console.log('fetchAddUnit',idUnit);
      if (idTicket) {
        await this.$store.dispatch('updateTicket', {
          item: {
            title: this.title,
            math: JSON.stringify(this.math),
            selectedProducts: JSON.stringify(this.selectedProducts),
            selectedProject: JSON.stringify(this.selectedProject),
            tree: JSON.stringify(this.tree)
          }, idTicket: idTicket
        });
      } else {
        await this.$store.dispatch('addTicket', {
          title: this.title,
          math: JSON.stringify(this.math),
          selectedProducts: JSON.stringify(this.selectedProducts),
          selectedProject: JSON.stringify(this.selectedProject),
          tree: JSON.stringify(this.tree)
        });
        this.itemId = this.$store.state.tickets.currentId;
        console.log(this.itemId);
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
      }
    },

    extractItemsWithIsScreenTrue(items) {
      const result = [];

      for (const item of items) {
        if (item.isScreen === true) {
          result.push(item);
        }

        if (item.children && Array.isArray(item.children)) {
          result.push(...this.extractItemsWithIsScreenTrue(item.children));
        }
      }

      return result;
    },

    extractItemsWithIsRequiredTrue(items) {
      const result = [];

      for (const item of items) {
        if (item.isRequired === true) {
          result.push(item.id);
        }

        if (item.children && Array.isArray(item.children)) {
          result.push(...this.extractItemsWithIsRequiredTrue(item.children));
        }
      }

      return result;
    },


    findParentId(tree, childId) {
      return this.findParentIdRecursive(tree, childId, null);
    },

    findParentIdRecursive(node, childId, parentId) {
      if (node.id === childId) {
        return parentId; // Znaleziono rodzica, zwracamy jego id
      }

      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          const result = this.findParentIdRecursive(child, childId, node.id);
          if (result !== null) {
            return result; // Znaleziono rodzica w jednym z dzieci
          }
        }
      }

      return null; // Nie znaleziono rodzica dla danego dziecka
    },


    async handleSelected(idTree, idFeature = null) {


      const idParent = this.findParentId(this.tree[0], Number(idTree));
      const parent = await this.searchProductById(this.tree[0], Number(idParent))
      const currentItem = await this.searchProductById(this.tree[0], Number(idTree))

      // Usuwanie zaznaczenia z pól gdzie jest tylko jeden wybór
      if (parent && parent.oneChildren === true && parent.children) {
        parent.children.filter(child => {
          const child_ = this.selectedProducts.findIndex((el) => el.idTree === Number(child.id));
          console.log('child', child_);
          if (child_ !== -1) {
            this.selectedProducts.splice(child_, 1);
          }
        });
      }

      // Ustawianie "tab" na właściwy
      const screen = this.itemsWithIsScreenTrue.find((e) => e.id === Number(idTree))
      if (screen) {
        this.tab = Number(idTree);
      }

      // Dodawanie wartości do tablicy
      let data = {idTree: Number(idTree)};

      console.log('currentItem', currentItem);

      data.name = currentItem.label;

      if (currentItem.children) {
        data.children = [];
        currentItem.children.forEach((element) =>
            data.children.push(element.id)
        );
      }

      if (idFeature !== null) {
        const row = this.selectedProducts.findIndex((el) => el.idTree === Number(idTree) && el.idFeature === Number(idFeature));
        if (row !== -1) {
          this.selectedProducts.splice(row, 1);
        } else {
          data.idFeature = Number(idFeature);
          this.selectedProducts.push(data);
        }
      } else {
        const row = this.selectedProducts.findIndex((el) => el.idTree === Number(idTree));
        if (row !== -1) {
          this.selectedProducts.splice(row, 1);
        } else {
          if (currentItem.features !== undefined) {
            currentItem.features.filter((feature) => {

              console.log('feature', feature);

              let data = {idTree: Number(idTree)};
              data.parent = currentItem.label ?? currentItem.title;
              data.name = feature.title ?? feature.label;
              if (currentItem.children) {
                data.children = [];
                currentItem.children.forEach((element) =>
                    data.children.push(element.id)
                );
              }
              data.idFeature = Number(feature.id);
              data.value = Number(feature.value);
              data.math = feature.math;
              this.selectedProducts.push(data);
            })
          } else {


            console.log('data', data);

            this.selectedProducts.push(data);
          }

          //this.selectedProducts = this.sortValuesWithChildren(this.selectedProducts);

          /*const feature = currentItem.find(f => f.id = Number(idFeature));
          data.value = feature.value;*/


        }
      }


      console.log('this.selectedProducts', this.selectedProducts);

      this.selectedProducts = this.customSort(this.selectedProducts);
      /* Wyłączyłem bo można również pokazać na selectedProducts - to samo zwraca.
      //tableData
      this.tableData=[];
      this.selectedProducts.forEach(item => {
        console.log("item",item);
        this.tableData.push({
          'id': item.idTree,
          'name': item.name,
          'value': item.value,
          'math': item.math,
        })
      })
      console.log("tabela",this.tableData);
      */

    },

    customSort(arr) {
      const idToElementMap = new Map();
      const result = [];

      arr.forEach(item => {
        idToElementMap.set(item.idTree, item);
      });

      arr.forEach(item => {
        if (!item.children) {
          if (!result.includes(item)) {
            result.push(item);
          }
        } else {
          if (!result.includes(item)) {
            result.push(item);
          }
          item.children.forEach(childId => {
            const childElement = idToElementMap.get(childId);
            if (childElement && !result.includes(childElement)) {
              const parentIndex = result.findIndex(parent => parent.idTree === item.idTree);
              result.splice(parentIndex + 1, 0, childElement);
            }
          });
        }
      });

      return result;
    }
    ,

    checkSelected(idTree, idFeature = null) {
      if (idFeature !== null) {
        const row = this.selectedProducts.find((el) => el.idTree === Number(idTree) && el.idFeature === Number(idFeature));
        return (row !== -1) ? row : false;
      } else {
        const row = this.selectedProducts.find((el) => el.idTree === Number(idTree));
        return (row !== -1) ? row : false;
      }
    }
    ,

    updateMyProp($event, index) {


      const row = this.math.findIndex((el) => el.idTree === Number(index[0]) && el.feature === Number(index[1]));
      if (row !== -1) {
        this.math[row] = {
          idTree: Number(index[0]),
          feature: Number(index[1]),
          value: Number($event)
        }
      } else {
        this.math.push(
            {
              idTree: Number(index[0]),
              feature: Number(index[1]),
              value: Number($event)
            }
        );
      }
    }
    ,

    searchProductById(element, element_id) {
      if (Number(element.id) === Number(element_id)) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchProductById(element.children[i], element_id);
        }
        return result;
      }
      return null;
    }
    ,

    updateInput(idTree, idFeature = null, value = null) {

      console.log('updateInput',idTree, idFeature, value);

      let data = {
        idTree: Number(idTree),
        idFeature: Number(idFeature),
        value: value
      }
      const row = this.selectedProducts.findIndex((el) => el.idTree === Number(idTree) && el.idFeature === Number(idFeature));

      console.log('updateInputrow', row)

      if (row !== -1) {
        this.selectedProducts[row].value = value
      } else {
        this.selectedProducts.push(data);
      }
    },

    ...mapMutations(["checkRole"]),
    checkRoles(roles) {
      const userRoles = this.getRoles || [];
      return roles.some(role => userRoles.includes(role));
    },


    cleanMathExpression(expression) {
      // Usunięcie wszystkich znaków, które nie są cyframi, kropką lub znakami matematycznymi (+, -, *, /)
      const cleanedExpression = expression.replace(/[^0-9.+\-*/]/g, '');

      // Usunięcie zbędnych znaków operatorów na początku oraz przy występowaniu obok siebie
      const fixedExpression = cleanedExpression.replace(/^([+\-*/]+)|([+\-*/]+)$/g, '');


      return fixedExpression;
    },

    getCalculations() {
      this.selectedProducts.filter((item) => {
            if (item.math !== undefined) {
              let mathElements;
              mathElements = rozdzielDzialanie(item.math);
              let _math = '';

              console.log('mathElements', mathElements);

              mathElements.filter((mathElement) => {
                    if (mathElement) {
                      let pattern = /^[0-9]+([.,][0-9]+)?$/;
                      if (mathElement === "*" || mathElement === "/" || mathElement === "+" || mathElement === "-" || mathElement === "(" || mathElement === ")"
                          || pattern.test(mathElement)) {
                        _math += mathElement;
                      } else {

                        // Rozbijamy działanie matematyczne na części
                        // Wyciągamy identyfikatory
                        const mathId = mathElement.split('_');

                        let mathProduct = mathId[0];
                        let mathFeature = mathId[1];
                        let mathContent = mathId[2];

                        if (mathProduct && mathFeature && !mathContent) {
                          let variables = this.getVariableById(mathFeature);
                          _math += String(variables.value.replace(',', '.'));
                        }

                        if (mathProduct && mathFeature && mathContent) {
                          let _selectChildren = null;

                          if (item.children !== undefined) {
                            item.children.filter((childrenProduct) => {
                              // Zaznaczony produkt RADIO
                              if (this.selected) {
                                let selectChildren = this.selected.filter(element => Number(element) === Number(childrenProduct));
                                if (selectChildren.length > 0) {
                                  _selectChildren = selectChildren[0];
                                }
                              }
                            })
                          }

                          if (mathProduct === "??" && mathFeature === "??") {
                            const row = this.selectedProducts.find((el) => String(el.idTree) === String(_selectChildren));
                            if (row !== undefined) mathProduct = row;
                            if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
                          } else if (mathProduct === "??" && mathFeature !== "??") {

                            const row = this.selectedProducts.find((el) => String(el.idTree) === String(_selectChildren)
                                && String(el.idFeature) === String(mathFeature));
                            if (row !== undefined) mathProduct = row;

                            if (row !== undefined && mathContent === "C" && mathProduct !== '??') {
                              _math += String(row.price);
                            }
                            if (row !== undefined && mathContent === "W" && mathProduct !== '??') {
                              _math += String(row.value);
                            }

                          } else if (mathProduct !== "??" && mathProduct !== "00" && mathFeature !== "??" && mathFeature !== "00") {

                            const row = this.selectedProducts.find((el2) => {

                              if (String(el2.idTree) === String(mathProduct) &&
                                  String(el2.idFeature) === String(mathFeature)
                              ) {


                                console.log(mathProduct, mathFeature, el2);


                                if (item.children !== undefined) {
                                  if (item.children.find((el3) => el3 === el2.idTree)) {
                                    return el2
                                  }
                                }

                                if (String(el2.idTree) === String(mathProduct)) {
                                  return el2
                                }
                              }
                            });


                            if (row !== undefined && mathContent === "W" && mathProduct !== '??') {
                              if (row.value !== null) {
                                let price = row.value;
                                if (price) {
                                  _math += parseFloat(price.toString().replace(',', '.'));
                                }
                              }
                            }

                          } else if (mathProduct !== "??" && mathFeature === "??") {

                            let row = this.selectedProducts.find((el) => String(el.idTree) === String(mathProduct));

                            if (row.type !== undefined && row.type === 'ready' && !row.priceReady) {

                              let index = this.selectedProducts.findIndex(obj => obj.idTree === row.idTree);

                              if (index !== -1) {
                                this.selectedProducts.splice(index, 1);
                              }

                              row = this.selectedProducts.find((el) => String(el.idTree) === String(mathProduct));
                            }

                            if (row !== undefined) mathProduct = row;

                            if (mathContent === "C" && mathProduct !== '??') _math += String(mathProduct.priceReady);
                            if (mathContent === "J" && mathProduct !== '??') _math += String(mathProduct.priceUnit);

                          } else if (mathProduct === "00" && mathFeature === "00") {

                            if (item.children !== undefined) {
                              item.children.filter((childrenProduct) => {
                                const row = this.selectedProducts.find((el) => String(el.idTree) === String(childrenProduct));
                                if (row.priceReady !== undefined) {
                                  if (mathContent === "C") _math += String(row.priceReady);
                                  if (mathContent === "J") _math += String(row.priceUnit);
                                  _math += '+';
                                }
                              });

                              var lastChar = _math.charAt(_math.length - 1);
                              if (lastChar === "+") {
                                _math = _math.slice(0, -1);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
              )


              if (_math === "") {
                _math = '---';
              }


              console.log('_math', _math, this.cleanMathExpression(_math));

              try {
                let resultMath = eval(this.cleanMathExpression(_math))
                console.log('_math', item.idTree, item.idFeature, resultMath);
                resultMath = parseFloat(resultMath.toFixed(2));
                item.value = resultMath;

              } catch (error) {
                //console.log(error)
              }

            }

          }
      )
      ;
    },


  },

}
;
</script>
