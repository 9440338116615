<template>
  <template v-if="statuses.statuses">
    <template v-for="(status) in statuses.statuses" :key="status.id">
      <v-card
          v-if="listStatuses !== undefined && listStatuses[status.id] === true"
          class="statusesSteps float-start"
          :color="cDone(status.id) && cDone(status.id).status === true ?  status.color : '#ccc'"
          :style="{ width: isTitle ? 'auto' : '40px', 'height': isTitle ? '110px' : '50px' }"
      >
        <v-dialog
            v-model="dialog[status.id]"
            width="auto"
        >
          <v-card>
            <v-card-text>
              Tutaj będzie zmiana statusu. Czy coś jeszcze?
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="dialog[status.id] = false">Zamknij</v-btn>
              <!--<v-btn color="primary" @click="done(status.id)">Potwierdź</v-btn>-->
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-item class="rounded-0 ps-3" :style="{ paddingRight: isTitle ? '15px' : '0'}"
                     @click="!isTitle ? dialog[status.id] = true  : ''"
                     :color="(cDone(status.id) && cDone(status.id).status) ? status.color : 'grey-darken-1'"
        >
          <template v-if="cDone(status.id) && cDone(status.id).file !== undefined && cDone(status.id).file.length > 0">
            <v-badge :content="cDone(status.id).file.length" dot color="success"
                     style="margin-top:2px; margin-bottom:0;">
              <v-icon :icon="'mdi-' + status.icon">
              </v-icon>
            </v-badge>
          </template>
          <template v-else>
            <v-icon :icon="'mdi-' + status.icon">
            </v-icon>
          </template>

          <span v-if="isTitle" style=" margin-left: 5px;">
          <span style="display:inline-block; margin-top:4px;">{{ status.name }}</span>
          <v-divider class="my-3"></v-divider>
          <v-btn :icon="cDone(status.id) && cDone(status.id).status === true ? 'mdi-close' :'mdi-check' "
                 variant="outlined" size="x-small"
                 class="mx-1"
                 @click="isTitle ? done(status.id)  : dialog[status.id] = true"></v-btn>
            <template v-if="cDone(status.id) && cDone(status.id).files !== undefined && cDone(status.id).files.length > 0">
                        <v-btn icon="mdi-file-multiple" variant="outlined" size="x-small" class="mx-1"
                               @click="dialogFile[status.id] = true"
                        ></v-btn>
                    <v-dialog
                        v-model="dialogFile[status.id]"
                        width="auto"
                    >
                      <v-card>
                        <v-card-title>
                          Lista plików
                        </v-card-title>
                        <v-card-text>
                          <template v-if="cDone(status.id).files.length > 0">
                                        <v-list lines="one">
                            <v-list-item
                                v-for="n in cDone(status.id).files"
                                :key="n"
                                :title="n.title ?? 'test'"
                                :subtitle="n.name"
                                :href="'https://api.labmetry.com/' + n.path"
                                target="_blank"
                            >
                            </v-list-item>
                                    </v-list>
                          </template>
                          <template v-else>
                            Brak plików
                          </template>


                        </v-card-text>
                        <v-card-actions>
                          <AnnulledButton @action="dialogFile[status.id] = false" ></AnnulledButton>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

            </template>
        </span>

        </v-card-item>
      </v-card>
    </template>


  </template>

  <v-progress-linear v-if="!statuses.statuses"
                     indeterminate
                     color="yellow-darken-2"
  ></v-progress-linear>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import AnnulledButton from "@/components/navigation/element/AnnulledButton.vue";

export default {
  name: "StatusesComponent",
  components: {AnnulledButton},
  props: ['idBranch', 'idProject', 'isTitle', 'listStatuses'],
  data() {
    return {
      dialog: [],
      itemStatuses: [],
      menu: [],
      dialogFile: [],

    }
  },
  computed: {
    ...mapGetters(['getToken', 'getBranchStatusesByProjectId']),
    ...mapState(['statuses']),
  },
  created() {
  },
  methods: {
    ...mapActions(['getHistory']),

    async setBranchStatusById(id) {
      console.log(id)
    },
    getBranchByIdProps() {
      return this.getBranchStatusesByProjectId(this.$props.idBranch);
    },
    getStatusById(statusId) {
      const result = this.getBranchStatusesByProjectId(this.$props.idBranch);
      if (result) {
        if (result.status !== undefined) {
          return result.status.find(item => Number(item.id) === Number(statusId));
        } else {
          return false;
        }

      } else {
        return false;
      }

    },

    async done(statusId) {

      let branch = this.getStatusById(statusId);
      let newStatus;

      console.log(branch);

      if (branch === undefined) {
        console.log(branch);
        newStatus = false;
      } else {
        newStatus = branch.status;
        if (newStatus === 'false') {
          newStatus = false;
        }
      }

      const data = {
        "idStatus": statusId,
        "status": !newStatus
      }

      await this.$store.dispatch('putBranchStatus', {item: data, id: this.$props.idBranch});
      await this.getHistory({idBranch: this.$props.idBranch, endpoint: 'App/Entity/Branch/StatusesBranch'});
      this.$emit('updateHistory');
    },
    cDone(statusId) {
      //let branch = this.getStatusById(statusId);
      //let branch = this.getBranchByIdProps();
      try {
        return this.getStatusById(statusId);
      } catch (error) {
        return {
          status: false,
          file: false
        };
      }
    }

    /*  cDone(statusId) {
        let product = this.getReadyProductById(this.productId);
        if (product) {
          let status = product.statuses.find((ps) => ps.elementStatus === "/api/element_statuses/" + statusId);
          if (status) {
            return status
          }
        }

      },

      async done(statusId) {
        console.log('statusId', statusId);
        let status = true;
        let data = null;
        let productId = null;
        if (this.$props.productId) {
          productId = this.$props.productId;
        } else if (this.$store.state.readyProducts.currentProduct) {
          productId = this.$store.state.readyProducts.currentProduct;
        } else if (this.product) {
          productId = this.product.id;
        }


        const productStatus = this.product.statuses.find((ps) => ps.elementStatus === "/api/element_statuses/" + statusId);
        let statusO = productStatus ? productStatus.status : this.cDone(statusId).status;

        if (statusO !== undefined) {
          status = !statusO;
          data = {
            item: {
              status: status,
              product: "/api/products/" + productId,
              elementStatus: "/api/element_statuses/" + statusId
            },
            idProductStatus: productStatus.id
          };
          await this.$store.dispatch('updateProductStatus', data);
        } else {
          data = {
            status: status,
            product: "/api/products/" + productId,
            elementStatus: "/api/element_statuses/" + statusId
          };
          await this.$store.dispatch('addProductStatus', data);
        }
        await this.fetchSingleItem(productId);

        this.$emit('updateProduct', this.product)


        this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      },*/

  }
}
</script>
<style>


.statusesSteps.v-card {
  overflow: visible !important;
  display: inline-block;
  z-index: 20;
  float: end;
  border-radius: 0 !important;
  padding-left: 0;
  border: 0;
  margin: 0;
}

.statusesSteps.v-card::after {
  content: '';
  position: absolute;
  display: block;
  left: 98%;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 500;
  height: 100%;
  background: inherit;
  -webkit-clip-path: polygon(0 0, 10% 0, 20% 50%, 10% 100%, 0 100%);
  clip-path: polygon(0 0, 10% 0, 20% 50%, 10% 100%, 0 100%);
}

.statusesSteps.v-card:nth-child(1) {
  z-index: 501;
}

.statusesSteps.v-card:nth-child(1)::after {
  z-index: 500;
}

.statusesSteps.v-card:nth-child(2), .statusesSteps.v-card:nth-child(2)::after {
  z-index: 499;
}

.statusesSteps.v-card:nth-child(3), .statusesSteps.v-card:nth-child(3)::after {
  z-index: 498;
}

.statusesSteps.v-card:nth-child(4), .statusesSteps.v-card:nth-child(4)::after {
  z-index: 497;
}

.statusesSteps.v-card:nth-child(5), .statusesSteps.v-card:nth-child(5)::after {
  z-index: 496;
}

.statusesSteps.v-card:nth-child(6), .statusesSteps.v-card:nth-child(6)::after {
  z-index: 495;
}

.statusesSteps.v-card:nth-child(7), .statusesSteps.v-card:nth-child(7)::after {
  z-index: 494;
}

.statusesSteps.v-card:nth-child(8), .statusesSteps.v-card:nth-child(8)::after {
  z-index: 493;
}

.statusesSteps.v-card:nth-child(9), .statusesSteps.v-card:nth-child(9)::after {
  z-index: 492;
}

.statusesSteps.v-card:nth-child(10), .statusesSteps.v-card:nth-child(10)::after {
  z-index: 491;
}

.statusesSteps.v-card:nth-child(11), .statusesSteps.v-card:nth-child(11)::after {
  z-index: 490;
}

.statusesSteps.v-card:nth-child(12), .statusesSteps.v-card:nth-child(12)::after {
  z-index: 489;
}

.statusesSteps.v-card:nth-child(13), .statusesSteps.v-card:nth-child(13)::after {
  z-index: 488;
}

.statusesSteps.v-card:nth-child(14), .statusesSteps.v-card:nth-child(14)::after {
  z-index: 487;
}

.statusesSteps.v-card:nth-child(15), .statusesSteps.v-card:nth-child(15)::after {
  z-index: 486;
}

.statusesSteps.v-card:nth-child(16), .statusesSteps.v-card:nth-child(16)::after {
  z-index: 485;
}

.statusesSteps.v-card:nth-child(17), .statusesSteps.v-card:nth-child(17)::after {
  z-index: 484;
}

.statusesSteps.v-card:nth-child(18), .statusesSteps.v-card:nth-child(18)::after {
  z-index: 483;
}

.statusesSteps.v-card:nth-child(19), .statusesSteps.v-card:nth-child(19)::after {
  z-index: 482;
}

.statusesSteps.v-card:nth-child(20), .statusesSteps.v-card:nth-child(20)::after {
  z-index: 481;
}

.statusesSteps .v-card-item__content {
  overflow: visible;
  height: 20px;
}
</style>
