import axios from "axios";

export const tickets = {
    state: {
        tickets: [],
        currentId: null,
    },
    getters: {
        getTickets: state => {
            return state.tickets;
        },
        getTicketById: (state) => (id) => {

            //  console.log("state",state.tickets);

            return state.tickets.find(item => item.id === Number(id));
        },
        getModifyDateOfTicket: (state) => (id) => {
            return state.tickets.find(item => item.id === Number(id)).dateModify;
        },
    },
    mutations: {

        setCurrentId(state, currentId) {
            console.log('setCurrentId', currentId);
            state.currentId = currentId;
        }
        ,
        setTickets(state, items) {
            state.tickets = items;
        }
        ,
        removeTicket(state, itemId) {
            state.tickets = state.tickets.filter(item => item.id !== itemId);
        }
        ,
        addTicket(state, items) {
            state.tickets.push(items);
        }
        ,
        updateTicket(state, data) {

            const indexData = state.tickets.find(item => item.id === data.id);

            console.log('updateTicketBefore', indexData);

            const index = state.tickets.findIndex(item => item.id === data.id);
            let dataItem = data.item;
            dataItem.id = data.id;

            console.log('updateTicket', dataItem);

            state.tickets.splice(index, 1, dataItem);
        }
        ,
    }
    ,
    actions: {
        async loadTickets({commit, rootState}) {
            try {

                const response = await axios.get('https://api.labmetry.com/api/tickets',

                    {headers: {Authorization: `Bearer ${rootState .token}`}});

                const items = response.data;
                commit('setTickets', items);
            } catch (error) {
                console.error('Error loading items:', error);
            }
        }
        ,
        async removeTicket({commit, rootState}, itemId) {
            try {
                // Perform the asynchronous task to remove an item

                await axios.delete(`https://api.labmetry.com/api/tickets/${itemId}`,

                    {headers: {Authorization: `Bearer ${rootState .token}`}});


                // Commit the mutation to update the state
                commit('removeTicket', itemId);
                commit('setResponseMessage', 'Zlecenie zostało usunięte');
                commit('setResponseStatus', 'success');
            } catch (error) {
                console.error('Error removing item:', error);
            }
        }
        ,
        async addTicket({commit, rootState}, data) {
            try {
                await axios.post('https://api.labmetry.com/api/tickets',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addTicket', items);
                        commit('setResponseMessage', 'Zlecenie zostało dodane');
                        commit('setResponseStatus', 'success');
                        commit('setCurrentId', items.id);
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
        ,
        async updateTicket({commit, rootState}, data) {
            //console.log('updateUnit', data)
            try {
                await axios.put('https://api.labmetry.com/api/tickets/' + data.idTicket,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {

                        data.id = data.idTicket;
                        data.item.customers.id = data.idCustomers;

                        commit('updateTicket', data);
                        commit('setResponseMessage', 'Zlecenie zostało zaktualizowane');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
        ,
    }
}
