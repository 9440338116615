import axios from "axios";

const success_addWarehouse = "Magazyn dodano poprawnie";
const success_updateWarehouse = "Magazyn zaktualizowano poprawnie";
const success_removeWarehouse = "Magazyn usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const warehouses = {
    state: {
        warehouses: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getWarehouses: state => {
            return state.warehouses;
        },
        getWarehouseById: (state) => (id) => {
            return state.warehouses.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setWarehouses(state, items) {
            state.warehouses = items;
        },
        addWarehouse(state, items) {
            state.warehouses.push(items);
        },
       /* updateWarehouse(state, data) {
            const index = state.warehouses.findIndex(item => Number(item.id) === Number(data.id));
            state.warehouses.splice(index, 1, data.item);
        },*/

        updateWarehouse(state, data) {
            //console.log('updateCategory',data, state.categories);
            const index = state.warehouses.findIndex(item => item.id === data.id);
            state.warehouses.splice(index, 1, {
                id: data.id,
                name: data.name,
                parent: data.parent,
            });
        },

        removeWarehouse(state, itemId) {
            state.warehouses = state.warehouses.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadWarehouses({commit, rootState}) {

            try {
                const response = await axios.get(host + '/api/warehouses/',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setWarehouses', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addWarehouse({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/warehouses',
                    {
                        name: data.name,
                        parent: data.parentWarehouse,
                    },
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addWarehouse', items);
                        commit('setResponseMessage', success_addWarehouse);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateWarehouse({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/warehouses/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateWarehouse', items);
                        commit('setResponseMessage', success_updateWarehouse);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeWarehouse({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/warehouses/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeWarehouse', id);
                        commit('setResponseMessage', success_removeWarehouse);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
