<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="auto"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          color="red"
          v-bind="props"
          append-icon="mdi-trash-can" size="small"
      >
        Usuń
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Czy jesteś pewien swojej decyzji?
      </v-card-title>
      <v-card-text>Usunięcie wpisu jest nieodwracalne.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="gray"
            variant="flat"
            @click="dialog = false"
        >
          Anuluj
        </v-btn>
        <v-btn
            color="red"
            variant="flat"
            @click="confirm"
        >
          Potwierdzam
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RemoveButton",
  data () {
    return {
      dialog: false,
    }
  },
  methods:{
    confirm(){
      this.$emit('confirm');
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
