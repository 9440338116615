<template>
  <div v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_EMPLOYEES'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_EMPLOYEES_SAVE'])"
                v-model="dialog"
                persistent
                width="1024"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="green"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="small"
                    prepend-icon="mdi-plus"
                    variant="elevated"
                >
                  Dodaj
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                          prepend-icon="mdi-email"
                          name="firstName"
                          label="Imię"
                          type="text"
                          v-model="firstName"
                          destiny="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          prepend-icon="mdi-email"
                          name="lastName"
                          label="Nazwisko"
                          type="text"
                          v-model="lastName"
                          destiny="compact"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                      prepend-icon="mdi-email"
                      name="email"
                      label="Email"
                      type="text"
                      v-model="email"
                      destiny="compact"
                  ></v-text-field>
                </v-card-text>
                <v-card-item>
                  <PermissionsView :roles="roles"></PermissionsView>
                </v-card-item>
                <ActionsButton @annulled="dialog = false" @save="fetchAddUser(itemId)" labelSave="Dodaj pracownika"></ActionsButton>
              </v-card>
            </v-dialog>

          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="employees.employees"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.lastName }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.status }}</td>
                <td align="end">

                  <v-btn
                      v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_EMPLOYEES_SAVE'])"
                      class="mx-2" append-icon="mdi-pencil-box" size="small" fab dark color="blue"
                         :to="'/pracownik/' + item.id">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteUser(item.id)"
                                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_EMPLOYEES_REMOVE'])">
                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>

  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>


<script>
import {mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import ActionsButton from "@/components/navigation/ActionsButton.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import PermissionsView from "@/views/emplyees/PermissionsView.vue";

export default {
  name: 'EmployeesView',
  components: {PermissionsView, RemoveButton, ActionsButton, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Ustawienia',
          disabled: true,
        },
        {
          title: 'Pracownicy',
          disabled: true
        },
      ],
      roles: [ "ROLE_SETTING", "ROLE_LIBRARY", "ROLE_WAREHOUSE", "ROLE_ORDER", "ROLE_PRODUCTS", "ROLE_GANTT", "ROLE_PROJECTS", "ROLE_DEMAND" ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Imię', key: 'firstName', align: 'start'},
        {title: 'Nazwisko', key: 'lastName', align: 'start'},
        {title: 'Email', key: 'email', align: 'start'},
        {title: 'Status', key: 'status', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      //loaders
      loadingSaveItem: false,

      loading: false,
      totalItems: 10,
      itemId: null,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordRules: [
        value => {
          if (value) return true
          return 'Hasło jest wymagane.'
        },
      ],

    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken', "getRoles"]),
    ...mapState(["employees"])
  },

  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    //...mapActions(['addEmployee']),
    async fetchAddUser() {

      this.loadingSaveItem=true;
      await this.$store.dispatch('addEmployee', {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        roles: this.roles
      });
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});

      if(this.$store.state.users.responseStatus === 'success'){
        this.email='';
        this.firstName='';
        this.lastName='';
        this.roles= [ "ROLE_SETTING", "ROLE_LIBRARY", "ROLE_WAREHOUSE", "ROLE_ORDER", "ROLE_PRODUCTS", "ROLE_GANTT", "ROLE_PROJECTS", "ROLE_DEMAND" ];
        this.dialog = false;
      }
      this.loadingSaveItem=false;

    },
    async fetchDeleteUser(idUser) {
      await this.$store.dispatch('removeEmployee', idUser);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
  }
}
</script>
