import axios from "axios";

const success_addEmployee = "Pracownika dodano poprawnie";
const success_updateEmployee = "Pracownika zaktualizowano poprawnie";
const success_removeEmployee = "Pracownika usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const employees = {
    state: {
        employees: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getEmployees: state => {
            return state.employees;
        },
        getEmployeeById: (state) => (id) => {
            return state.employees.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setEmployees(state, items) {
            state.employees = items;
        },
        addEmployee(state, items) {
            state.employees.push(items);
        },
        updateEmployee(state, data) {
            const index = state.employees.findIndex(item => Number(item.id) === Number(data.id));
            state.employees.splice(index, 1, data.item);
        },
        removeEmployee(state, itemId) {
            state.employees = state.employees.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadEmployees({commit, rootState}) {
            try {
                const response = await axios.get(host +'/api/employees',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;



                commit('setEmployees', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addEmployee({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/employees',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        console.log(items);
                        commit('addEmployee', items);
                        commit('setResponseMessage', success_addEmployee);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateEmployee({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/employees/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateEmployee', items);
                        commit('setResponseMessage', success_updateEmployee);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeEmployee({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/employees/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeEmployee', id);
                        commit('setResponseMessage', success_removeEmployee);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
