<template>
  <div>
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-row class="pa-0">
          <v-col><BoxComponent
              :forecast="forecast"
              :labels="labels"
              :time="time" :title="'Gantt'"
              :count="5"
              :icon="'mdi-chart-gantt'"
          /></v-col>
          <v-col><BoxComponent
                                :forecast="forecast"
                                :labels="labels"
                                :time="time" :title="'Projekty'"
                                :count="5"
                                :icon="'mdi-projector-screen-outline'"
          /></v-col>
          <v-col><BoxComponent  :forecast="forecast"
                                :labels="labels"
                                :time="time" :title="'Produkty'"
                                :count="30000"
                                :icon="'mdi-cart-arrow-down'"
          /></v-col>
<!--          <v-col><BoxComponent :expand="expand" :forecast="forecast" :labels="labels" :time="time"
          /></v-col>
          <v-col><BoxComponent :expand="expand" :forecast="forecast" :labels="labels" :time="time"/></v-col>
          <v-col><BoxComponent :expand="expand" :forecast="forecast" :labels="labels" :time="time"/></v-col>-->

        </v-row>
      </v-main>
    </v-layout>

  </div>
</template>

<script>
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import BoxComponent from "@/views/BoxComponent.vue";

export default {
  name: "HomeView",
  components: {BoxComponent, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Panel zarządzania',
          disabled: true,
        },
      ],
      labels: { 0: 'SU', 1: 'MO', 2: 'TU', 3: 'WED', 4: 'TH', 5: 'FR', 6: 'SA' },
      time: 0,
      forecast: [
        { day: 'Tuesday', icon: 'mdi-white-balance-sunny', temp: '24\xB0/12\xB0' },
        { day: 'Wednesday', icon: 'mdi-white-balance-sunny', temp: '22\xB0/14\xB0' },
        { day: 'Thursday', icon: 'mdi-cloud', temp: '25\xB0/15\xB0' },
      ],
    }

  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
  }
}
</script>

