<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_LIBRARY'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="library.library"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.filename }}</td>
                <td>{{ formatDate(item.createAt) }}</td>
                <td>{{ item.type }}</td>
                <td align="end">
                  <v-btn class="mx-2" append-icon="mdi-download" size="small" fab dark color="blue" target="_blank"
                         :href="'https://api.labmetry.com/api/file_libraries/' + item.id">
                    Pobierz
                  </v-btn>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>



  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>

</template>


<script>
import {mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";

export default {
  name: 'LibraryView',
  components: {BreadcrumbsComponent},
  data: () => {
    return {
      title: '',
      breadcrumbs: [
        {
          title: 'Biblioteka plików',
          disabled: true,
        },
      ],
      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'filename', align: 'start'},
        {title: 'Data stworzenia', key: 'createAt', align: 'start'},
        {title: 'Typ', key: 'type', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
    };
  },
  computed: {
    ...mapState(["library"])
  },

  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    formatDate(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
  }
}
</script>
