<template>
  <v-card-item>
    <v-text-field
        hide-details
        v-model="branchName"
        destiny="compact"
        @input="update"
    >
    </v-text-field>
  </v-card-item>
</template>

<script>
export default {
  name: "BranchView",
  props: ['label'],
  data: () => {
    return {
      branchName: null,
    }
  },
  mounted(){
    this.branchName = this.$props.label;
    this.update()
  },
  methods:{
    update(){
      console.log(this.branchName);
      this.$emit('updateLabel', this.branchName);
    }
  }

}
</script>

<style scoped>

</style>
