<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs" />
          </v-col>
          <v-col>
            <v-btn
                color="red"
                style="margin-top:6px;"
                class="float-end mx-2"
                size="x-small"
                prepend-icon="mdi-format-list-bulleted-square"
                to="/users"
            >
              Lista użytkowników
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card v-if="loading === false">
          <v-tabs v-model="tab">
            <v-tab value="one">
              <v-icon left> mdi-account-cog </v-icon>
              Edytuj profil
            </v-tab>
            <v-tab value="three">
              <v-icon left> mdi-lock </v-icon>
              Zmień hasło
            </v-tab>
          </v-tabs>
          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="one">
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>                    <v-text-field
                          prepend-icon="mdi-email"
                          name="email"
                          class="mt-2"
                          label="Email"
                          density="compact"
                          type="text"
                          v-model="email"
                          :rules="emailRules"
                      ></v-text-field></v-col>
                      <v-col>
                        <v-text-field
                            prepend-icon="mdi-phone"
                            name="email"
                            class="mt-2"
                            label="Telefon"
                            density="compact"
                            type="text"
                            v-model="phone"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                            prepend-icon="mdi-account"
                            name="name"
                            label="Imię"
                            density="compact"
                            type="text"
                            v-model="firstName"
                            :rules="firstNameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            prepend-icon="mdi-account"
                            name="lastName"
                            label="Nazwisko"
                            density="compact"
                            type="text"
                            v-model="lastName"
                            :rules="lastNameRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <SaveButton :loadingSave="loadingSaveItem" @action="loadingSaveItem" @click="saveUser"></SaveButton>

                  </v-col>

                  <v-col class="text-center" v-if="checkRoles(['ROLE_ADMIN','ROLE_OWNER','ROLE_SETTING','ROLE_EMPLOYEES'])">
                    <PermissionsView :roles="roles"></PermissionsView>
                  </v-col>
                </v-row>
              </v-window-item>


              <v-window-item value="three">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        id="newPassword"
                        prepend-icon="mdi-lock"
                        label="Nowe hasło"
                        type="password"
                        density="compact"
                        :rules="newPasswordRules"
                        v-model="newPassword"
                    ></v-text-field>
                    <v-text-field
                        id="repeatPassword"
                        prepend-icon="mdi-lock"
                        label="Powtórz nowe hasło"
                        type="password"
                        density="compact"
                        :rules="repeatPasswordRules"
                        v-model="repeatPassword"
                    ></v-text-field>
                    <v-alert v-show="!isPasswordMatch" text="Podane hasła są różne" class="my-4" type="error"></v-alert>
                  </v-col>
                </v-row>
                <SaveButton @action="resetPassword" label="Zmień hasło"></SaveButton>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>


<script>
// @ is an alias to /src
import axios from 'axios';
import {mapGetters} from "vuex";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import {useStorage} from "vue3-storage";
import SaveButton from "@/components/navigation/element/SaveButton.vue";
import PermissionsView from "@/views/emplyees/PermissionsView.vue";
export default {
  name: 'UserProfileView',
  components: {PermissionsView, SaveButton, BreadcrumbsComponent},
  data: () => {
    return {
      roles: null,
      user: null,
      breadcrumbs: [
        {
          title: 'Użytkownicy',
          disabled: false,
          to: '/uzytkownicy',
        },
        {
          title: 'Profil użytkownika',
          disabled: true,
        },
      ],
      tab: null,
      loading:true,
      userData: null,
      description: null,
      isPasswordMatch: true,
      email: "",
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail jest wymagany.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail musi być prawidłowy'
        },
      ],
      firstName: "",
      firstNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],
      lastName: "",
      lastNameRules: [
        value => {
          if (value) return true
          return 'Wymagane'
        },
      ],
      newPassword: "",
      newPasswordRules: [
        value => {
          if (value) return true
          return 'Nowe Hasło jest wymagane.'
        },
      ],
      repeatPassword: "",
      repeatPasswordRules: [
        value => {
          if (value) return true
          return 'Nowe Hasło jest wymagane.'
        },
      ],

      phone: "",
      companyName: "",
      companyTax: "",
      //loaders
      loadingSaveItem: false,
    };
  },
  computed: {
    ...mapGetters(['getToken','getRoles'])
  },
  watch: {
    repeatPassword() {
      if(this.newPassword !== this.repeatPassword){
        this.isPasswordMatch = false
      }else{
        this.isPasswordMatch = true
      }
    }
  },
  mounted() {
    this.getUserData();
  },
  methods:{
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    async getUserData(){
      if (this.$route.params.id !== "") {

        this.loading = true;
        await axios.get("https://api.labmetry.com/api/users/" + this.$route.params.id,{
          headers: {
            Authorization: `Bearer ${this.getToken}`
          }
        })
            .then(response => {
              this.user = response.data;
              this.email = response.data.email;
              this.firstName = response.data.firstName;
              this.lastName = response.data.lastName;
              this.user = response.data;
              this.roles = response.data.roles;
              this.phone = response.data.phone;
              this.companyName = response.data.companyName;
              this.companyTax = response.data.companyTax;
              this.loading = false;
            })
            .catch(error => {
              this.$notify({
                title: error,
                type: 'error',
              });
              this.$router.go(-1)
            });


      }else{
        this.$router.go(-1)
      }

    },
    resetPassword() {
      if (this.newPassword !== this.repeatPassword) {
        this.isPasswordMatch = false;
        return;
      }

      this.isPasswordMatch = true;

          axios.put('https://api.labmetry.com/api/new-password/' + this.$route.params.id ,{
            password: this.newPassword
          },{
            headers: {
              Authorization: `Bearer ${this.getToken}`
            }
          })
          .then(response => {
            this.items = response.data;
            this.$notify({
              title: 'Hasło zostało zmienione',
              type: 'success',
            });
          })
          .catch(error => {
            console.log(error.response.data.detail);
            this.$notify({
              title: error.response.data.detail,
              type: 'error',
            });
          });

      // Call your API endpoint to reset the password
      // Pass the newPassword value to the API

      // Reset the input fields
      this.newPassword = '';
      this.repeatPassword = '';

    },
    updateRole (controller) {
      let find = this.roles.find((el) => el === controller)
      if(find){
        let index = this.roles.indexOf(find);
        if (index > -1) {
          this.roles.splice(index, 1);
        }
      }else{
        this.roles.push(controller)
      }
    },
    saveUser(){
      this.loadingSaveItem=true;
      axios.put('https://api.labmetry.com/api/users/'  + this.$route.params.id,{
        roles: this.roles,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone
      },{
        headers: {
          Authorization: `Bearer ${this.getToken}`
        }
      })
          .then(response => {
            this.items = response.data;
            this.$notify({
              title: 'Zmiany zostały zapisane',
              type: 'success',
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify({
              title: error,
              type: 'error',
            });
          });
      this.loadingSaveItem=false;
    }
  }
}
</script>
