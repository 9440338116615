import axios from "axios";

export const categories = {
    state: {
        categories: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getCategories: state => {
            return state.categories;
        },
        getCategoryById: (state) => (id) => {
            return state.categories.find(item => item.id === id);
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setCategories(state, items) {
            state.categories = items;
        },
        addCategory(state, items) {
            //console.log('addCategory',items);
            state.categories.push(items);
        },
        updateCategory(state, data) {
            //console.log('updateCategory',data, state.categories);
            const index = state.categories.findIndex(item => item.id === data.id);
            state.categories.splice(index, 1, {
                id: data.id,
                title: data.title,
                features: data.features,
                parent: data.parent,
            });
        },
        removeCategory(state, itemId) {
            state.categories = state.categories.filter(item => item.id !== itemId);
        },
    },
    actions: {
        /*        async loadCategories({commit, rootState }) {
                    try {
                        const response = await axios.get('https://api.labmetry.com/api/products/categories',
                            {headers: {Authorization: `Bearer ${rootState .token}`}});
                        const items = response.data;
                        commit('setCategories', items);
                    } catch (error) {
                        console.error('Error loading items:', error);
                    }
                },*/
        async loadCategories({commit, rootState}) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/categories',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setCategories', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addCategory({commit, rootState}, data) {

            try {
                await axios.post('https://api.labmetry.com/api/categories',
                    {
                        title: data.title,
                        features: data.features,
                        parent: data.parentCategory,
                    },
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addCategory', items);
                        commit('setResponseMessage', 'Kategoria została dodana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateCategory({commit, rootState}, data) {
            try {


                await axios.put('https://api.labmetry.com/api/categories/' + data.idCategory,
                    {
                        title: data.item.title,
                        features: data.item.features,
                        parent: data.item.parentCategory,
                    },
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateCategory', items);
                        commit('setResponseMessage', 'Kategoria została zaktualizowana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeCategory({commit, rootState}, itemId) {
            try {
                await axios.delete('https://api.labmetry.com/api/categories/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeCategory', itemId);
                        commit('setResponseMessage', 'Cecha została usunięta');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
