<template>
  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCTS'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-btn
                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCTS_SAVE'])"
                color="green"
                class="float-end ms-1"
                style="margin-top:6px;"
                size="small"
                variant="elevated"
                prepend-icon="mdi-plus">
              Dodaj
              <v-dialog
                  v-model="dialog[0]"
                  activator="parent"
                  width="1000"
              >

                <v-card>
                  <v-card-title>
                    <v-btn icon @click="dialog[0] = false" class="float-end" variant="text" density="compact">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <CreateProductView :productId="null" @close-dialog="dialog[0] = false"></CreateProductView>
                </v-card>
              </v-dialog>
              <v-tooltip
                  activator="parent"
                  location="end"
              >Dodaj nowy produkt
              </v-tooltip>
            </v-btn>
            <v-dialog
                v-model="dialogUploadFile"
                width="600"
                height="500"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="blue"
                    style="margin-top:6px;"
                    class="float-end ms-1"
                    v-bind="props"
                    size="small"
                    prepend-icon="mdi-import"
                    :loading="loadingExcel"
                    variant="elevated"
                >
                  Import
                  <v-tooltip
                      activator="parent"
                      location="end"
                  >Importuj produkty z pliku Excel
                  </v-tooltip>
                </v-btn>

              </template>
              <v-card>
                <form @submit="uploadFile">
                  <v-card-text>
                    <input type="file" ref="fileInput"/>
                  </v-card-text>
                  <v-card-actions>
                    <v-row align="center" justify="space-between">
                      <v-col cols="auto">
                        <v-btn color="grey-darken-1"
                               variant="flat"
                               size="small"
                               prepend-icon="mdi-close"
                               @click="dialogUploadFile = false"
                        >Zamknij
                        </v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn color="green"
                               variant="flat"
                               size="small"
                               prepend-icon="mdi-content-save"
                               type="submit"
                               :loading="loadingSubmitForm"
                        >
                          Wgraj i zamknij
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>

                </form>
              </v-card>
            </v-dialog>
            <v-btn
                color="blue"
                style="margin-top:6px;"
                class="float-end ms-1"
                size="small"
                prepend-icon="mdi-export"
                target="_blank"
                @click="exportExcel"
                variant="elevated"
                :loading="loadingExport"
            >
              Eksport
              <v-tooltip
                  activator="parent"
                  location="end"
              >Eksportuj produkty do pliku Excel
              </v-tooltip>
            </v-btn>

          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :group-by="groupBy"
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="readyProducts.readyProducts"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >

            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.category ? item.category.title : '-' }}</td>
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.unit ? item.unit.title : '-' }}</td>
                <td>{{ item.tags }}</td>
                <td align="end">

                  <v-btn
                      v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCTS_SAVE'])"
                      class="mx-2" append-icon="mdi-pencil-box" size="small" fab dark color="blue">
                    Edytuj
                    <v-dialog
                        v-model="dialog[item.id]"
                        activator="parent"
                        width="1000"
                    >

                      <v-card>
                        <v-card-title>
                          <v-btn icon @click="dialog[item.id] = false" class="float-end" variant="text" density="compact">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <CreateProductView :productId="item.id" @close-dialog="dialog[item.id] = false"></CreateProductView>
                      </v-card>
                    </v-dialog>

                  </v-btn>
                  <RemoveButton @confirm="removeReadyProduct(item.id)" :loadingSave="loadingRemoveItem"
                                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_PRODUCTS_REMOVE'])">
                  </RemoveButton>
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>
  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>

</template>


<script>

import {mapActions, mapGetters,  mapState} from "vuex";

import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import CreateProductView from "@/views/products/CreateProductView.vue";
import axios from "axios";
import {useStorage} from "vue3-storage";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";

export default {
  name: 'ProductsView',
  components: {RemoveButton, CreateProductView, BreadcrumbsComponent},
  data: () => {
    return {
      breadcrumbs: [
        {
          title: 'Produkty',
          disabled: true,
        },
      ],

      groupBy: [{key: 'category.title', order: 'asc'}],

      dialog: [],
      dialogUploadFile: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Cena', key: 'price', align: 'start'},
        {title: 'Jednostka', key: 'unit', align: 'start'},
        {title: 'Tagi', key: 'tags', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
      //loader
      loadingExport: false,
      loadingSubmitForm: false,
      loadingRemoveItem: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", 'getToken','getRoles',]),
    ...mapState(["readyProducts"]),
  },
  mounted() {
  },
  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    ...mapActions(['removeReadyProduct']),
    async uploadFile(event) {
      this.loadingSubmitForm=true;
      event.preventDefault();

      const file = this.$refs.fileInput.files[0];
      const formData = new FormData();

      formData.append('file', file);

      this.dialogUploadFile = false;

      this.$notify({
        title: "Plik został przesłany.",
        type: 'success',
      });

      // Wykonaj żądanie API przy użyciu preferowanej metody (np. Axios)
      await axios.post('https://api.labmetry.com/api/products/import', formData,
          {headers: {Authorization: `Bearer ${this.getToken}`}})
          .then(async () => {
            this.$notify({
              title: "Aktualizacja przebiegła pomyślnie",
              type: 'success',
            });
          })
          .catch(() => {
            this.$notify({
              title: "Wystąpił problem podczas aktualizacji produktów",
              type: 'error',
            });
          });
      this.loadingSubmitForm=false;
    },
    async exportExcel(){
      this.loadingExport=true;
      await axios.get('https://api.labmetry.com/api/products/export',
          {headers: {Authorization: `Bearer ${this.getToken}`}})
          .then(async () => {
            this.$notify({
              title: "Aktualizacja przebiegła pomyślnie",
              type: 'success',
            });
          })
          .catch(() => {
            this.$notify({
              title: "Wystąpił problem podczas aktualizacji produktów",
              type: 'error',
            });
          });
      this.loadingExport=false;
    }
  }
}
</script>
