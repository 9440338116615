import axios from "axios";

const success_addOrder = "Przykład dodano poprawnie";
const success_updateOrder = "Przykład zaktualizowano poprawnie";
const success_removeOrder = "Przykład usunięto poprawnie";
const host = 'https://api.labmetry.com';
export const orders = {
    state: {
        orders: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getOrders: state => {
            return state.orders;
        },
        getOrderById: (state) => (id) => {
            return state.orders.find(item => Number(item.id) === Number(id));
        }
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setOrders(state, items) {
            state.orders = items;
        },
        addOrder(state, items) {
            state.orders.push(items);
        },
        updateOrder(state, data) {
            const index = state.orders.findIndex(item => Number(item.id) === Number(data.id));
            state.orders.splice(index, 1, data.item);
        },
        removeOrder(state, itemId) {
            state.orders = state.orders.filter(item => Number(item.id) !== Number(itemId));
        },
    },
    actions: {
        async loadOrders({commit, rootState}) {
            try {
                const response = await axios.get('/api/orders',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setOrders', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addOrder({commit, rootState}, data) {
            try {
                await axios.post(host + '/api/orders',
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addOrder', items);
                        commit('setResponseMessage', success_addOrder);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', 'Brak systemu magazynowego');
                commit('setResponseStatus', 'error');
            }
        },
        async updateOrder({commit, rootState}, data) {
            try {
                await axios.put(host + '/api/orders/' + data.id,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('updateOrder', items);
                        commit('setResponseMessage', success_updateOrder);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeOrder({commit, rootState}, id) {
            try {
                await axios.delete(host + '/api/orders/' + id,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(() => {
                        commit('removeOrder', id);
                        commit('setResponseMessage', success_removeOrder);
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
