import axios from "axios";
export const library = {
    state: {
        library: [],
        responseStatus: '',
        responseMessage: ''
    },
    getters: {
        getLibrary: state => {
            return state.library;
        },
    },
    mutations: {
        setResponseMessage(state, message) {
            state.responseMessage = message;
        },
        setResponseStatus(state, status) {
            state.responseStatus = status;
        },
        setLibrary(state, items) {
            state.library = items;
        },
        addLibrar(state, items) {
            state.library.push(items);
        },
        updateLibrar(state, data) {
            const index = state.library.findIndex(item => item.idLibrar === data.idLibrar);
            state.library.splice(index, 1, {id: data.idLibrar , title: data.item.title});
        },
        removeLibrar(state, itemId) {
            state.library = state.library.filter(item => item.id !== itemId);
        },
    },
    actions: {
        async loadLibrary({commit, rootState }) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/file_libraries',
                    {headers: {Authorization: `Bearer ${rootState .token}`}});
                const items = response.data;
                commit('setLibrary', items);
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async addLibrar({commit, rootState }, data) {
            try {
                await axios.post('https://api.labmetry.com/api/library',
                    data,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(response => {
                        const items = response.data;
                        commit('addLibrar', items);
                        commit('setResponseMessage', 'Jednostka została dodana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateLibrar({commit, rootState }, data) {
           //console.log('updateLibrar', data)
            try {
                await axios.put('https://api.labmetry.com/api/library/' + data.idLibrar,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('updateLibrar', data);
                        commit('setResponseMessage', 'Jednostka została zaktualizowana');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async removeLibrar({commit, rootState }, itemId) {
            try {
                await axios.delete('https://api.labmetry.com/api/library/' + itemId,
                    {headers: {Authorization: `Bearer ${rootState .token}`}})
                    .then(() => {
                        commit('removeLibrar', itemId);
                        commit('setResponseMessage', 'Jednostka została usunięta');
                        commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        commit('setResponseMessage', error.response.data.detail);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        }
    }
}
