<template>

  <div v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_FEATURES'])">
    <v-layout ref="app">
      <v-app-bar density="compact" class="px-2">
        <v-row>
          <v-col>
            <BreadcrumbsComponent :items="breadcrumbs"/>
          </v-col>
          <v-col>
            <v-dialog
                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_FEATURES_SAVE'])"
                v-model="dialog"
                width="600"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    color="green"
                    style="margin-top:6px;"
                    class="float-end"
                    v-bind="props"
                    size="small"
                    prepend-icon="mdi-plus"
                    variant="elevated"
                >
                  Dodaj
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                          density="compact"
                          label="Cecha"
                          :rules="[rules.required, rules.counter]"
                          v-model="title"
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-checkbox
                          density="compact"
                          :label="unit.title"
                          v-for="unit in units.units"
                          :key="unit.id"
                          v-model="selectedUnits"
                          :value="unit.id"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-space-between">
                  <v-btn
                      color="gray"
                      size="small"
                      variant="tonal"
                      @click="dialog = false"
                  >
                    Anuluj
                  </v-btn>
                  <v-btn
                      color="green"
                      size="small"
                      variant="tonal"
                      prepend-icon="mdi-content-save"
                      @click="fetchAddFeature(itemId)"
                      :loading="loadingSaveItem"
                  >
                    Zapisz
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main class="mt-3">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Szukaj"
                single-line
                hide-details
                density="compact"
            ></v-text-field>
          </v-card-title>
          <v-data-table

              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items-length="totalItems"
              :items="features.features"
              :loading="loading"
              :search="search"
              class="elevation-0"
              item-value="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>
                  <span v-for="units in item.units" :key="units.id"> [ {{ units.title }} ] </span>
                </td>
                <td align="end">
                  <v-btn
                      v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_FEATURES_SAVE'])"
                      class="mx-2" append-icon="mdi-pencil-box" size="small" fab dark color="blue"
                         @click="editItem(item)">
                    Edytuj
                  </v-btn>
                  <RemoveButton @confirm="fetchDeleteFeature(item.id)"
                                v-if="checkRoles(['ROLE_ADMIN', 'ROLE_OWNER','ROLE_FEATURES_REMOVE'])">
                  </RemoveButton>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-main>
    </v-layout>

  </div>
  <div v-else>
    Przykro nam, ale nie posiadasz uprawnień do przeglądania tej strony
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import RemoveButton from "@/components/navigation/element/RemoveButton.vue";
import {useStorage} from "vue3-storage";
export default {
  name: 'FeaturesView',
  components: {RemoveButton, BreadcrumbsComponent},
  data: () => {
    return {
      rules: {
        required: value => !!value || 'Wymagane.',
        counter: value => value.length <= 20 || 'Max 20 znaków',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      title: '',
      breadcrumbs: [
        {
          title: 'Ustawienia',
          disabled: true,
        },
        {
          title: 'Kategorie',
          disabled: false,
          to: '/kategorie'
        },
        {
          title: 'Cechy',
          disabled: true,
        },
      ],

      dialog: false,
      search: '',
      itemsPerPage: 50,
      headers: [
        {
          title: 'ID',
          align: 'start',
          key: 'id',
        },
        {title: 'Nazwa', key: 'title', align: 'start'},
        {title: 'Jednostki', key: 'measurementUnits', align: 'start'},
        {title: 'Akcja', align: 'end', sortable: false},
      ],
      //loaders
      loadingSaveItem: false,
      loading: false,
      totalItems: 10,
      itemId: null,
      items: [],
      selectedUnits: [],
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn", 'getToken']),
    ...mapState(["features", "units"]),

  },

  methods: {
    checkRoles(roles) {
      let allRoles = this.$store.state.roles = useStorage().getStorageSync("roles");
      return roles.some(role => allRoles.includes(role));
    },
    ...mapActions(['addFeature']),
    async fetchAddFeature(idFeature) {
      this.loadingSaveItem = true;
     //console.log('fetchAddFeature', idFeature);
      if (idFeature) {
        await this.$store.dispatch('updateFeature', {
          item: {
            title: this.title,
            units: this.selectedUnits
          }, idFeature: idFeature
        });
      } else {
        await this.$store.dispatch('addFeature', {
          title: this.title,
          units: this.selectedUnits
        });
      }
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
      if (this.$store.state.users.responseStatus === 'success') {
        this.dialog = false;
        this.itemId = null;
      }
      this.loadingSaveItem = false;
    },
    async fetchDeleteFeature(idFeature) {
      await this.$store.dispatch('removeFeature', idFeature);
      this.$notify({type: this.$store.state.users.responseStatus, title: this.$store.state.users.responseMessage});
    },
    editItem(unit) {
      this.dialog = true
      this.title = unit.title
      this.itemId = unit.id;
      this.selectedUnits = unit.units.map(item => item.id)
    },
  }

}
</script>
