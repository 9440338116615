import axios from "axios";

export const projects = {
    state: {
        projects: [],
        currentIdProject: null,
    },
    getters: {
        getProjects: state => {
            return state.projects;
        },
        getProjectById: (state) => (id) => {
            return state.projects.find(item => item.id === Number(id));
        },

    },
    mutations: {
        setProjects(state, items) {
            state.projects = items;
        },
        removeProject(state, itemId) {
            state.projects = state.projects.filter(item => item.id !== itemId);
        },
        currentIdProject(state, item) {
            state.currentIdProject = item;
        },
        addProject(state, items) {
            state.projects.push(items);
        },
        addProjectElement(state, items) {
            state.projectsElements.push(items);
        },
        updateProject(state, data) {
            const index = state.projects.findIndex(item => Number(item.id) === Number(data.id));
            state.projects.splice(index, 1, data.item);
        },
    },
    actions: {
        async loadProjects({commit, rootState}) {
            try {
                const response = await axios.get('https://api.labmetry.com/api/projects',
                    {headers: {Authorization: `Bearer ${rootState.token}`}});
                const items = response.data;
                commit('setProjects', items);
            } catch (error) {
                console.error('AccessDanied:', error.response.status === 401);
            }
        },
        async removeProject({commit, rootState}, itemId) {
            // Perform the asynchronous task to remove an item
            await axios.delete(`https://api.labmetry.com/api/projects/${itemId}`,
                {headers: {Authorization: `Bearer ${rootState.token}`}})
                .then(response => {

                    commit('removeProject', itemId);
                    commit('setResponseMessage', response.data);
                    commit('setResponseStatus', 'success');

                })
                .catch(error => {
                    commit('setResponseMessage', error);
                    commit('setResponseStatus', 'error');
                });

            // Commit the mutation to update the state

        },
        async addProject({commit, rootState}, data) {

            try {
                await axios.post('https://api.labmetry.com/api/projects',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then(response => {
                        const items = response.data;
                            commit('addProject', items);
                            commit('currentIdProject', items.id);
                            commit('setResponseMessage', 'Projekt został dodany');
                            commit('setResponseStatus', 'success');
                    })
                    .catch(error => {
                        console.log(error);
                        commit('setResponseMessage', error.response.data.error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', error);
                commit('setResponseStatus', 'error');
            }
        },
        async updateProject({commit, rootState}, data) {
            try {
                await axios.put('https://api.labmetry.com/api/projects/' + data.idProject,
                    data.item,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then((response) => {

                        const items = {
                            item: response.data,
                            id: data.idProject
                        };

                        if (!items.item.error) {
                            commit('updateProject', items);
                            commit('currentIdProject', items.id);
                            commit('setResponseMessage', 'Projekt został zaktualizowany');
                            commit('setResponseStatus', 'success');
                            console.log('updateProject', items);
                        } else {
                            commit('setResponseMessage', items.error);
                            commit('setResponseStatus', 'error');
                            console.log('Error', items.error);
                        }

                    })
                    .catch(error => {
                        console.log(error);
                        commit('setResponseMessage', '0:' + error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', '1:' + error);
                commit('setResponseStatus', 'error');
            }
        },

        async postBranch({commit, rootState}, data) {
            try {
                await axios.post('https://api.labmetry.com/api/branches',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then((response) => {
                        return response.data.idElement;
                    })
                    .catch(error => {
                        console.log(error);
                        commit('setResponseMessage', '0:' + error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', '1:' + error);
                commit('setResponseStatus', 'error');
            }
        },
        async getBranch({commit, rootState}, data) {
            try {
                await axios.get('https://api.labmetry.com/api/branches/',
                    data,
                    {headers: {Authorization: `Bearer ${rootState.token}`}})
                    .then((response) => {
                        return response.data.idElement;
                    })
                    .catch(error => {
                        console.log(error);
                        commit('setResponseMessage', '0:' + error);
                        commit('setResponseStatus', 'error');
                    });
            } catch (error) {
                commit('setResponseMessage', '1:' + error);
                commit('setResponseStatus', 'error');
            }
        },
    }
}
