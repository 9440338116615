import { createStore } from 'vuex'
import { useStorage } from "vue3-storage";
import {projects} from "./modules/projects";
import {categories} from "./modules/categories";
import {readyProducts} from "@/store/modules/readyProducts";
import {users} from "@/store/modules/users";
import {units} from "@/store/modules/units";
import {features} from "@/store/modules/features";
import {projectsElements} from "@/store/modules/projectsElements";
import {tickets} from "@/store/modules/tickets";
import {statuses} from "@/store/modules/status";
import {library} from "@/store/modules/library";
import {histories} from "@/store/modules/history";
import {productStatus} from "@/store/modules/productStatus";
import {branchStatuses} from "@/store/modules/branchStatuses";
import {employees} from "@/store/modules/employees";
import {customers} from "@/store/modules/customers";
import {orders} from "@/store/modules/orders";
import {producers} from "@/store/modules/producers";
import {warehouses} from "@/store/modules/warehouses/warehouses";
import {industries} from "@/store/modules/industries";
import {currencies} from "@/store/modules/currencies";
import {activities} from "@/store/modules/activities";
import {itemsInWarehouses} from "@/store/modules/warehouses/itemsInWarehouses";
import {locationItems} from "@/store/modules/warehouses/locationItems";
export default createStore({
  state: {
    token: null,
    user:  null,
    roles: null,
    role: null,
    networkError: false,
    companyId: null,
    userId: null,
  },
  getters: {
    isLoggedIn(state) {
      const storage = useStorage();
      const token = storage.getStorageSync("token");
      state.token = token;
      return !!state.token;
    },
    getToken(state) {
      const storage = useStorage();
      const token = storage.getStorageSync("token");
      return state.token = token;
    },
    getUser(state) {
      const storage = useStorage();
      const user = storage.getStorageSync("user");
      return state.user = user;
    },
    getUserId(state) {
      const storage = useStorage();
      const id = storage.getStorageSync("idUser");
      return state.idUser = id;
    },
    getRoles(state) {
      const storage = useStorage();
      const user = storage.getStorageSync("roles");
      return state.roles = user;
    },
  },
  mutations: {
    setNetworkError(state, value) {
      state.networkError = value;
      this.logout(state);
    },
    logout(state) {
      //console.log'Usuwamy')
      const storage = useStorage();
      storage.removeStorageSync('token');
      storage.removeStorageSync('user');
      storage.removeStorageSync('roles');
      storage.removeStorageSync('idUser');
      state.token = null;
      state.user = null;
      state.roles = null;
      state.idUser = null;
      state.token = null;
      return state.token;
    },
    setToken(state, token) {
      const storage = useStorage();
      storage.setStorage({
        key: "token",
        data: token
      })
      state.token = token;
    },
    setUser(state, user) {
      const storage = useStorage();
      storage.setStorage({
        key: "user",
        data: user
      })
      state.user = user;
    },
    setRoles(state, roles) {
      const storage = useStorage();
      storage.setStorage({
        key: "roles",
        data: roles
      })
      state.roles = roles;
    },
    setCompanyId(state, item) {
      const storage = useStorage();
      storage.setStorage({
        key: "companyId",
        data: item
      })
      state.companyId = item;
    },
    setUserId(state, item) {
      const storage = useStorage();
      storage.setStorage({
        key: "userId",
        data: item
      })
      state.userId = item;
    },
    checkRole(state ,roles) {
      let allRoles = state.roles;
      if (allRoles === null) {
        allRoles = useStorage().getStorageSync("roles");
      }
      console.log(roles.some(role => allRoles.includes(role)));
      return roles.some(role => allRoles.includes(role));
    },
  },
  actions: {

  },
  modules: {
    users,
    units,
    features,
    projects,
    projectsElements,
    readyProducts,
    categories,
    tickets,
    statuses,
    library,
    branchStatuses,
    histories,
    employees,
    orders,
    productStatus,
    producers,
    customers,
    //
    warehouses,
    industries,
    currencies,
    activities,
    itemsInWarehouses,
    locationItems,
  }
})
