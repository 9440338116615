<template>
  <v-dialog
      v-model="$props.dialog"
      width="auto"
  >
    <v-card>
      <v-card-text>
        <v-text-field v-if="duration" v-model="duration" density="compact" ></v-text-field>

        <v-text-field v-model="startTime" density="compact" @click="showDatePicker = !showDatePicker"></v-text-field>
        <v-date-picker
            v-model="startTimeV"
            v-if="showDatePicker"
            locale="pl"
            @input="showDatePicker = false"
        ></v-date-picker>

        <v-text-field v-model="endTime" density="compact" @click="showDatePicker2 = !showDatePicker2"></v-text-field>
        <v-date-picker
            v-model="endTimeV"
            v-if="showDatePicker2"
            locale="pl"
            @input="showDatePicker2 = false"
        ></v-date-picker>


        <v-text-field v-if="progress" v-model="progress" density="compact"></v-text-field>
        {{ $props.currentTask }}

        <StatusesComponent
            v-if="$props.currentTask.branch"
            :listStatuses="$props.currentTask.statuses ?? []"
            :idBranch="$props.currentTask.branch"
            :isTitle="true"
        ></StatusesComponent>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="$emit('dialog')">Zamknij</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from "vuex";
import StatusesComponent from "@/components/StatusesComponent.vue";
import moment from 'moment'

export default {
  name: 'GanttDialog',
  components: {StatusesComponent},
  props: ["dialog", "currentTask"],
  data: () => {
    return {
      duration: null,
      startTime: null,
      endTime: null,
      progress: null,

      showDatePicker:false,
      showDatePicker2:false,
      startTimeV: null,
      endTimeV: null
    }
  },
  computed: {
    ...mapGetters(['getBranchStatusesByProjectId']),
  },
  watch:{
    startTimeV(value){
      this.startTime = this.formatDateDP(value);
      this.$props.currentTask.startTime = new Date(value).getTime();
    },
    endTimeV(value){
      this.endTime = this.formatDateDP(value);
      this.$props.currentTask.endTime = new Date(value).getTime();
    }
  },

  mounted() {
    if(this.$props.currentTask){
      this.duration = null; //this.$props.currentTask.duration ?? null;
      const _startTime = new Date(this.$props.currentTask.startTime);
      this.startTime = this.format_date(_startTime.toLocaleDateString());

      const _endTime = new Date(this.$props.currentTask.endTime);
      this.endTime = this.format_date(_endTime.toLocaleDateString());

      this.progress = this.$props.currentTask.progress;
    }

  },
  methods: {
    formatDateDP(date) {
      // Format the date as needed
      return new Date(date).toLocaleDateString('pl-PL', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
  }
}


</script>
