<template>
  <v-breadcrumbs
      :items="items"
      size="x-small"
      class="my-0 py-0 pl-0 mb-0 fs-1 breadcrumb">
    <template v-slot:prepend>
      <v-btn icon="mdi-home" to="/" size="small" class="me-3" color="grey">
      </v-btn>
    </template>
    <template v-slot:divider>
      <v-icon icon="mdi-chevron-right"></v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  props: {
    items: {},
  },
  data() {
    return {
    }
  }
}
</script>
<style scoped>
</style>
